import React, {FC, useEffect, useMemo} from "react";
import {Button, Card, FormLabel} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {useStore} from "effector-react";
import {$airports} from "../../../airport/store/airport.store";
import {Text} from "../../../../components/text";
import dayjs from "dayjs";
import {Checkbox} from "../../../../components/checkbox/checkbox";
import {File} from "../../../../components/file";
import {$foodApp, getFoodApp} from "../../store/food-app.store";
import "./food.css";
import {Input} from "../../../../components/input/input";

const dishes = [{
    id: 1,
    name: "Ланч бокс",
    value: false,
    field: "launch_box",
    checked: false
},{
    id: 2,
    name: "Фарфор",
    value: false,
    field: "porcelain",
    checked: false
},{
    id: 3,
    name: "Дестер",
    value: false,
    field: "dester",
    checked: false
}];

export const Food: FC = () => {
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            getFoodApp(parseInt(id, 10));
        }
    }, [id]);

    const airports = useStore($airports);
    const foodApp = useStore($foodApp);

    if (foodApp === null || airports === null) {
        return null;
    }

    const getDepartureAirport = (legNumber: number) => {
        return airports.find((airport) => {
            return airport.id === foodApp.food_legs[legNumber].departure_airport;
        });
    };

    const getArrivalAirport = (legNumber: number) => {
        return airports.find((airport) => {
            return airport.id === foodApp.food_legs[legNumber].arrival_airport;
        });
    };


    return (
        <>
            <div className="food-order-top">
                <Text type="caption">Заказ: {foodApp.id}</Text>
                <div className="food-order-back">
                    <Link to="/dashboard/food-list">
                        <Button variant="link">{'<'} Актуальные заявки</Button>
                    </Link>
                </div>
            </div>
            <Card key={foodApp.id} className="food-order">
                {
                    foodApp.food_legs.map((food_legs, legNumber) => (
                        <>
                            <div className="food-order-title addFoodOrder-line">
                                <div className="food-order-line-item">
                                    <Text type="title">
                                        {getDepartureAirport(legNumber)?.city_rus}
                                    </Text>
                                    <Text type="text">
                                        {`${getDepartureAirport(legNumber)?.name} ${getDepartureAirport(legNumber)?.iata}`}
                                    </Text>
                                </div>
                                <div className="food-order-line-item">
                                    <Text type="title">
                                        {getArrivalAirport(legNumber)?.city_rus}
                                    </Text>
                                    <Text type="text">
                                        {`${getArrivalAirport(legNumber)?.name} ${getArrivalAirport(legNumber)?.iata}`}
                                    </Text>
                                </div>
                                <Text type="title">
                                    {dayjs(foodApp.food_legs[legNumber].departure_datetime).format("D MMMM в H:mm")}
                                </Text>
                                <Text type="title">
                                    {foodApp.food_legs[legNumber].regular_number}
                                </Text>
                                <div className="food-order-top">
                                    <div className="food-order-status">{foodApp.status}</div>
                                </div>
                            </div>
                            <div className="food-dishes">
                                <div className="food-app-portions">
                                    <FormLabel className="portions-info-header">Количество порций</FormLabel>
                                    <Input
                                        variant="solid"
                                        type="text"
                                        value={foodApp.food_legs[legNumber].portions.toString()}
                                        onChange={()=>{}}
                                    ></Input>
                                </div>
                                <div className="food-services">
                                    <div className="food-services-item">
                                        <Checkbox
                                            checked={foodApp.food_legs[legNumber].launch_box}
                                            onChange={() => {}}>
                                            <label>Ланч бокс</label>
                                        </Checkbox>
                                        <Checkbox
                                            checked={foodApp.food_legs[legNumber].porcelain}
                                            onChange={() => {}}>
                                            <label>Фарфор</label>
                                        </Checkbox>
                                        <Checkbox
                                            checked={foodApp.food_legs[legNumber].dester}
                                            onChange={() => {}}>
                                            <label>Дестер</label>
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                }
            </Card>
            <Card className="food-order">
                <Text type="text" className="food-order-data-comment">Комментарий</Text>
                <Text type="text" className="food-order-data">{foodApp.comment}</Text>
            </Card>
            <div className="order-files">
                {
                    foodApp?.documents.length !== 0 && (
                        <Card className="food-order">
                            <div className="food-order-files">
                                <Text className="food-сustom-title" type="text">Файлы заказа</Text>
                                <div className="food-order-files-list">
                                    {
                                        foodApp?.documents.map((file) => (
                                            <File key={file} name={file} />
                                        ))
                                    }
                                </div>
                            </div>
                        </Card>
                    )
                }
            </div>
        </>
    );
};
