import React, {FC, useCallback} from "react";
import { Button } from "../../button";

import './service.css';
import {useStore} from "effector-react";
import {$authorizedUser} from "../../../domains/user/store/authorized-user.store";
import {useNavigate, useSearchParams} from "react-router-dom";

interface IProps {
    showSignForm(): void;
}

export const ServiceSection: FC<IProps> = ({
    showSignForm,
}) => {
    const navigate = useNavigate();
    const [_, setSearchParams] = useSearchParams();
    const user = useStore($authorizedUser);

    const sendApp = useCallback(() => {
        if (user) {
            if (user.status === "2") {
                if (user.type === "team") {
                    navigate("/dashboard/applications/form")
                } else {
                    setSearchParams({
                        airlineNotice: "true",
                    });
                }
            } else {
                showSignForm();
            }
        } else {
            showSignForm();
        }
    }, [user, showSignForm, navigate, setSearchParams]);

    return (
        <section className="section service-section">
            <div className="service-title">
                Сервис является закрытым. Участники сервиса —&nbsp;спортивные клубы
            </div>
            <div className="service-text">
                В интересах спортивных лиг и состоящих в них клубов
            </div>
            <Button variant="action" onClick={sendApp}>Подать заявку</Button>
        </section>
    )
}
