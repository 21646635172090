import React from "react";
import './team-list-item.css';
import {Text} from "../../../../../components/text";
import {ITeam} from "../../../types/team.type";
import {Link} from "react-router-dom";


interface IListItemProps {
    team: ITeam;
}

export const TeamListItem: React.FC<IListItemProps> = ({
    team,
}) => {

    return (
        <Link className={`item item_type_table`} to={`/dashboard/teams/${team.id}`}>
            <div className="item-cell">
                <Text type="caption" className="list-item">{team.id}</Text>
            </div>
            <div className="item-cell">
                <Text type="caption" className="list-item">{team.team_name}</Text>
            </div>
            <div className="item-cell">
                <Text type="caption" className="list-item">{team.contact_name}</Text>
            </div>
        </Link>
    );
}
