import {IAirline} from "../../airline/types/airline.types";
import {IFoodLeg} from "../../food/types/food.types";
import {IAircraft} from "../../aircraft/types/aircraft.types";

export interface IOffer {
    id: number;
    status: string;
    airline: IAirline;
    additional_file: string | null;
    additional_price: number;
    price: number;
    aircraft: IAircraft | null;
    leg_files: string[];
    flight_type: string;
    flight: string;
    comment: string;
}

export interface ILeg {
    departure_airport: number;
    arrival_airport: number;
    departure_datetime: string;
    departure_back_datetime: string;
    regular_number: string;
    pax: number;
    business_pax: number;
    baggage: string;
    baggage_number: number;
}

export interface IApplication {
    airlines: IAirline[];
    id: number;
    app_status: string;
    legs: ILeg[];
    offers: IOffer[];
    need_loader: boolean;
    need_manager: boolean;
    transfer: boolean;
    post_payment: boolean;
    food_order: boolean;
    food_legs?: IFoodLeg[];
    comment: string;
    documents: string[];
    flight: "ow" | "rt" | "cf",
    flight_type: "plane" | "regular" | "passing",
    new_messages: boolean;
    passing_flight: number;
}


export interface IMessages {
    airline_id: number;
    attachment?: string;
    author: string;
    body: string;
    created: string;
    is_new: boolean;
    logo: string;
}

export enum FlightTypes {
    REGULAR = 'regular',
    PLANE = 'plane',
    PASSING = 'passing'
}


