import React, {memo} from "react";
import {FormLabel} from "react-bootstrap";
import {
    $applicationFormFlight, $applicationFormFlightType,
    setApplicationFormFlight,
    setApplicationFormFlightType
} from "../../../store/application-form.store";
import trip from "../application-form-leg/trip.svg";
import roundTrip from "../application-form-leg/roundTrip.svg";
import difficultFlight from "../application-form-leg/difficultFlight.svg";
import {FlightTypes} from "../../../types/application.types";
import {RadioBox} from "../../../../../components/radiobox/radio-box";
import {changeFlightType} from "../../../../airline/store/airlines.store";
import {useStore} from "effector-react";
import "./application-form-flight-type.css";

const translateFlightTypes = (name: string): string => {
    switch (name) {
        case FlightTypes.REGULAR: return 'Регулярные рейсы';
        case FlightTypes.PLANE: return 'Чартер';
        case FlightTypes.PASSING: return 'Попутный';
        default: return name;
    }
}

const ApplicationFormFlightType:React.FC = memo(() => {

    const flight = useStore($applicationFormFlight);
    const flightType = useStore($applicationFormFlightType);

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    return (
        <div className="addOrder-line-flight-type">
            <div className="addOrder-half-flight-type addOrder-half-flight-type_vertical">
                <FormLabel>Тип перелета</FormLabel>
                <div className="btn-select-flight" data-toggle="buttons">
                    <input
                        className="btn-trip-radio"
                        type="radio"
                        name="flight"
                        id="ow"
                        checked={flight === "ow"}
                        onClick={() => {
                            setApplicationFormFlight("ow");
                        }}
                    />
                    <label
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="В одну сторону"
                        htmlFor="ow"
                        data-tooltip-id="ow"
                        className="airplane-icon-label">
                        <img src={trip} alt="one_way_trip"/>
                    </label>
                    <input
                        className="btn-trip-radio"
                        type="radio"
                        name="flight"
                        id="rt"
                        checked={flight === "rt"}
                        onClick={() => {
                            setApplicationFormFlight("rt");
                        }}
                    />
                    <label
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Туда-обратно"
                        htmlFor="rt"
                        data-tooltip-id="rt"
                        className="airplane-icon-label">
                        <img src={roundTrip} alt="round_trip"/>
                    </label>
                    <input
                        className="btn-trip-radio"
                        type="radio"
                        name="flight"
                        id="cf"
                        checked={flight === "cf"}
                        onClick={() => {
                            setApplicationFormFlight("cf");
                        }}
                    />
                    <label
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Сложный маршрут"
                        htmlFor="cf"
                        data-tooltip-id="cf"
                        className="airplane-icon-label">
                        <img src={difficultFlight} alt="difficult_flight"/>
                    </label>
                </div>
            </div>
            <div className="addOrder-half-flight-type addOrder-half-flight-type_vertical">
                <FormLabel>Тип рейса</FormLabel>
                <div className="addOrder-data-flight-type">
                    {
                        (Object.values(FlightTypes) as Array<FlightTypes>).map(one => (
                            <RadioBox
                                name="flight-type"
                                value={one}
                                id={one}
                                checked={one ? one === flightType.toString() : undefined}
                                label={translateFlightTypes(one)}
                                onClick={() => {
                                    changeFlightType(one);
                                    setApplicationFormFlightType(one);
                                }}
                            />
                        ))
                    }
                </div>
            </div>
            {
                flightType !== 'plane' && (
                    <div className="addOrder-line_air-timetable">
                        <a className="air-timetable_timetable-link-link"
                           target="_blank"
                           href="https://www.aviakassa.com/">
                            Посмотреть расписание</a>
                    </div>
                )
            }
        </div>
    )
});

export default ApplicationFormFlightType;
