import React from "react";
import {Text} from "../../../../../components/text";
import {Checkbox} from "../../../../../components/checkbox/checkbox";
import {changeFoodFormLeg, IFoodFormLeg} from "../../../store/food-form.store";

interface IProps {
    foodLeg: IFoodFormLeg;
}

export const FoodFormDishes: React.FC<IProps> = ({foodLeg}) => {

    const dishes = [
        {
            id: 1,
            name: "Ланч бокс",
            field: "launch_box",
            value: foodLeg.launch_box,
        },
        {
            id: 2,
            name: "Фарфор",
            field: "porcelain",
            value: foodLeg.porcelain,
        },
        {
            id: 3,
            name: "Дестер",
            field: "dester",
            value: foodLeg.dester,
        },
    ]

    return (
        <div>
            <div className="addOrderCompanies">
                {dishes.map((dish) => (
                    <div key={dish.name} className="addOrderCompanies-item">
                        <Checkbox
                            checked={dish.value}
                            onChange={() => {
                                changeFoodFormLeg({
                                    ...foodLeg,
                                    [dish.field]: !dish.value
                                });
                            }}
                        >
                            <Text type="text">{dish.name}</Text>
                        </Checkbox>
                    </div>
                ))}
            </div>
        </div>
    )
}
