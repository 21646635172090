import React, {FC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useStore} from "effector-react";
import {$authorizedUser} from "../../../../domains/user/store/authorized-user.store";
import {EUserType} from "../../../../domains/user/types/user.types";
import {FoodList} from "../../../../domains/food/views/food-list/food-list";
import {FoodForm} from "../../../../domains/food/views/food-form/food-form";
import {Food} from "../../../../domains/food/views/food/food";

export const Foods: FC = () => {
    const user = useStore($authorizedUser);

    if (user === null) {
        return null;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    user.type === EUserType.TEAM
                        ? (
                            <FoodList />
                        ) : null
                }
            />
            <Route
                path="/food-form"
                element={
                    user.type === EUserType.TEAM
                        ? (
                            <FoodForm />
                        )
                        : (
                            <Navigate to="/dashboard/food-list" />
                        )
                }
            />
            <Route
                path="/:id"
                element={
                    user.type === EUserType.TEAM
                    && (
                        <Food />
                    )
                }
            />
        </Routes>
    );
};
