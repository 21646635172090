import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import {TextArea} from "../textArea";
import {Button} from "react-bootstrap";
import {MessageItem} from "./chat-item/chat-item";
import {useStore} from "effector-react";
import {$userSettingsEmail, getUserSettings} from "../../domains/user/store/user-settings.store";
import {FileInputMessage} from "../filesInput/fileInputMessage";
import {IMessages} from "../../domains/application/types/application.types";
import "./chat.css"
import {$application} from "../../domains/application/store/application.store";
import {$offer} from "../../domains/offer/store/offer.store";

interface IChatProps {
    messages: IMessages[],
    onSend(message?: string, file?: FileList | null): void,
}

export const Chat:React.FC<IChatProps> = ({
  messages,
  onSend
}) => {

    const [message, setMessage] = useState<string | undefined>(undefined);
    const [messageFile, setMessageFile] = useState<FileList | null>(null);

    const userEmail = useStore($userSettingsEmail);
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const childRef = useRef<{ reset(): void }>(null);
    const application = useStore($application);
    const offer = useStore($offer);

    const scrollToBottom = () => {
        const lastItem = chatContainerRef.current;
        if (lastItem) {
            lastItem.scroll({ top: lastItem.scrollHeight, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        getUserSettings();
    }, []);

    const handleSendClick = useCallback(() => {
        onSend(message, messageFile);

        if (childRef.current) {
            childRef.current.reset();
        }

        setMessage("");
        setMessageFile(null);
    }, [message, messageFile, onSend, setMessage, setMessageFile]);

    const handleInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    }, [setMessage]);

    const handleFileChange = useCallback((file: FileList | null) => {
        if(!file?.length) {
            return null
        }
        setMessageFile(file);
    }, [setMessageFile]);

    if(messages === null) {
        return null;
    }

    return (
        <>
            <div id="chat-list" className="chat-list" ref={chatContainerRef}>
                <div className="chat-conversation">
                    {
                        messages.map((message, index, messagesNumber)  => <MessageItem key={index} message={message} userEmail={userEmail}/>
                        )}
                </div>
            </div>
            <div className="chatFormWrapper">
                <form className="chat-form">
                    <TextArea value={message} onChange={handleInputChange}
                              placeholder="Введите текст сообщения" className="chat-input"></TextArea>
                    <Button
                        className="chat-button"
                        variant="primary"
                        onClick={handleSendClick}
                        disabled={application?.app_status === "Отменена" || offer?.status === "18"}
                    >
                        Отправить
                    </Button>
                </form>
                    <div className="file-attach-message">
                        <FileInputMessage
                            ref={childRef}
                            onChange={handleFileChange}
                        />
                    </div>
            </div>
        </>
    )
}


