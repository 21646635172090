import moment from 'moment/moment';

export function dateToDMY(date: Date) {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '.' + (m<=9 ? '0' + m : m) + '.' + y;
}

export function dateTimeToDMY(dateString: string | null) {
    if (dateString === null) return '';

    const date = new Date(dateString);
    if (date) {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        const h = date.getHours();
        const mm = date.getMinutes();
        return '' + (d <= 9 ? '0' + d : d) + '.' + (m <= 9 ? '0' + m : m) + '.' + y + ' ' + (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm);
    }
    return '';
}

export function dateToISOString(data: Date): string {
    return moment(data).format("YYYY-MM-DDTHH:mm:ss")
}
