
import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IUserList} from "../types/user.types";

export type TGetUsersListResponse = IUserList[];

export const getUsersListHttp = async (): Promise<TGetUsersListResponse> => {
    const getUsersListResponse = await httpRequest("users-list");

    return getUsersListResponse.json();
}
