import {createEffect, createEvent, createStore, forward} from "effector";
import {getSeatBlocksHttp, TGetSeatBlocksResponse} from "../http/get-seat-blocks.http";
import {ISeatBlock} from "../types/seat-blocks.types";

export const getSeatBlocks = createEvent<void>();

export const getSeatBlocksFx = createEffect<
    void,
    TGetSeatBlocksResponse
>(() => {
    return getSeatBlocksHttp();
});

const $seatBlocks = createStore<ISeatBlock[] | null>(null)
    .on(getSeatBlocksFx.doneData, (_, payload) => payload)
    .on(getSeatBlocksFx.fail, () => null);

export const $filteredSeatBlocks = $seatBlocks
    .map((seatBlocks) => {
        if (seatBlocks) {
            return seatBlocks.filter((block) => {
                const legDepartureDate = new Date(block.departure_datetime).getTime();
                const differenceInSeconds = (legDepartureDate - Date.now()) / 1000;

                return differenceInSeconds > 2 * 3600;
            });
        }

        return null;
    })
    .map((seatBlocks) => {
        seatBlocks?.sort((a, b) => {
            const aDepartureDate = new Date(a.departure_datetime).getTime();
            const bDepartureDate = new Date(b.departure_datetime).getTime();

            return aDepartureDate - bDepartureDate;
        });

        return seatBlocks;
    });

forward({
    from: getSeatBlocks,
    to: getSeatBlocksFx,
});
