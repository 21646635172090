import React, {FC, useCallback, useEffect} from "react";
import {Card, FormLabel} from "react-bootstrap";

import ApplicationFormServices from "./application-form-services/application-form-services";
import {Button} from "../../../../components/button";
import {
    $applicationFormComment, $applicationFormFlightType,
    $applicationFormLegs,
    resetApplicationForm, saveApplicationForm, saveApplicationFormFx,
    setApplicationFormComment, setApplicationFormFiles,
} from "../../store/application-form.store";
import {useStore} from "effector-react";
import {Text} from "../../../../components/text";
import {TextArea} from "../../../../components/textArea";
import {FilesInput} from "../../../../components/filesInput";
import {Link, useNavigate} from "react-router-dom";
import "./application-form.css";
import ApplicationFormAirlinesRegular from "./application-form-airlines/application-form-airlines-regular";
import ApplicationFormAirlines from "./application-form-airlines/application-form-airlines";
import ApplicationFormFlightType from "./application-form-flight-type/application-form-flight-type";
import ApplicationFormLeg from "./application-form-leg/application-form-leg";


export const ApplicationForm: FC = () => {
    const navigate = useNavigate();

    const applicationFormLegs = useStore($applicationFormLegs);
    const applicationFormComment = useStore($applicationFormComment);
    const isApplicationFormSaving = useStore(saveApplicationFormFx.pending);
    const flightType = useStore($applicationFormFlightType);

    useEffect(() => {
        return () => {
            resetApplicationForm();
        };
    }, []);

    const saveApp = useCallback(() => {
        saveApplicationForm(() => {
            navigate("/dashboard/applications");
        });
    }, [navigate]);

    return (
        <>
            <Card className="addOrder">
                <div className="addOrder-top">
                    <Text type="subtitle" className="addOrder-title">Укажите маршрут</Text>
                    <div>
                        <Link to="/dashboard/applications">
                            <Button className="addOrder-desktop" variant="link">{'<'} Актуальные заявки</Button>
                        </Link>
                    </div>
                </div>
                <ApplicationFormFlightType/>
                {
                    applicationFormLegs.map((leg) => (
                        <ApplicationFormLeg
                            key={leg.id}
                            leg={leg}
                        />
                    ))
                }
                <div className="addOrder-line-offer">
                    <div className="addOrder-half addOrder-half_vertical">
                        {
                            flightType  === 'regular' && (
                                <ApplicationFormAirlinesRegular />
                            )
                        }
                        {
                            flightType !== 'regular' && (
                                <ApplicationFormAirlines />
                            )
                        }
                    </div>
                </div>
                <div className="addOrder-separator" />
                <Text type="subtitle" className="addOrder-title addOrder-secondary">Дополнительные услуги</Text>
                <ApplicationFormServices />
                <div className="addOrder-line-offer addOrder-comments">
                    <div className="addOrder-half addOrder-half_vertical-comment">
                        <FormLabel>Комментарий</FormLabel>
                        <TextArea
                            value={applicationFormComment}
                            onChange={(event) => {
                                setApplicationFormComment(event.target.value);
                            }}
                        />
                    </div>
                    <div className="addOrder-half addOrder-half_vertical">
                        <FormLabel>Файлы</FormLabel>
                        <FilesInput
                            onChange={(files) => {
                                setApplicationFormFiles(files);
                            }}
                        />
                    </div>
                </div>
                <div className="addOrder-separator" />
                <Button
                    className="addOrder-submit addOrder-desktop"
                    variant="primary"
                    disabled={isApplicationFormSaving}
                    onClick={saveApp}>
                    Отправить заявку в авиакомании
                </Button>
            </Card>
            <div className="addOrder-Block addOrder-mobile">
                <Button
                    className="addOrder-submit"
                    variant="primary"
                    disabled={isApplicationFormSaving}
                    onClick={saveApp}>
                    Отправить заявку в авиакомании
                </Button>
            </div>
        </>
    );
};

