import React, {FC, useEffect} from "react";
import {$passingFlightsTeam, getPassingFlightsTeam} from "../../store/passing-flights-team.store";
import {useStore} from "effector-react";
import {IPassingFlight} from "../../types/passing-flight.types";
import {$applicationFormLegs} from "../../../application/store/application-form.store";
import {PassingFlightTeamItem} from "./passing-flight-team-item/passing-flight-team-item";
import { Card } from "react-bootstrap";

export const PassingFlightsTeam: FC = () => {

    useEffect(() => {
        getPassingFlightsTeam();
    }, []);

    const applicationFormLegs = useStore($applicationFormLegs);
    const passingFlightsListTeam = useStore($passingFlightsTeam);

    if(passingFlightsListTeam === null) {
        return null
    }

    return (
        <div className="row">
            <div className="title">
                <div className="title-text mb-4">Попутные рейсы</div>
                <div className="title-under-text mb-3">
                    Часто авиакомпании, совершая перелет в одну сторону с пассажирами, обратный перелет к месту
                    базирования самолета или для совершения другого рейса летят пустыми (без пассажиров), расходуя
                    ресурсы самолета напрасно. В таких случаях для авиакомпании выгодно продать пустой перелет на
                    фиксированный маршрут и дату для организации чартера. При этом стоимость такого чартера значительно
                    ниже, чем аренда самолета целиком.
                </div>
                <div className="title-under-text mb-3">
                    Для бронирования и покупки чартерного рейса на предложенные направления и даты свяжитесь с нами по
                    тел: <a href="tel:+74955055797">+7 (495) 505-57-97</a> или email: <a
                    href="mailto:charter@globus.aero">charter@globus.aero</a>
                </div>
            </div>
            <Card>
                {
                    passingFlightsListTeam.length === 0
                        ? (
                            <p className="title-under-text mb-9">На данный момент актуальных предложений нет</p>
                        )
                        : (
                            <div className="list">
                                {
                                    passingFlightsListTeam.map((passingFlight: IPassingFlight) => (
                                        <PassingFlightTeamItem
                                            passingFlight={passingFlight}
                                            key={passingFlight.id}
                                            leg={applicationFormLegs[0]}
                                        />
                                    ))
                                }
                            </div>
                        )
                }
            </Card>
        </div>
    );
};

