import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "./craft-bootstrap.css";
import {App} from "./components/app/app";
import dayjs from "dayjs";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';

import "dayjs/locale/ru";
registerLocale('ru', ru);
setDefaultLocale('ru');

dayjs.locale("ru");

const htmlRoot = document.getElementById("root");

if (htmlRoot) {
    const root = ReactDOM.createRoot(htmlRoot);

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );

    // reportWebVitals(console.log);
}
