import React, {FC} from "react";
import {Dropdown, Nav} from "react-bootstrap";
import {Button} from "../button";
import {$authorizedUser, logoutUser} from "../../domains/user/store/authorized-user.store";
import {Navigation} from "../navigation";
import './header.css';
import userIcon from "../main/start/uploads/user.svg";
import {useStore} from "effector-react";
import {NavLink} from "react-router-dom";

export const Header: FC = () => {
    const user = useStore($authorizedUser);
    const userLogo = user?.logo ? `/api/${user?.logo}` : userIcon;

    return (
        <div className="header">
            <div className="header-menu">
                <Dropdown>
                    <Dropdown.Toggle id="menuDropdownButton" className="button-menu-burger" variant="white">
                        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 1.6667C0 0.746206 0.746206 0 1.6667 0H22.3333C23.2538 0 24 0.746206 24 1.6667C24 2.58719 23.2538 3.3334 22.3333 3.3334H1.6667C0.746207 3.3334 0 2.58719 0 1.6667Z" fill="#7A7A90"/>
                            <path d="M0 9.99971C0 9.07921 0.746206 8.33301 1.6667 8.33301H15.4762C16.3967 8.33301 17.1429 9.07921 17.1429 9.99971C17.1429 10.9202 16.3967 11.6664 15.4762 11.6664H1.6667C0.746205 11.6664 0 10.9202 0 9.99971Z" fill="#7A7A90"/>
                            <path d="M0 18.3337C0 17.4132 0.746206 16.667 1.6667 16.667H8.61901C9.53951 16.667 10.2857 17.4132 10.2857 18.3337C10.2857 19.2542 9.53951 20.0004 8.61902 20.0004H1.6667C0.746208 20.0004 0 19.2542 0 18.3337Z" fill="#C6C6CC"/>
                        </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="header-menu-dropdown">
                        <Navigation />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <Nav.Item className="start-phone">
                    {user?.organization_name}
                </Nav.Item>
                <Nav.Item className="start-user-menu-wrapper">
                    <NavLink to="/dashboard/settings">
                        <div className="start-user-menu" style={{cursor: "default"}}>
                            <img className="img-logo-user" src={userLogo} alt="Header-logo" />
                        </div>
                    </NavLink>
                </Nav.Item>
                <Button
                    variant="white"
                    onClick={() => logoutUser()}
                >
                    Выход
                </Button>
            </div>
        </div>
    );
};
