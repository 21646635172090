import {combine, createEffect, createEvent, createStore, restore, sample} from "effector";
import {validateEmail, validatePassword, validatePasswordConfirm} from "../../../shared/validations/validations";
import {
    resetUserRegistrationForm,
} from "./user-registration.store";
import {recoverPasswordHttp} from "../http/recover-password.http";
import {getResetPasswordHttp, TGetResetPasswordResponse} from "../http/get-reset-password.http";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";

export interface INewPassword {
    username: string,
    password: string,
    token: string,
    isNewPasswordValid: boolean,
    isNewPasswordConfirmValid: boolean,
}

export const setUserMail = createEvent<string>();
export const setNewPassword = createEvent<string>();
export const setNewPasswordConfirm = createEvent<string>();
export const resetUserRecoverPasswordForm = createEvent<void>();
export const sendRecoverPassword = createEvent<INewPassword>();

export const getResetPasswordFx = createEffect<
    string,
    TGetResetPasswordResponse
>(   (userMail: string) => {
    return getResetPasswordHttp(userMail);
});

export const sendRecoverPasswordFx = createEffect <
    INewPassword,
    void
>(async ({
    username,
    password,
    token,
    isNewPasswordValid,
    isNewPasswordConfirmValid,
}) => {
            try {
                const createRecoverPasswordResponse = await recoverPasswordHttp(
                    username,
                    password,
                    token,
                );
                const result = await createRecoverPasswordResponse.text();
            } catch (e: any) {}
        throw new Error("validation error");
    });

export const $userMail = restore<string>(
    setUserMail,
    "",
)
    .reset(resetUserRecoverPasswordForm);
export const $userMailValid = createStore(false)
    .on($userMail, (_, userMail) => validateEmail(userMail))
    .reset(resetUserRecoverPasswordForm);

export const $userNewPassword = restore<string>(
    setNewPassword,
    "",
)
    .reset(resetUserRecoverPasswordForm);

export const $isNewPasswordValid = createStore(false)
    .on($userNewPassword, (_, password) => validatePassword(password))
    .reset(resetUserRecoverPasswordForm);

export const $newPasswordConfirm = restore<string>(
    setNewPasswordConfirm,
    "",
)
    .reset(resetUserRecoverPasswordForm);

const $userNewPasswords = combine({
    password: $userNewPassword,
    passwordConfirm: $newPasswordConfirm,
});

export const $isNewPasswordConfirmValid = createStore(true)
    .on($userNewPasswords, (_, passwords) => {
        return validatePasswordConfirm(passwords.password, passwords.passwordConfirm);
    })
    .reset(resetUserRegistrationForm);

sample({
    clock: sendRecoverPassword,
    target: sendRecoverPasswordFx,
    fn: (source) => ({
        ...source,
    }),
});

getResetPasswordFx.done.watch(() => {
    showNotice("primary", "На вашу почту отправоено письмо с инструкцией по восстановлению пароля.");
});

getResetPasswordFx.fail.watch(() => {
    showNotice("danger", "Во время сброса пароля произошла ошибка. Повторите операцию позже.");
});

