import React, {FC, useEffect, useMemo} from "react";
import {useStore} from "effector-react";
import {$passingFlightsAk, getPassingFlightsAk} from "../../store/passing-flights-ak.store";
import {IPassingFlight} from "../../types/passing-flight.types";
import "./passing-flights-ak.css";
import {PassingFlightForm} from "./passing-flight-form/passing-flight-form";
import dayjs from "dayjs";
import {PassingFlightAkItem} from "./passing-flight-ak-item/passing-flight-ak-item";
import { Card } from "react-bootstrap";

export const PassingFlightsAk: FC = () => {

    useEffect(() => {
        getPassingFlightsAk();
    }, []);

    const passingFlightsListAk = useStore($passingFlightsAk);

    const currentDate = useMemo(() => {
        return dayjs().format("DD.MM.YYYY")
    }, []);


    if (passingFlightsListAk === null) {
        return null;
    }

    return (
        <div className="row">
            <div className="title">
                <div className="title-text mb-4">Актуальные попутные рейсы</div>
                <div className="title-date">На {currentDate}</div>
                <div className="title-under-text mb-3">
                    Часто авиакомпании, совершая перелет в одну сторону с пассажирами, обратный перелет к месту
                    базирования самолета или для совершения другого рейса летят пустыми (без пассажиров), расходуя
                    ресурсы самолета напрасно. В таких случаях для авиакомпании выгодно продать пустой перелет на
                    фиксированный маршрут и дату для организации чартера. При этом стоимость такого чартера значительно
                    ниже, чем аренда самолета целиком.
                </div>
            </div>
            <Card>
               {
                   passingFlightsListAk.length === 0
                    ? (
                        <p className="title-under-text mb-9">На данный момент актуальных предложений нет</p>
                     ) : (
                           <div className="list">
                               {
                                   passingFlightsListAk.map((passingFlight: IPassingFlight) => (
                                       <PassingFlightAkItem
                                           passingFlight={passingFlight}
                                           key={passingFlight.id}
                                       />
                                   ))
                               }
                           </div>
                       )
              }
            </Card>
            <div className="form-separator"></div>
            <div className="title-under-text mb-3">
                Для возможности продажи билетов на попутные рейсы заполните поля и клиенты увидят ваши перелеты в списках предложений
            </div>
            <PassingFlightForm />
        </div>
    );
};

