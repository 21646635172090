import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IAirport} from "../types/airport.types";

export type TGetAirportsHttpResponse = IAirport[];

export const getAirportsHttp = async (): Promise<TGetAirportsHttpResponse> => {
    const getAirportsResponse = await httpRequest("airports");

    return getAirportsResponse.json();
};
