import {createEffect, createEvent, createStore, forward, sample} from "effector";
import {getOfferHttp, TGetOfferResponse} from "../http/get-offer.http";
import {IOffer} from "../types/offer.types";
import {addInfoToOfferHttp} from "../http/add-info-to-offer.http";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";

export const getOffer = createEvent<number>();
export const changeOfferPrice = createEvent<{price: string}>();
export const changeOfferComment = createEvent<{comment: string}>();
export const changeOfferAircraft = createEvent<{aircraft: number}>();
export const changeOfferFiles = createEvent<{files: FileList}>();

export const saveOffer = createEvent<() => void>();

export const getOfferFx = createEffect<
    number,
    TGetOfferResponse
>((id: number) => {
    return getOfferHttp(id);
});

export const saveOfferFx = createEffect<
    {
        source: IOffer,
        files: Record<number, FileList>,
        callback: () => void,
    },
    any
>(async ({source, files, callback}) => {
    await addInfoToOfferHttp(
        source.id,
        source.price,
        source.aircraft,
        source.comment,
        files,
    );

    callback();
});

export const $offerFiles = createStore<Record<number, FileList>>({})
    .on(changeOfferFiles, (value, payload) => {
        return {
            ...value,
            files: payload.files,
        };
    });

export const $offer = createStore<IOffer | null>(null)
    .on(getOfferFx.doneData, (_, payload) => payload)
    .on(getOfferFx.fail, () => null)
    .on(changeOfferPrice, (offer, payload) => {
        if (offer) {
            return {
                ...offer,
                price: parseInt(payload.price, 10),
            };
        }

        return null;
    })
    .on(changeOfferComment, (offer, payload) => {
        if (offer) {
            return {
                ...offer,
                comment: payload.comment,
            };
        }
        return null;
    })
    .on(changeOfferAircraft, (offer, payload) => {
        if (offer) {
            return {
                ...offer,
                aircraft: {
                    id: payload.aircraft,
                    type: "",
                    logo: "",
                    salon: "",
                    max_pax: 0,
                },
            };
        }

        return null;
    });

forward({
    from: getOffer,
    to: getOfferFx,
});

sample({
    clock: saveOffer,
    target: saveOfferFx,
    source: {
        offer: $offer,
        offerFiles: $offerFiles,
    },
    fn: (source, callback) => {
        return {
            source: source.offer as IOffer,
            files: source.offerFiles,
            callback,
        };
    },
    filter: (source) => source.offer !== null,
});

saveOfferFx.fail.watch(() => {
    showNotice("danger", "Во время отправки предложения произошла ошибка. Повторите операцию позже.");
});
