import React, { FC } from "react";
import { Button as ButtonBase, ButtonProps } from "react-bootstrap";

import './button.css';

interface IButtonProps extends ButtonProps {
    variant?: ButtonProps['variant'] | 'action' | 'grey' | 'white' | 'red' | 'green';
}

export const Button: FC<IButtonProps> = ({ ...props }) => {
    return <ButtonBase {...props} />
}
