import { httpRequest } from "../../../shared/http/http-request/http-request";
import { dateToISOString } from '../../../shared/utils/dateUtils';

export const creatGameCalendarHttp = (
    teamId: number,
    game_name: string,
    game_date: Date | null
): Promise<Response> => {
    return httpRequest(`game-calendar`, {
        method: "POST",
        body: JSON.stringify({
                team_id: teamId,
                game_name,
                game_date: game_date ? dateToISOString(game_date) : null
            }),
    })
}
