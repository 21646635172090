import React, {forwardRef} from "react";
import {
    addFoodFormLeg,
    changeFoodFormLeg,
    IFoodFormLeg,
} from "../../../store/food-form.store";
import {Input} from "../../../../../components/input/input";
import {dateTimeToDMY} from "../../../../../shared/utils/dateUtils";
import {FormLabel} from "react-bootstrap";
import {SearchSelect} from "../../../../../components/search-select/search-select";
import DatePicker from "react-datepicker";
import {TextArea} from "../../../../../components/textArea";
import plus2 from "../../../../team/views/game-calendar/plus2.png";
import {useStore} from "effector-react";
import {$airports} from "../../../../airport/store/airport.store";
import "../../../../application/views/application-form/application-form.css";
import {FoodFormDishes} from "../food-form-dishes/food-form-dishes";

interface IProps {
    foodLeg: IFoodFormLeg;
}

export const FoodFormLeg: React.FC<IProps> = ({
    foodLeg
}) => {

    const airports = useStore($airports);

    const DatePickerInput = forwardRef<HTMLInputElement>(({ value, onClick }: any, ref) => {
        return (
            <Input
                variant="solid"
                value={value.length === 0 ? "" : dateTimeToDMY(value)}
                onChange={() => null}
                onClick={onClick}
                inputRef={ref}
            />
        );
    });

    return (
        <div className="addOrderFlight">
            <div className="addOrder-line-offer">
                <div className="addOrder-half addOrder-half_vertical">
                    <FormLabel>Вылет</FormLabel>
                    <SearchSelect
                        name={`${foodLeg.id}-departure_airport`}
                        placeholder="Выберите аэропорт"
                        onChange={(event) => {
                            changeFoodFormLeg({
                                ...foodLeg,
                                departure_airport: parseInt(event, 10),
                            });
                        }}
                    >
                        {
                            airports?.map((airport) => (
                                <option key={airport.id} value={airport.id}>
                                    {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                </option>
                            ))
                        }
                    </SearchSelect>
                </div>
                <div className="addOrder-half addOrder-half_vertical">
                    <FormLabel>Прилет</FormLabel>
                    <SearchSelect
                        name={`${foodLeg.id}-arrival_airport`}
                        placeholder="Выберите аэропорт"
                        onChange={(event) => {
                            changeFoodFormLeg({
                                ...foodLeg,
                                arrival_airport: parseInt(event, 10),
                            });
                        }}
                    >
                        {
                            airports?.map((airport) => (
                                <option key={airport.id} value={airport.id}>
                                    {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                </option>
                            ))
                        }
                    </SearchSelect>
                </div>
                <div className="addOrder-half addOrder-half_vertical">
                    <FormLabel>Дата и время</FormLabel>
                    <DatePicker
                        selected={foodLeg.departure_datetime}
                        onChange={(date: Date) => {
                            changeFoodFormLeg({
                                ...foodLeg,
                                departure_datetime: date,
                            });
                        }}
                        customInput={<DatePickerInput />}
                        minDate={new Date()}
                        timeFormat="HH:mm"
                        dateFormat="yyyy-MM-dd HH:mm"
                        timeIntervals={10}
                        showTimeSelect
                    />
                </div>
                <div className="addOrder-line-offer">
                    <div className="addOrder-half addOrder-half_vertical">
                        <FormLabel>Номер рейса</FormLabel>
                        <TextArea
                            rows={1}
                            value={foodLeg.regular_number}
                            onChange={(event) => {
                                changeFoodFormLeg({
                                    ...foodLeg,
                                    regular_number: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="addOrder-line-offer">
                    <div className="addOrder-half addOrder-half_vertical">
                        <FormLabel>Кол-во порций</FormLabel>
                        <TextArea
                            rows={1}
                            value={foodLeg.portions === 0 ? "" : foodLeg.portions.toString()}
                            onChange={(event) => {
                                let preparedValue = event.target.value.replace(/\D/g, "");
                                preparedValue = preparedValue === "" ? "0" : preparedValue;
                                changeFoodFormLeg({
                                    ...foodLeg,
                                    portions: parseInt(preparedValue, 10),
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="add-flight-btn">
                    <button
                        className="btn-add-game"
                        onClick={() => {
                            addFoodFormLeg();
                        }}
                    ><img className="add-icon-image" src={plus2} alt="add button"/></button>
                </div>
            </div>
            <div className="addOrder-line-offer">
                <FoodFormDishes foodLeg={foodLeg}/>
            </div>
        </div>
    )
}
