import React, {FC, PropsWithChildren} from "react";

import './content.css';

export const Content: FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <div className="content">
            {children}
        </div>
    );
};
