import {FC, useCallback, useEffect, useState} from "react";
import {NoticeBubbleItem} from "./notice-bubble-item/notice-bubble-item";
import "./notice-bubble.css";

export const showNotice = (
    theme: "primary" | "danger",
    content: string,
) => {
    window.postMessage(JSON.stringify({
        type: "NOTICE_BUBBLE",
        payload: {
            theme,
            content,
        },
    }));
};

export const NoticeBubble: FC = () => {
    const [noticeList, setNoticeList] = useState<any[]>([]);

    const removeNotice = useCallback((noticeId: number) => {
        setNoticeList((oldList) => {
            return oldList.filter(({id}) => id !== noticeId);
        });
    }, []);

    useEffect(() => {
        const messageHandler = (event: any) => {
            try {
                const message = JSON.parse(event.data);

                if (message.type === "NOTICE_BUBBLE") {
                    const id = Math.random();

                    setNoticeList((oldList) => ([
                        ...oldList,
                        {
                            id,
                            ...message.payload,
                        },
                    ]));

                    setTimeout(() => {
                        removeNotice(id);
                    }, 5000);
                }
            } catch (_: unknown) {}
        };

        window.addEventListener("message", messageHandler);

        return () => {
            window.removeEventListener("message", messageHandler);
        };
    }, [removeNotice]);

    return (
        <div className="notice-bubble-container">
            {
                noticeList.map(({id, theme, content}) => (
                    <NoticeBubbleItem
                        key={id}
                        id={id}
                        theme={theme}
                        content={content}
                        onRemove={removeNotice}
                    />
                ))
            }
        </div>
    );
};
