import {createEffect, createEvent, createStore, forward} from "effector";
import {IGame} from "../types/team.type";
import {getGamesCalendarHttp, TGetGamesCalendarResponse} from "../http/get-game-calendar.http";
import orderBy from 'lodash/orderBy';
import moment from 'moment';

export const getGamesCalendar = createEvent<number>();

export const getGamesCalendarFx = createEffect<
    number,
    TGetGamesCalendarResponse
>( (id: number) => {

    return getGamesCalendarHttp(id);
});

export const $gamesCalendar = createStore<IGame[] | null>(null)
    .on(getGamesCalendarFx.doneData, (_, payload) => {
      if (payload && !!payload.length) {
        return orderBy(payload, (o: any) => {
          return moment(o.game_date);
        }, [])
      }
        return payload;
    })
    .on(getGamesCalendarFx.fail, () => null)

forward({
    from: getGamesCalendar,
    to: getGamesCalendarFx,
});


