import {httpRequest} from "../../../shared/http/http-request/http-request";

export type TGetNewMessagesResponse = {airlines_id: number[]};

export const getNewMessagesHttp = async (
    id: number,
): Promise<TGetNewMessagesResponse> => {
    const getNewMessagesResponse = await httpRequest(`application/${id}/new-messages`);

    return await getNewMessagesResponse.json();
};
