import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IApplication} from "../types/application.types";

export type TGetApplicationResponse = IApplication;

export const getApplicationHttp = async (
    id: number,
): Promise<TGetApplicationResponse> => {
    const getApplicationResponse = await httpRequest(`application/${id}`);

    return getApplicationResponse.json();
};
