import React, {FC, useEffect, useMemo, useState} from "react";
import {
    $userSettingsCharterEmailByAdmin,
    $userSettingsGroupEmailByAdmin,
    $userSettingsIsTelegramValidByAdmin,
    $userSettingsNameByAdmin,
    $userSettingsOrganizationNameByAdmin,
    $userSettingsPhoneByAdmin,
    $userSettingsTelegramByAdmin, $userType,
    getUserByAdmin, saveUserSettingsByAdmin, setUserSettingsCharterEmailByAdmin, setUserSettingsGroupEmailByAdmin,
    setUserSettingsNameByAdmin,
    setUserSettingsOrganizationNameByAdmin,
    setUserSettingsPhoneByAdmin,
    setUserSettingsTelegramByAdmin
} from "../../../store/user-settings-by-admin.store";
import {Link, useParams} from "react-router-dom";
import {useStore} from "effector-react";
import {Card, FormLabel} from "react-bootstrap";
import {Text} from "../../../../../components/text";
import {Input} from "../../../../../components/input/input";
import userLogo from "../../user-settings/userLogo.png";
import {Button} from "../../../../../components/button";


export const EditUserByAdmin: FC = () => {

    const {id} = useParams();
    const userId = useMemo(() => Number(id), [id]);

    useEffect(() => {
        if (userId) {
            getUserByAdmin(userId);
        }
    }, [userId]);

    const phone = useStore($userSettingsPhoneByAdmin);
    const contactName = useStore($userSettingsNameByAdmin);
    const telegram = useStore($userSettingsTelegramByAdmin);
    const isUserTelegramValid = useStore($userSettingsIsTelegramValidByAdmin);
    const organizationName = useStore($userSettingsOrganizationNameByAdmin);
    const groupEmail = useStore($userSettingsGroupEmailByAdmin);
    const charterEmail = useStore($userSettingsCharterEmailByAdmin);
    const typeOfUser = useStore($userType);

    const [selectedImage, setSelectedImage] = useState<File | null>(null);


    return (
        <>
            <Card className="settings">
                <div className="users-back">
                <Text
                    type="subtitle"
                    className="settings-title"
                >
                    Информация о пользователе
                </Text>

                    <Link to="/dashboard/users-list">
                        <Button variant="link">{'<'} Список пользователей</Button>
                    </Link>
                </div>
                <div className="settings-line">
                    <div className="settings-half settings-half_vertical">
                        <div className="vertical-field_user_name">
                            <FormLabel>Имя и фамилия</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={contactName}
                                onChange={(event) => {
                                    setUserSettingsNameByAdmin(event.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <FormLabel>Ник в Telegram</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={telegram}
                                onChange={(event) => {
                                    setUserSettingsTelegramByAdmin(event.target.value);
                                }}
                            />
                            {
                                telegram && !isUserTelegramValid  && (
                                    <Text type="text" className="user-sign-up-form-validation">
                                        <ul>
                                            <li>Допустим ввод симовлов a-z и нижнее подчеркивание</li>
                                            <li>Длина не менее 5 символов</li>
                                        </ul>
                                    </Text>
                                )
                            }
                        </div>
                    </div>
                    <div className="settings-half">
                        <div className="settings-item">
                            <FormLabel>Телефон</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={phone}
                                onChange={(event) => {
                                    setUserSettingsPhoneByAdmin(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="settings-separator"/>
                <div className="settings-line">
                    <div className="setting-logo-wrapper">
                        <Text type="text" className="settings-logo-title">
                            Логотип
                        </Text>
                        <div className="settings-logo-wrapper">
                            {selectedImage ? selectedImage && (
                                <div className="upload-logo-wrapper">
                                    <div className="user-logo">
                                        <img className="user-logo-item"
                                             src={URL.createObjectURL(selectedImage)}
                                             alt="user-logo"
                                        />
                                    </div>
                                    <button className="delete-logo-btn" onClick={() => {}}>Удалить
                                    </button>
                                </div>
                            ) : <img src={userLogo} alt="user-logo"/>}
                            { !selectedImage && (
                                <label htmlFor="input-logo" className="input-button-label">
                                    Загрузить
                                </label>)
                            }
                            <input
                                className="input-button"
                                id="input-logo"
                                type='file'
                                accept="image/*"
                                onChange={() => {}}
                            />
                        </div>
                    </div>
                    <div className="user-settings-org-data">
                        <div className="settings-half settings-half_vertical">
                            <FormLabel>Название</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={organizationName}
                                onChange={(event) => {
                                    setUserSettingsOrganizationNameByAdmin(event.target.value);
                                }}
                            />
                        </div>
                        {
                            typeOfUser === "airline" && (
                                <>
                                <div className="settings-half settings-half_vertical">
                                    <FormLabel>Email группового отдела</FormLabel>
                                    <Input
                                        variant="solid"
                                        type="text"
                                        value={groupEmail}
                                        onChange={(event) => {
                                            setUserSettingsGroupEmailByAdmin(event.target.value);
                                        }}
                                    />
                                </div>
                                <div className="settings-half settings-half_vertical">
                                    <FormLabel>Email чартерного отдела</FormLabel>
                                    <Input
                                        variant="solid"
                                        type="text"
                                        value={charterEmail}
                                        onChange={(event) => {
                                        setUserSettingsCharterEmailByAdmin(event.target.value);
                                    }}
                                /></div>
                                </>
                            )
                        }
                    </div>
                </div>
            </Card>
            <div className="settings-save">
                <Button
                    className="settings-submit"
                    variant="primary"
                    onClick={() => {
                        saveUserSettingsByAdmin();
                    }}
                >
                    Сохранить изменения
                </Button>
            </div>
        </>
    );
};
