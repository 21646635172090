import React, {useEffect} from "react";

export const AirTickets: React.FC = () => {
    useEffect(() => {
        new (window as any)
            .Aviakassa
            .Partner("ak-app-5942", {
                "showAvia": true,
                "showRail": false,
                "showHotel": true,
                "showAviaTitle": false,
                "showRailTitle": false,
                "showHotelTitle": false,
                "aviaTitle": "Поиск дешевых авиабилетов",
                "railTitle": "",
                "hotelTitle": "",
                "showAviakassaLogo": true,
                "showLocaleSelect": false,
                "aviaShowComplexRoute": true,
                "showAviaAirlinesPrefilter": true,
                "channelToken": "7f4dfd28590981a8421d2cc0b716eda7149e7917",
                "id": 5942
            });
    }, []);

    return (
        <div id="ak-app-5942"/>
    );
};
