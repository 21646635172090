import React, { FC } from "react";

import './footer.css';
import {Button} from "../../button";

export const FooterSection: FC = () => {
    return (
        <section className="section footer-section">
            <div className="footer-contacts" id="contacts">
                <div className="footer-data">
                    <div className="footer-email">
                        <a href="mailto:charter@globus.najet.ru">charter@globus.aero</a>
                    </div>
                    <div className="footer-phone">
                        <a href="tel:+74955055797">+7 (495) 505-57-97</a>
                    </div>
                </div>
                {/*<div className="footer-button">*/}
                {/*    <Button variant="white">Обратный звонок</Button>*/}
                {/*</div>*/}
            </div>
            <div className="footer-company">ООО «Глобус» Все права защищены, {new Date().getFullYear()}</div>
            <div className="footer-info">
                <div className="footer-info-row">
                    <a href="/rules.pdf" target={"_blank"}>Пользовательское соглашение</a>
                    <br/>
                    Продолжая работу с сайтом, Вы подтверждаете полное согласие с пользовательским соглашением.
                </div>
                {/*<div className="footer-info-row">*/}
                {/*    Далее, после регистрации, когда пользователь отправлял заявку в надежде немедленно получить обещанное сервисом предложение на авиаперевозку, он получал сообщение*/}
                {/*    о приёме заявки в работу и обещание вскоре направить ему предложения. Никакой автоматизации, по сути, нет. Так сервису придают современный вид. Технология*/}
                {/*    же не изменилась. Дальше совершались те же бесконечные телефонные звонки и переписка. А самым ценным в данном сервисе являлось приобретение контактов клиентов.*/}
                {/*</div>*/}
            </div>
        </section>
    );
}
