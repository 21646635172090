import React, { PropsWithChildren } from "react";

import './text.css';

interface ITextProps {
    className?: string;
    type: 'title' | 'subtitle' | 'caption' | 'text' | 'file' | 'small';
}

export const Text: React.FC<PropsWithChildren<ITextProps>> = ({ className, type, children }) => {
    return (
        <div className={`customText customText_type_${type}${className ? ' ' + className : ''}`}>
            {children}
        </div>
    )
}