import React from 'react';
import { Comment } from '../../../../../components/comment';
import { File } from '../../../../../components/file';
import { Text } from '../../../../../components/text';

import './offer-item.css';
import {IOffer} from "../../../types/offer.types";

interface IProps {
    offer: IOffer;
}

export const OfferItem: React.FC<IProps> = ({
    offer,
}) => {

    return (
        <div className="offer">
            <div className="offer-line">
                {
                    Boolean(offer.comment.length) && (
                        <div className="offer-comment">
                            <Text type="caption">Комментарии к заказу</Text>
                            <Comment text={offer.comment} />
                        </div>
                    )
                }
                <div className="offer-price">
                    <Text className="offer-price-value" type="text">
                        {offer.price} ₽
                    </Text>
                    <div className="offer-price-caption">Стоимость</div>
                </div>
            </div>

            <div className="offer-line mt-5">
                {
                    Boolean(offer.files.length) || Boolean() && (
                        <div className="offer-files">
                            <Text type="caption">Файлы</Text>
                            <div className="offer-files-list">
                                {
                                    offer.files.map((name) => (
                                        <File key={name} name={name} />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    offer.aircraft !== null && (
                        <div className="offer-plane">
                            <img
                                className="offer-plane-image"
                                src={`/api/${offer.aircraft.logo}`}
                                alt={offer.aircraft.type}
                            />
                        </div>
                    )
                }
            </div>
            <div className="offers-title"></div>
            <div className="offers-list"></div>
        </div>
    );
};
