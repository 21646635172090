import React from "react";

import './comment.css';

interface ICommentProps {
  text: string;
}

export const Comment: React.FC<ICommentProps> = ({ text }) => {
  return (
    <div className="comment">
      {text}
    </div>
  )
}
