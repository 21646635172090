import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IAircraft} from "../../aircraft/types/aircraft.types";

export const addInfoToOfferHttp = async (
    offerId: number,
    price: number,
    aircraft: IAircraft | null,
    comment: string,
    files: Record<number, FileList>
) => {
    const preparedFiles: Record<number, any> = {};

    for (const offerId in files) {
        const filePromises = [];

        for (const file of files[offerId]) {
            filePromises.push(new Promise((res, rej) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);

                fileReader.onload = () => {
                    res({
                        filename: file.name,
                        body: (fileReader.result as string).split(",")[1],
                    });
                };
                fileReader.onerror = rej;
            }));
        }

        preparedFiles[offerId] = await Promise.all(filePromises);
    }

    const body = {
            price: price,
            aircraft_id: aircraft ? aircraft.id : null,
            comment: comment,
            files: preparedFiles[offerId] ? preparedFiles[offerId] : [],
        };


    return httpRequest(`offer/${offerId}/leg-info`, {
        method: "PUT",
        body: JSON.stringify(body),
    });
};
