import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IAirline} from "../../airline/types/airline.types";
import {IApplicationFormLeg} from "../store/application-form.store";
import { dateToISOString } from '../../../shared/utils/dateUtils';
import {IFoodFormLeg} from "../../food/store/food-form.store";

export const createApplicationHttp = (
    legs: IApplicationFormLeg[],
    need_loader: boolean,
    need_manager: boolean,
    transfer: boolean,
    post_payment: boolean,
    food_order: boolean,
    food_legs: IFoodFormLeg[],
    comment: string,
    airlines: IAirline[],
    flight: string,
    flight_type: string,
    passing_flight: number,
): Promise<Response> => {
    return httpRequest(`application`, {
        method: "POST",
        body: JSON.stringify({
            legs: legs.map((leg) => ({
                ...leg,
                departure_datetime: leg.departure_datetime ? dateToISOString(leg.departure_datetime) : null,
                id: undefined,
            })),
            need_loader,
            need_manager,
            transfer,
            post_payment,
            food_order,
            food_legs: food_legs.map((food_leg) => ({
                ...food_leg,
                departure_datetime: food_leg.departure_datetime ? dateToISOString(food_leg.departure_datetime) : null,
                id: undefined,
            })),
            comment,
            airlines,
            flight,
            flight_type,
            passing_flight
        }),
    });
};
