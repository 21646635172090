import {createEffect, createEvent, createStore, forward} from "effector";
import {IFoodApp} from "../types/food.types";
import {getFoodAppHttp, TGetFoodAppResponse} from "../http/get-food-app.http";

export const getFoodApp = createEvent<number>();

export const getFoodAppFx = createEffect<
    number,
    TGetFoodAppResponse
>((id: number) => {
    return getFoodAppHttp(id);
});

export const $foodApp = createStore<IFoodApp | null>(null)
    .on(getFoodAppFx.doneData, (_, payload) => {
        return {
            ...payload,
        };
    })
    .on(getFoodAppFx.fail, () => null)

forward({
    from: getFoodApp,
    to: getFoodAppFx,
});

