import React, {FC} from "react";
import {IFullAircraft} from "../../../types/full-aircraft.types";
import "./aircraft-list-item.css";
import {Carousel} from "react-bootstrap";

interface IProps {
    aircraft: IFullAircraft;
}

export const AircraftListItem: FC<IProps> = ({
    aircraft,
}) => {
    return (
        <div className="aircraft-list-item">
            <div className="aircraft-list-item-content">
                <Carousel>
                    {
                        aircraft.images.map((image) => (
                            <Carousel.Item>
                                <img className="aircraft-list-item-image" src={`${process.env.GLOBUS_API_DOMAIN}/${image}`} alt={aircraft.type} />
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
                <p className="aircraft-list-item-header">
                    {aircraft.type}
                </p>
                <div className="aircraft-list-item-properties">
                    <div className="aircraft-list-item-main-properties">
                        <div className="aircraft-list-item-main-properties-item">
                            <p className="aircraft-list-item-main-properties-item-title">
                                АК
                            </p>
                            <p className="aircraft-list-item-main-properties-item-value">
                                {aircraft.airline}
                            </p>
                        </div>
                        <div className="aircraft-list-item-main-properties-item">
                            <p className="aircraft-list-item-main-properties-item-title">
                                НОМЕР БОРТА
                            </p>
                            <p className="aircraft-list-item-main-properties-item-value">
                                {aircraft.number}
                            </p>
                        </div>
                        <div className="aircraft-list-item-main-properties-item">
                            <p className="aircraft-list-item-main-properties-item-title">
                                ГОД ВЫПУСКА
                            </p>
                            <p className="aircraft-list-item-main-properties-item-value">
                                {aircraft.release_year}
                            </p>
                        </div>
                        <div className="aircraft-list-item-main-properties-item">
                            <p className="aircraft-list-item-main-properties-item-title">
                                ДАЛЬНОСТЬ
                            </p>
                            <p className="aircraft-list-item-main-properties-item-value">
                                {aircraft.max_distance}
                            </p>
                        </div>
                        <div className="aircraft-list-item-main-properties-item">
                            <p className="aircraft-list-item-main-properties-item-title">
                                КР. СКОРОСТЬ
                            </p>
                            <p className="aircraft-list-item-main-properties-item-value">
                                {aircraft.speed}
                            </p>
                        </div>
                        <div className="aircraft-list-item-main-properties-item">
                            <p className="aircraft-list-item-main-properties-item-title">
                                КОЛИЧЕСТВО МЕСТ
                            </p>
                            <p className="aircraft-list-item-main-properties-item-value">
                                {aircraft.max_pax}
                            </p>
                        </div>
                    </div>
                    <div className="aircraft-list-item-extra-properties">
                        <div className="aircraft-list-item-extra-properties-block">
                            <p className="aircraft-list-item-extra-properties-block-header">
                                РАЗМЕРЫ САЛОНА
                            </p>
                            <div className="aircraft-list-item-extra-properties-block-item">
                                <div className="aircraft-list-item-extra-properties-block-item-title">
                                    ВЫСОТА
                                </div>
                                <div className="aircraft-list-item-extra-properties-block-item-value">
                                    {aircraft.salon_height}
                                </div>
                            </div>
                            <div className="aircraft-list-item-extra-properties-block-item">
                                <div className="aircraft-list-item-extra-properties-block-item-title">
                                    ШИРИНА
                                </div>
                                <div className="aircraft-list-item-extra-properties-block-item-value">
                                    {aircraft.salon_width}
                                </div>
                            </div>
                            <div className="aircraft-list-item-extra-properties-block-item">
                                <div className="aircraft-list-item-extra-properties-block-item-title">
                                    ДЛИНА
                                </div>
                                <div className="aircraft-list-item-extra-properties-block-item-value">
                                    {aircraft.salon_length}
                                </div>
                            </div>
                        </div>
                        <div className="aircraft-list-item-extra-properties-block">
                            <p className="aircraft-list-item-extra-properties-block-header">
                                РАЗМЕРЫ ВС
                            </p>
                            <div className="aircraft-list-item-extra-properties-block-item">
                                <div className="aircraft-list-item-extra-properties-block-item-title">
                                    ДЛИНА
                                </div>
                                <div className="aircraft-list-item-extra-properties-block-item-value">
                                    {aircraft.length}
                                </div>
                            </div>
                            <div className="aircraft-list-item-extra-properties-block-item">
                                <div className="aircraft-list-item-extra-properties-block-item-title">
                                    ВЫСОТА
                                </div>
                                <div className="aircraft-list-item-extra-properties-block-item-value">
                                    {aircraft.height}
                                </div>
                            </div>
                            <div className="aircraft-list-item-extra-properties-block-item">
                                <div className="aircraft-list-item-extra-properties-block-item-title">
                                    КРЫЛЬЯ
                                </div>
                                <div className="aircraft-list-item-extra-properties-block-item-value">
                                    {aircraft.wingspan}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aircraft-list-item-description">
                    {
                        aircraft
                            .description
                            .replace(/\r\n/g, "\n")
                            .split("\n")
                            .map((paragraph) => (
                                <p>{paragraph}</p>
                            ))
                    }
                </div>
            </div>
        </div>
    );
};
