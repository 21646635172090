import React, {FC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useStore} from "effector-react";
import {$authorizedUser} from "../../../../domains/user/store/authorized-user.store";
import {EUserType} from "../../../../domains/user/types/user.types";
import {ApplicationList} from "../../../../domains/application/views/application-list/application-list";
import {OfferList} from "../../../../domains/offer/views/offer-list/offer-list";

import {Application} from "../../../../domains/application/views/application/application";
import {Offer} from "../../../../domains/offer/views/offer/offer";
import {ApplicationForm} from "../../../../domains/application/views/application-form/application-form";

export const Applications: FC = () => {
    const user = useStore($authorizedUser);

    if (user === null) {
        return null;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    user.type === EUserType.TEAM
                        ? (
                            <ApplicationList />
                        )
                        : (
                            <OfferList />
                        )
                }
            />
            <Route
                path="/form"
                element={
                    user.type === EUserType.TEAM
                        ? (
                            <ApplicationForm />
                        )
                        : (
                            <Navigate to="/dashboard/applications" />
                        )
                }
            />
            <Route
                path="/:id"
                element={
                    user.type === EUserType.TEAM
                    ? (
                        <Application />
                    )
                    : (
                        <Offer />
                    )
                }
            />
            <Route
                path="/:id/airline/:airlineId"
                element={
                    user.type === EUserType.TEAM
                        && (
                            <Application />
                    )
                }
            />
        </Routes>
    );
};
