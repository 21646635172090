import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IApplicationFormLeg} from "../store/application-form.store";
import { dateToISOString } from '../../../shared/utils/dateUtils';

export const createApplicationNonAuthHttp = (
    name: string,
    phone: string,
    email: string,
    legs: IApplicationFormLeg[],
    need_loader: boolean,
    need_food: boolean,
    need_manager: boolean,
    transfer: boolean,
    post_payment: boolean,
    comment: string,
    flight: string,
): Promise<Response> => {
    return httpRequest(`application-non-auth`, {
        method: "POST",
        body: JSON.stringify({
            legs: legs.map((leg) => ({
                ...leg,
                departure_datetime: leg.departure_datetime ? dateToISOString(leg.departure_datetime) : null,
                id: undefined,
            })),
            name,
            phone,
            email,
            need_loader,
            need_food,
            need_manager,
            transfer,
            post_payment,
            comment,
            flight,
        }),
    });
};
