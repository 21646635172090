import React, {FC, useCallback, useState} from "react";
import {Input} from "../../../../components/input/input";
import {Button} from "react-bootstrap";
import {Text} from "../../../../components/text";
import {useStore} from "effector-react";
import {
    $userMailValid,
    $userMail, resetUserRecoverPasswordForm,
    setUserMail, getResetPasswordFx
} from "../../store/user-recover-password-store";
import {useNavigate} from "react-router-dom";


export const UserRecoverPasswordForm: FC = () => {

    const [isFormTouched, setIsFormTouched] = useState(false);

    const userMail = useStore($userMail);
    const isUserMailValid = useStore($userMailValid);
    const navigate = useNavigate();

    const changeUserMail = useCallback((event: any) => {
        setUserMail(event.target.value);
    }, []);

    const toResetPassword = useCallback(() => {
        setIsFormTouched(true)
        getResetPasswordFx(userMail);
        resetUserRecoverPasswordForm();

        if(isUserMailValid) {
            navigate("/dashboard");
        }
    }, [isUserMailValid, navigate]);

    return <div>
        <Text
            type="title"
            className="user-sign-in-form-title"
        >
            Сброс пароля
        </Text>
        <Text type="text" className="user-reset-pas-form-label">Email</Text>
        <Input
            value={userMail}
            onChange={changeUserMail}
        ></Input>
        {
            isFormTouched && !isUserMailValid && (
                <Text type="text" className="user-reset-pas-form-validation">
                    Неверный формат email
                </Text>
            )
        }
        <Button className="user-reset-pas-form-rec-button"
                onClick={toResetPassword}
        >Сбросить пароль</Button>
    </div>
}
