import {createEffect, createEvent, createStore, forward} from "effector";
import {IPassingFlight} from "../types/passing-flight.types";
import {
    getPassingFlightAkHttp,
    TGetPassingFlightsAkResponse
} from "../http/get-passing-flights-ak.http";

export const getPassingFlightsAk = createEvent<void>();

export const getPassingFlightsAkFx = createEffect<
    void,
    TGetPassingFlightsAkResponse
>(() => {
    return getPassingFlightAkHttp();
});

export const $passingFlightsAk = createStore<IPassingFlight[] | null>(null)
    .on(getPassingFlightsAkFx.doneData, (_, payload) => {
        const sortedPayload = [...payload];
        sortedPayload.sort((a1, a2) => {
            if (a1.id < a2.id) {
                return 1;
            }

            return -1;
        });

        return sortedPayload;
    })
    .on(getPassingFlightsAkFx.fail, () => null);



// export const $filteredPassingFlightsAk = $passingFlightsAk.map((passingFlightsAk) => {
//     if (passingFlightsAk) {
//         return passingFlightsAk.filter((item) => {
//             const legDepartureDate = new Date(item.departure_datetime).getTime();
//             const differenceInSeconds = (legDepartureDate - Date.now()) / 1000;
//
//             return differenceInSeconds > 2 * 3600;
//         });
//     }
//
//     return null;
// })



forward({
    from: getPassingFlightsAk,
    to: getPassingFlightsAkFx,
});


