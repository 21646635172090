import {combine, createEffect, createEvent, createStore, restore, sample} from "effector";
import {IAirline} from "../../airline/types/airline.types";
import {createApplicationHttp} from "../http/create-application.http";
import {attachFileToApplicationHttp} from "../http/attach-file-to-application.http";
import {$currentAirlines} from "../../airline/store/airlines.store";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";
import {$foodFormLegs} from "../../food/store/food-form.store";

export interface IApplicationFormLeg {
    id: number;
    departure_airport: number | null;
    arrival_airport: number | null;
    departure_datetime: Date | null;
    departure_back_datetime: Date | null;
    regular_number: string;
    pax: number;
    business_pax: number;
    baggage: string;
    baggage_number: number;
}

export const addApplicationFormLeg = createEvent<void>();
export const changeApplicationFormLeg = createEvent<IApplicationFormLeg>();
export const setApplicationFormIsNeedLoader = createEvent<boolean>();
export const setApplicationFormIsNeedManager = createEvent<boolean>();
export const setApplicationFormIsNeedTransfer = createEvent<boolean>();
export const setApplicationFormIsNeedPostPayment = createEvent<boolean>();
export const setApplicationFormIsNeedFoodOrder = createEvent<boolean>();
export const setApplicationFormComment = createEvent<string>();
export const setApplicationPassingFlightId = createEvent<number>();
export const setApplicationFormFlight = createEvent<string>();
export const setApplicationFormFlightType = createEvent<string>();
export const setApplicationFormFiles = createEvent<FileList>();
export const changeApplicationFormAirlines = createEvent<IAirline>();
export const changeApplicationFormRegularAirlines = createEvent<IAirline>();
export const resetApplicationFormAirlines = createEvent<void>();
export const fillApplicationFormAirlines = createEvent<void>();
export const saveApplicationForm = createEvent<() => void>();
export const resetApplicationForm = createEvent<void>();

export const saveApplicationFormFx = createEffect(async ({
    legs,
    isNeedLoader,
    isNeedManager,
    isNeedTransfer,
    isNeedPostPayment,
    isNeedFoodOrder,
    foodLegs,
    comment,
    airlines,
    files,
    flight,
    flightType,
    passingFlight,
    callback,
}: any) => {

    const createApplicationResponse = await createApplicationHttp(
        legs,
        isNeedLoader,
        isNeedManager,
        isNeedTransfer,
        isNeedPostPayment,
        isNeedFoodOrder,
        foodLegs,
        comment,
        airlines,
        flight,
        flightType,
        passingFlight,
    );

    const applicationId = await createApplicationResponse.text();

    if (files) {
        for (const file of Array.from(files as FileList)) {
            attachFileToApplicationHttp(applicationId, file);
        }
    }

    callback();
});

export const $applicationFormLastLegId = createStore(0)
    .on(addApplicationFormLeg, (store) => store + 1)
    .reset(resetApplicationForm);

export const $applicationFormLegs = createStore<IApplicationFormLeg[]>([{
    id: 0,
    departure_airport: null,
    arrival_airport: null,
    regular_number: "",
    departure_datetime: null,
    departure_back_datetime: null,
    pax: 0,
    business_pax: 0,
    baggage: "",
    baggage_number: 0,
}])
    .on($applicationFormLastLegId, (store, payload) => {

        return [
            ...store,
            {
                id: payload,
                departure_airport: null,
                arrival_airport: null,
                regular_number: "",
                departure_datetime: null,
                departure_back_datetime: null,
                pax: 0,
                business_pax: 0,
                baggage: "",
                baggage_number: 0,
            },
        ];
    })
    .on(changeApplicationFormLeg, (store, payload) => {
        return store.map((currentLeg) => {
            if (currentLeg.id === payload.id) {
                return payload;
            }

            return currentLeg;
        })
    })
    .reset(resetApplicationForm);

export const $applicationFormIsNeedLoader = restore<boolean>(
    setApplicationFormIsNeedLoader,
    false,
)
    .reset(resetApplicationForm);

export const $applicationFormIsNeedManager = restore<boolean>(
    setApplicationFormIsNeedManager,
    false,
)
    .reset(resetApplicationForm);

export const $applicationFormIsNeedTransfer = restore<boolean>(
    setApplicationFormIsNeedTransfer,
    false,
)
    .reset(resetApplicationForm);

export const $applicationFormIsNeedPostPayment = restore<boolean>(
    setApplicationFormIsNeedPostPayment,
    false,
)
    .reset(resetApplicationForm);

export const $applicationFormIsNeedFoodOrder = restore<boolean>(
    setApplicationFormIsNeedFoodOrder,
    false,
)
    .reset(resetApplicationForm);

export const $applicationFormComment = restore<string>(
    setApplicationFormComment,
    "",
)
    .reset(resetApplicationForm);

export const $passingFlight = restore<number>(
    setApplicationPassingFlightId,
    null
)
    .reset(resetApplicationForm);

export const $applicationFormFlight = restore<string>(
    setApplicationFormFlight,
    "ow"
)
    .reset(resetApplicationForm);

export const $applicationFormFlightType = restore<string>(
    setApplicationFormFlightType,
    "regular"
)
    .reset(resetApplicationForm);

export const $applicationFormFiles = restore<FileList>(
    setApplicationFormFiles,
    null,
)
    .reset(resetApplicationForm);


export const $applicationFormAirlines = createStore<IAirline[]>([])
    .on(changeApplicationFormAirlines, (store, payload) => {
        const isExist = store.find((airline) => airline.name === payload.name);

        if (isExist) {
            return store.filter((airline) => airline.name !== payload.name);
        }

        return [
            ...store,
            payload,
        ];
    })
    .on(changeApplicationFormRegularAirlines, (store, payload) => {
        const isExist = store.find((airline) => airline.name === payload.name);

        if (isExist) {
            return store.filter((airline) => airline.name !== payload.name);
        }

        return [
            ...store,
            payload,
        ];
    })
    .on($currentAirlines, (store, payload) => {
        return payload;
    })
    .reset([resetApplicationForm, resetApplicationFormAirlines]);

sample({
    clock: saveApplicationForm,
    source: combine({
        legs: $applicationFormLegs,
        isNeedLoader: $applicationFormIsNeedLoader,
        isNeedManager: $applicationFormIsNeedManager,
        isNeedTransfer: $applicationFormIsNeedTransfer,
        isNeedPostPayment: $applicationFormIsNeedPostPayment,
        isNeedFoodOrder: $applicationFormIsNeedFoodOrder,
        foodLegs: $foodFormLegs,
        comment: $applicationFormComment,
        flight: $applicationFormFlight,
        flightType: $applicationFormFlightType,
        airlines: $applicationFormAirlines,
        passingFlight: $passingFlight,
        files: $applicationFormFiles,
    }),
    target: saveApplicationFormFx,
    fn: (source, callback) => ({
        ...source,
        callback,
    }),
});

sample({
    clock: [fillApplicationFormAirlines, $currentAirlines],
    source: $currentAirlines,
    target: $applicationFormAirlines,
    filter: (source): source is IAirline[] => source !== null,
});

saveApplicationFormFx.fail.watch(() => {
    showNotice("danger", "Во время создания заявки произошла ошибка. Повторите операцию позже.");
});
