import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IUser} from "../types/user.types";

export type TGetAuthorizedUserHttpResponse = IUser;

export const getAuthorizedUserHttp = async (): Promise<TGetAuthorizedUserHttpResponse> => {
    const authToken = localStorage.getItem("authToken");
    if(!authToken) throw new Error ("No auth data");

    const getAuthorizedUserResponse = await httpRequest("auth/user");

    return getAuthorizedUserResponse.json();
};
