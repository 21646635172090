import React, {forwardRef} from "react";
import DatePicker from 'react-datepicker';
import {FormLabel} from "react-bootstrap";
import '../application-form.css';
import "react-datepicker/dist/react-datepicker.css";
import {useStore} from "effector-react";
import {Input} from "../../../../../components/input/input";
import {TextArea} from "../../../../../components/textArea";
import {
    addApplicationFormNonAuthLeg, $applicationFormNonAuthFlight,
    changeApplicationFormNonAuthLeg,
    IApplicationFormNonAuthLeg, setApplicationFormNonAuthFlight,
} from "../../../store/application-form-non-auth.store";
import {$airports} from "../../../../airport/store/airport.store";
import {dateTimeToDMY} from "../../../../../shared/utils/dateUtils";
import {SearchSelect} from "../../../../../components/search-select/search-select";
import plus2 from "../../../../team/views/game-calendar/plus2.png";

interface IProps {
    leg: IApplicationFormNonAuthLeg;
}

export const ApplicationFormNonAuthLeg: React.FC<IProps> = ({
    leg,
}) => {
    const airports = useStore($airports);
    const flight = useStore($applicationFormNonAuthFlight);

    const DatePickerInput = forwardRef<HTMLInputElement>(({ value, onClick }: any, ref) => {
        return (
            <Input
                variant="solid"
                value={value.length === 0 ? "" : dateTimeToDMY(value)}
                onChange={() => null}
                onClick={onClick}
                inputRef={ref}
            />
        );
    });

    return (
        <div className="addOrderFlight">
            <div className="addOrder-line-offer">
                <div className="addOrder-half addOrder-half_vertical">
                    <FormLabel>Вылет</FormLabel>
                    <SearchSelect
                        name={`${leg.id}-departure_airport`}
                        placeholder="Выберите аэропорт"
                        onChange={(event) => {
                            changeApplicationFormNonAuthLeg({
                                ...leg,
                                departure_airport: parseInt(event, 10),
                            });
                        }}
                    >
                        {
                            airports?.map((airport) => (
                                <option key={airport.id} value={airport.id}>
                                    {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                </option>
                            ))
                        }
                    </SearchSelect>
                </div>
                <div className="addOrder-half addOrder-half_vertical">
                    <FormLabel>Прилет</FormLabel>
                    <SearchSelect
                        name={`${leg.id}-arrival_airport`}
                        placeholder="Выберите аэропорт"
                        onChange={(event) => {
                            changeApplicationFormNonAuthLeg({
                                ...leg,
                                arrival_airport: parseInt(event, 10),
                            });
                        }}
                    >
                        {
                            airports?.map((airport) => (
                                <option key={airport.id} value={airport.id}>
                                    {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                </option>
                            ))
                        }
                    </SearchSelect>
                </div>
                <div className="addOrder-half addOrder-half_vertical">
                    <FormLabel>Дата и время туда</FormLabel>
                    <DatePicker
                        selected={leg.departure_datetime}
                        onChange={(date: Date) => {
                            changeApplicationFormNonAuthLeg({
                                ...leg,
                                departure_datetime: date,
                            });
                        }}
                        customInput={<DatePickerInput />}
                        minDate={new Date()}
                        timeFormat="HH:mm"
                        dateFormat="yyyy-MM-dd HH:mm"
                        timeIntervals={10}
                        showTimeSelect
                    />
                </div>
                {
                    flight === "rt" && (
                        <div className="addOrder-half addOrder-half_vertical">
                            <FormLabel>Дата и время обратно</FormLabel>
                            <DatePicker
                                selected={leg.departure_back_datetime}
                                onChange={(date: Date) => {
                                    changeApplicationFormNonAuthLeg({
                                        ...leg,
                                        departure_back_datetime: date,
                                    });
                                }}
                                customInput={<DatePickerInput />}
                                minDate={new Date()}
                                timeFormat="HH:mm"
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeIntervals={10}
                                showTimeSelect
                            />
                        </div>)
                }
                <div className="add-flight-btn">
                    <button
                        className="btn-add-game"
                        onClick={() => {
                            setApplicationFormNonAuthFlight("cf");
                            addApplicationFormNonAuthLeg();
                        }}
                    ><img className="add-icon-image" src={plus2} alt="add button"/></button>
                </div>
            </div>
            <div className="addOrder-line-offer addOrder-line-pax">
                    <div className="addOrder-item">
                        <FormLabel>Пассажиров</FormLabel>
                        <Input
                            variant="solid"
                            type="text"
                            value={leg.pax === 0 ? "" : leg.pax.toString()}
                            onChange={(event: any) => {
                                let preparedValue = event.target.value.replace(/\D/g, "");
                                preparedValue = preparedValue === "" ? "0" : preparedValue;

                                changeApplicationFormNonAuthLeg({
                                    ...leg,
                                    pax: parseInt(preparedValue, 10),
                                })
                            }}
                        />
                    </div>
                    <div className="addOrder-item">
                        <FormLabel>Из них бизнес-классом</FormLabel>
                        <Input
                            variant="solid"
                            type="text"
                            value={leg.business_pax === 0 ? "" : leg.business_pax.toString()}
                            onChange={(event: any) => {
                                let preparedValue = event.target.value.replace(/\D/g, "");
                                preparedValue = preparedValue === "" ? "0" : preparedValue;

                                changeApplicationFormNonAuthLeg({
                                    ...leg,
                                    business_pax: parseInt(preparedValue, 10),
                                })
                            }}
                        />
                    </div>
                <div className="addOrder-quarter">
                    <FormLabel>Мест багажа</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        value={leg.baggage_number === 0 ? "" : leg.baggage_number.toString()}
                        onChange={(event: any) => {
                            let preparedValue = event.target.value.replace(/\D/g, "");
                            preparedValue = preparedValue === "" ? "0" : preparedValue;

                            changeApplicationFormNonAuthLeg({
                                ...leg,
                                baggage_number: parseInt(preparedValue, 10),
                            })
                        }}
                    />
                </div>
                <div className="addOrder-big">
                    <FormLabel>Негабаритный багаж</FormLabel>
                    <TextArea
                        className="addOrder-textArea"
                        value={leg.baggage}
                        onChange={(event) => {
                            changeApplicationFormNonAuthLeg({
                                ...leg,
                                baggage: event.target.value,
                            });
                        }}
                        rows={1}
                        placeholder="Укажите размеры/вес негабаритного груза (клюшки, штанги, лыжи, шесты и т.д.)"
                    />
                </div>
            </div>
        </div>
    )
}
