import React from "react";
import { Text } from "../../text";

import './point.css';

interface IPointProps {
    title?: string;
    airport: string;
    data?: string;

}

export const Point: React.FC<IPointProps> = ({ title, airport, data }) => {
    return (
        <div className="point">
            <Text className="point-title" type="caption">{title}</Text>
            <div className="point-airport">{airport}</div>
            <div className="point-data">{data}</div>
        </div>
    );
}