import {httpRequest} from "../../../shared/http/http-request/http-request";
import {EUserType} from "../types/user.types";

export const registerUserHttp = (
    username: string,
    password: string,
    telegram: string,
    type: EUserType,
    organization_name: string,
    contact_name: string,
    phone: string,
    charter_email: string,
    group_email: string
): Promise<Response> => {
    return httpRequest({
        path: "auth/register",
        query: {
            redirect_uri: "/",
        },
    }, {
        method: "POST",
        body: JSON.stringify({
            username,
            password,
            telegram,
            organization_name,
            contact_name,
            phone,
            charter_email,
            group_email,
            type,
        }),
    });
};
