import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {Text} from "../text";
import {Button} from "../button";
import icon from './upload/icon.svg';

import './filesInput.css';

interface IFilesInputProps {
    onChange: (files: FileList) => void;
}

export const FilesInput: React.FC<IFilesInputProps> = ({ onChange }) => {
    const [dragging, setDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const [files, setFiles] = useState<File[]>([]);

    const dropRef = React.createRef<HTMLDivElement>();

    const handleDrag = useCallback((e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragIn = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setDragCounter(dragCounter + 1);
            if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                setDragging(true);
            }
        },
        [dragCounter, setDragging]
    );

    const handleDragOut = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setDragCounter(dragCounter - 1);
            if (dragCounter === 0) {
                setDragging(false);
            }
        },
        [dragCounter, setDragging]
    );

    const handleUploadFiles = useCallback(
        (uploadedFiles: FileList) => {
            const newFiles = [...files];
            for (let i = 0, file; (file = uploadedFiles[i++]); ) {
                const reader = new FileReader();
                newFiles.push(file);
                reader.readAsDataURL(file);
            }

            const dt = new DataTransfer();
            newFiles.forEach(file => {
                dt.items.add(file);
            });
            onChange(dt.files);
            setFiles(newFiles);
        },
        [files]
    );

    const handleDrop = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                handleUploadFiles(e.dataTransfer.files);
            }
        },
        [handleUploadFiles]
    );

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.files && e.target.files.length > 0) {
                handleUploadFiles(e.target.files);
            }
        },
        [handleUploadFiles]
    );

    const handleRemove = useCallback(
        (index: number) => () => {
            const newFiles = [...files];
            newFiles.splice(index, 1);

            const dt = new DataTransfer();
            newFiles.forEach(file => {
                dt.items.add(file);
            });
            onChange(dt.files);
            setFiles(newFiles);
        },
        [files]
    );

    useEffect(() => {
        const div = dropRef.current;

        if (div) {
            div.addEventListener('dragenter', handleDragIn);
            div.addEventListener('dragleave', handleDragOut);
            div.addEventListener('dragover', handleDrag);
            div.addEventListener('drop', handleDrop);
        }

        return () => {
            if (div) {
                div.removeEventListener('dragenter', handleDragIn);
                div.removeEventListener('dragleave', handleDragOut);
                div.removeEventListener('dragover', handleDrag);
                div.removeEventListener('drop', handleDrop);
            }
        };
    }, [dropRef, handleDrag, handleDragIn, handleDragOut, handleDrop]);

    const randomInputId = useMemo(
        () =>
            Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '')
                .substr(0, 5),
        []
    );

    return (
        <>
            <div className={`filesInput${dragging ? ' filesInput_dragging' : ''}${files.length === 0 ? ' filesInput_empty' : ''}`} ref={dropRef}>
                <label htmlFor={randomInputId} className="filesInput-panel">
                    <div>
                        <img src={icon} alt="upload" />
                    </div>
                    <div>Перетащите файл или нажмите сюда, чтобы его добавить</div>
                    <input
                        className="filesInput-input"
                        tabIndex={-1}
                        type="file"
                        id={randomInputId}
                        onChange={handleChange}
                        multiple
                    />
                </label>
                {files.length > 0 && (
                    <div className="filesInput-filelist">
                        {files.map((file, index) => (
                            <div key={file.name} className="filesInput-file">
                                <Text type="text">{file.name}</Text>
                                <Button variant="link" onClick={handleRemove(index)}>Удалить</Button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};
