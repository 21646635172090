import React, {memo} from "react";
import {Text} from "../../../../../components/text";
import {Checkbox} from "../../../../../components/checkbox/checkbox";

import './application-form-airlines.css';
import {useStore} from "effector-react";
import {$airlines} from "../../../../airline/store/airlines.store";
import {
    $applicationFormAirlines,
    changeApplicationFormAirlines,
} from "../../../store/application-form.store";

const ApplicationFormAirlines: React.FC = memo((leg) => {
    const airlines = useStore($airlines);
    const applicationFormAirlines = useStore($applicationFormAirlines);

    return (
        <div>
            <div className="addOrderCompanies-top">
                <div>
                    <Text type="subtitle" className="addOrderCompanies-title">Авиакомпании</Text>
                    <Text type="text">Кому отправить заявку</Text>
                </div>
            </div>
            <div className="addOrderCompanies">
                {airlines?.map((airline) => (
                    <div key={airline.name} className="addOrderCompanies-item">
                        <Checkbox
                            checked={!!applicationFormAirlines.find(one => one.id === airline.id)}
                            onChange={() => {
                                changeApplicationFormAirlines(airline);
                            }}
                        >
                            <Text type="text">{airline.name}</Text>
                        </Checkbox>
                    </div>
                ))}
            </div>
        </div>
    )
});

export default ApplicationFormAirlines;
