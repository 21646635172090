import {httpRequest} from "../../../shared/http/http-request/http-request";
import {ISeatBlock} from "../types/seat-blocks.types";

export type TGetSeatBlocksResponse = ISeatBlock[];

export const getSeatBlocksHttp = async (): Promise<TGetSeatBlocksResponse> => {
    const getEmptyLegsResponse = await httpRequest("blocks");

    return getEmptyLegsResponse.json();
};
