import { createEffect, createEvent, createStore, forward } from "effector";
import { getAirlinesHttp, TGetAirlinesHttpResponse } from "../http/get-airlines.http";
import { IAirline } from "../types/airline.types";
import {FlightTypes} from '../../application/types/application.types';
import cloneDeep from 'lodash/cloneDeep';

export const getAirlines = createEvent<void>();
export const changeFlightType = createEvent<FlightTypes>();

export const getAirlinesFx = createEffect<
    void,
    TGetAirlinesHttpResponse
>(() => {
    return getAirlinesHttp();
});

export const $airlines = createStore<IAirline[] | null>(null)
    .on(getAirlinesFx.doneData, (_, payload) => payload)
    .on(getAirlinesFx.fail, () => null);

export const $currentAirlines = createStore<IAirline[]>([])
  .on(getAirlinesFx.doneData, (_, payload) => {
    return payload.length ? [payload[0]] : [];
  })
  .on(changeFlightType, (store, payload) => {
    const defaultAirlines = cloneDeep($airlines.getState() || []);

    if (payload === FlightTypes.REGULAR) {
      return defaultAirlines.length ? [defaultAirlines[0]] : [];
    } else {
      return defaultAirlines
    }
  })

forward({
    from: getAirlines,
    to: getAirlinesFx,
});
