import React, {useCallback, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {Text} from "../../../../components/text";
import {Input} from "../../../../components/input/input";
import {Button} from "react-bootstrap";
import {
    $isNewPasswordConfirmValid,
    $isNewPasswordValid,
    $newPasswordConfirm,
    $userNewPassword, sendRecoverPassword,
    setNewPassword,
    setNewPasswordConfirm,
} from "../../store/user-recover-password-store";
import {useStore} from "effector-react";

export const ConfirmPassword: React.FC = () => {

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const username = searchParams.get('user');

    const [isFormTouched, setIsFormTouched] = useState(false);
    const [isNewPasswordSave, setIsNewPasswordSave] = useState(false);

    const password = useStore($userNewPassword);
    const newPasswordConfirm = useStore($newPasswordConfirm);

    const isNewPasswordValid = useStore($isNewPasswordValid);
    const isNewPasswordConfirmValid = useStore($isNewPasswordConfirmValid);

    const changeNewPassword = useCallback((event: any) => {
        setNewPassword(event.target.value);
    }, []);

    const changeNewPasswordConfirm = useCallback((event: any) => {
        setNewPasswordConfirm(event.target.value);
    }, []);

    const handleRecoverPassword = useCallback(() => {
        setIsFormTouched(true);
            if(!token || !username || !isNewPasswordValid || !isNewPasswordConfirmValid) {
                return
            } else {
                sendRecoverPassword({token, username, password, isNewPasswordValid, isNewPasswordConfirmValid})
            }
            setIsNewPasswordSave(true);
    }, [token, username, password, isNewPasswordValid, isNewPasswordConfirmValid]);

    if(!searchParams.get('token')) return null;

    return <>
        { !isNewPasswordSave ? (
            <div>
                <Text
                    type="title"
                    className="user-sign-in-form-title"
                >
                    Восстановление пароля
                </Text>
                <Text type="text" className="user-reset-pas-form-label">Новый пароль</Text>
                <Input
                    value={password}
                    onChange={changeNewPassword}
                    type="password"
                ></Input>
                {
                    isFormTouched && !isNewPasswordValid && (
                        <Text type="text" className="user-reset-pas-form-validation">
                            Ваш пароль небезопасен. Используйте пароль, в котором:
                            <ul>
                                <li>Длина не менее 8 символов</li>
                                <li>В пароле должны присутствовать строчные и заглавные буквы</li>
                                <li>В пароле должна присутствовать хотя бы одна цифра</li>
                            </ul>
                        </Text>
                    )
                }
                <Text type="text" className="user-reset-pas-form-label">Подтвердите пароль</Text>
                <Input
                    value={newPasswordConfirm}
                    onChange={changeNewPasswordConfirm}
                    type="password" />
                {
                    isFormTouched && !isNewPasswordConfirmValid && (
                        <Text type="text" className="user-reset-pas-form-validation">
                            Пароли не совпадают
                        </Text>
                    )
                }
                <Button className="user-reset-pas-form-rec-button"
                        onClick={handleRecoverPassword}
                >Изменить пароль</Button>
                <div>
                </div>
            </div>) :
            (
                <div>
                    <Text type="text" className="new-password-save">Ваш пароль успешно изменен</Text>
                    <Link to="/">
                        <Button >Перейти на главную страницу</Button>
                    </Link>
                </div>
            )
        }
    </>
}
