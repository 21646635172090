import {TUrl} from "../http.types";
import {getUrlString} from "../get-url-string/get-url-string";

export const httpRequest = async (
    url: TUrl | string,
    requestInit?: RequestInit,
): Promise<Response> => {
    const authTokenType = localStorage.getItem("authTokenType");
    const authToken = localStorage.getItem("authToken");

    const preparedUrl = typeof url === "string"
        ? url
        : getUrlString(url);

    const fetchOptions: RequestInit = {
        ...requestInit,
        headers: {
            ...(authToken ? {"Authorization": `${authTokenType} ${authToken}`} : {}),
            "Content-Type": "application/json",
            ...requestInit?.headers,
        },
    };

    if (requestInit &&
        requestInit.headers &&
        (requestInit.headers as any)["Content-Type"] === "multipart/form-data"
    ) {
        delete (fetchOptions.headers as any)["Content-Type"];
    }

    const response = await fetch(`${process.env.GLOBUS_API_DOMAIN}/${preparedUrl}`, fetchOptions);

    if (response.ok) {
        return response;
    }

    if (response.redirected) {
        return response;
    }

    const responseBody = await response.text();
    throw new Error(responseBody);
};
