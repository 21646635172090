import React, {FC, useEffect, useMemo} from "react";
import {useStore} from "effector-react";
import {Button, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {OfferListItem} from "./offer-list-item/offer-list-item";
import "./offer-list.css";
import {$offers, getOffers} from "../../store/offers.store";

export const OfferList: FC = () => {
    useEffect(() => {
        getOffers();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getOffers();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const offers = useStore($offers);

    const currentDate = useMemo(() => {
        return dayjs().format("DD.MM.YYYY")
    }, []);

    if (offers === null) {
        return null;
    }

    return (
        <div className="row">
            <Stack direction="horizontal" className="content-header">
                <div className="title">
                    <div className="title-text">Актуальные заявки</div>
                    <div className="title-date">На {currentDate}</div>
                </div>
                {/*<Stack direction="horizontal" className="controls" gap={3}>*/}
                {/*    <Dropdown>*/}
                {/*        <Dropdown.Toggle id="dropdown-basic" className="btn bg-body btn-color-gray-700">*/}
                {/*            Все заявки*/}
                {/*        </Dropdown.Toggle>*/}

                {/*        <Dropdown.Menu>*/}
                {/*            <Dropdown.Item>Не все заявки</Dropdown.Item>*/}
                {/*            <Dropdown.Item>Не только лишь все заявки</Dropdown.Item>*/}
                {/*        </Dropdown.Menu>*/}
                {/*    </Dropdown>*/}

                {/*    <Link to={"/dashboard/applications/form"}>*/}
                {/*        <Button*/}
                {/*            className="button-desktop-visible"*/}
                {/*            variant="primary"*/}
                {/*        >*/}
                {/*            Новая заявка на полет*/}
                {/*        </Button>*/}
                {/*    </Link>*/}
                {/*</Stack>*/}
            </Stack>

            {
                offers.length === 0
                    ? (
                        <div className="title-text">Заявок нет</div>
                    )
                    : (
                        <div className={`container_type_card`}>
                            <div className="list">
                                {
                                    offers.map((offer) => (
                                        <OfferListItem
                                            key={offer.id}
                                            offer={offer}
                                        />
                                    ))
                                }
                            </div>
                            <Link to={"/dashboard/applications/form"}>
                                <Button
                                    className="button-add button-mobile-visible"
                                    variant="primary"
                                >
                                    Новая заявка на полет
                                </Button>
                            </Link>
                        </div>
                    )
            }
        </div>
    );
};
