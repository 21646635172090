import {httpRequest} from "../../../shared/http/http-request/http-request";

export const sendSupportFormHttp = async (
    params: {
        "name": string,
        "phone": string,
        "email": string,
        "flight_no": string,
        "departure_datetime": string,
        "notes": string,
        "departure_airport": number,
        "fast_track_dep": boolean,
        "porter_dep": boolean,
        "flight_manager_dep": boolean,
        "arrival_airport": number,
        "fast_track_arr": boolean,
        "porter_arr": boolean,
        "flight_manager_arr": boolean
    },
    files: FileList,
) => {
    const response = await httpRequest(`support`, {
        method: "POST",
        body: JSON.stringify(params),
    });

    const id = await response.text();

    if (files) {
        for (const file of files) {
            const formData = new FormData();
            formData.append("document", file);

            await httpRequest(`support/${id}/file`, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                body: formData,
            });
        }
    }

    await httpRequest(`support/${id}/notify`, {
        method: "POST",
    });
};
