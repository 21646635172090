import {FC, PropsWithChildren, useEffect} from "react";

interface IProps {
    name: string;
    placeholder: string;
    onChange(value: string): void;
}

export const SearchSelect: FC<PropsWithChildren<IProps>> = ({
    name,
    placeholder,
    onChange,
    children,
}) => {
    useEffect(() => {
        ($(".form-select2-jq") as any)
            .select2();
    }, []);

    useEffect(() => {
        $(".form-select2-jq").on("select2:select", (event: any) => {
            if (name === event.target.name) {
                onChange(event.params.data.id);
            }
        });
    }, [name, onChange]);

    return (
        <select
            name={name}
            className="form-select form-select2-jq"
            data-control="select2"
            data-placeholder={placeholder}
        >
            <option />
            {children}
        </select>
    );
};
