import {FC, useCallback, useState} from "react";
import "./user-sign-form.css";
import {UserSignInForm} from "./user-sign-in-form/user-sign-in-form";
import {UserSignUpForm} from "./user-sign-up-form/user-sign-up-form";
import crossIcon from "./cross.svg";
import {resetUserAuthorizationForm} from "../../store/user-authorization.store";
import {resetUserRegistrationForm} from "../../store/user-registration.store";
import {useSearchParams} from "react-router-dom";
import {UserRecoverPasswordForm} from "../user-recover-password-form/user-recover-password-form";

interface IProps {
    isVisible: boolean;
    hideSignForm(): void;
}

export const UserSignForm: FC<IProps> = ({
    isVisible,
    hideSignForm,
}) => {
    const [isAuth, setIsAuth] = useState(true);

    const [_, setSearchParams] = useSearchParams();

    const [isPasswordReset, setIsPasswordReset] = useState(false);

    const toAuthorization = useCallback(() => {
        setIsAuth(true);
    }, []);

    const toRegistration = useCallback(() => {
        setIsAuth(false);
    }, []);

    const closeForm = useCallback(() => {
        resetUserAuthorizationForm();
        resetUserRegistrationForm();
        setIsAuth(true);
        setSearchParams({});
        hideSignForm();
    }, [hideSignForm]);

    return (
        <>
            <div onClick={closeForm} className={`user-sign-form-overlay ${isVisible && "user-sign-form-overlay-visible"}`} />
            <div className={`user-sign-form ${isVisible && "user-sign-form-visible"}`}>
                <img
                    className="user-sign-form-close"
                    src={crossIcon}
                    onClick={closeForm}
                />
                {
                    isPasswordReset ? (
                            <UserRecoverPasswordForm />
                        ) : isAuth && isVisible
                        ? (
                            <UserSignInForm toRegistration={toRegistration} onResetClick={() => setIsPasswordReset(true)} />
                        )
                        : (
                            <UserSignUpForm toAuthorization={toAuthorization} />
                        )
                }
            </div>
        </>
    );
};
