import {httpRequest} from "../../../shared/http/http-request/http-request";

export const sendMessageHttp = (
    applicationId: number,
    airlineId: number,
    message: string,
): Promise<Response> => {
    return httpRequest(`application/${applicationId}/message`, {
        method: "POST",
        body: JSON.stringify({
            body: message,
            airline_id: airlineId,
        })
    })
}
