import React, {FC, useCallback} from "react";
import dayjs from "dayjs";
import "../../passing-flights-ak/passing-flight-form/passing-flight-form.css";
import {IPassingFlight} from "../../../types/passing-flight.types";
import {FormLabel} from "react-bootstrap";
import {Input} from "../../../../../components/input/input";
import {Button} from "../../../../../components/button";
import {
    changeApplicationFormAirlines,
    changeApplicationFormLeg,
    IApplicationFormLeg,
    saveApplicationForm, setApplicationFormFlightType, setApplicationPassingFlightId,
} from "../../../../application/store/application-form.store";
import {useNavigate} from "react-router-dom";
import {useStore} from "effector-react";
import {$airports} from "../../../../airport/store/airport.store";
import userLogo from "../../../../user/views/user-settings/userLogo.png";

interface IProps {
    passingFlight: IPassingFlight;
    leg: IApplicationFormLeg;
}

export const PassingFlightTeamItem: FC<IProps> = ({
   passingFlight,
   leg
}) => {

    const navigate = useNavigate();
    const airports = useStore($airports);
    const logoAircraft = passingFlight.image ? `/api/${passingFlight.image}` : userLogo;
    const date = new Date(passingFlight.departure_datetime)

    const saveApp = useCallback(() => {
        setApplicationFormFlightType("passing");
        setApplicationPassingFlightId(passingFlight.id);
        changeApplicationFormAirlines(passingFlight.airline[0]);
        saveApplicationForm(() => {
            navigate("/dashboard/applications");
        });
    }, [passingFlight.id, passingFlight.airline, navigate]);

    if(airports === null) {
        return null
    }

    const getDepartureAirport = () => {
        return airports.find((airport) => {
            return airport.id === passingFlight.departure_airport;
        });
    };

    const getArrivalAirport = () => {
        return airports.find((airport) => {
            return airport.id === passingFlight.arrival_airport;
        });
    };

    return (
        <div className="passing-flight-wrapper">
            <div className="passing-form-line">
                <div className="passing-plane">
                    <div className="plane-image">
                        <img src={logoAircraft} alt="aifrcaft logo"/>
                    </div>
                </div>
                <div className="passing-form-departure">
                    <FormLabel>Вылет</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={`${getDepartureAirport()?.city_rus} (${getDepartureAirport()?.name}) ${getDepartureAirport()?.iata}`}
                        onChange={() => {}}
                    ></Input>
                </div>
                <div className="passing-form-departure">
                    <FormLabel>Прилет</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={`${getArrivalAirport()?.city_rus} (${getArrivalAirport()?.name}) ${getArrivalAirport()?.iata}`}
                        onChange={() => {}}
                    ></Input>
                </div>
                <div className="passing-form-date">
                    <FormLabel>Дата и время</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={dayjs(passingFlight.departure_datetime).format("D MMMM в H:mm")}
                        onChange={() => {}}
                    ></Input>
                </div>
                <div className="addOrder-item">
                    <FormLabel>Колличество доступных мест</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={passingFlight.places.toString()}
                        onChange={()=>{}}
                    ></Input>
                </div>
            </div>

            <div className="passing-form-line"
                style={{justifyContent: "center"}}>
                <div className="passing-form-departure"
                    style={{width: "50%"}}>
                <FormLabel>Самолет</FormLabel>
                <Input
                    variant="solid"
                    type="text"
                    disable={true}
                    value={passingFlight.aircraft_type}
                    onChange={()=>{}}
                ></Input>
                </div>
                <div className="passing-form-departure"
                     style={{width: "50%"}}>
                <FormLabel>Цена за билет</FormLabel>
                <Input
                    variant="solid"
                    type="text"
                    disable={true}
                    value={passingFlight.price.toString()}
                    onChange={()=>{}}
                ></Input>
                </div>
            </div>
            <div className="passing-line-team-footer">
                <div className="passing-form-departure">
                    <FormLabel>Количество билетов на бронь</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={leg.pax === 0 ? "" : leg.pax.toString()}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            let preparedValue = event.target.value.replace(/\D/g, "");
                            preparedValue = preparedValue === "" ? "0" : preparedValue;

                            changeApplicationFormLeg({
                                ...leg,
                                pax: parseInt(preparedValue, 10),
                                departure_airport: passingFlight.departure_airport,
                                arrival_airport: passingFlight.arrival_airport,
                                departure_datetime: date,
                            })
                        }}
                    />
                </div>
                <div className="button-save-passing-app">
                    <Button
                        className="addOrder-submit"
                        variant="primary"
                        style={{marginTop: "0", width: "100%"}}
                        onClick={saveApp}>
                        Забронировать билеты
                    </Button>
                </div>
            </div>
            <div className="passing-flight-separator"/>
        </div>
    );
};


