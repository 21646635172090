import {createEffect, createEvent, createStore, forward} from "effector";
import {
    getNewMessagesHttp, TGetNewMessagesResponse,
} from "../http/get-new-messages.http";

export const getNewMessages = createEvent<number>();

export const getNewMessagesFx = createEffect<
    number,
    TGetNewMessagesResponse
>((id: number) => {
    return getNewMessagesHttp(id);
});


export const $newMessages = createStore<number[] | null>(null)
    .on(getNewMessagesFx.doneData, (airlines_id, payload) => {return payload.airlines_id})
    .on(getNewMessagesFx.fail, () => null);

forward({
    from: getNewMessages,
    to: getNewMessagesFx,
})
