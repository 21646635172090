import {combine, createEffect, createEvent, createStore, forward, restore, sample} from "effector";
import {authorizeUserHttp, IAuthorizeUserHttpResponse} from "../http/authorize-user.http";
import {getAuthorizedUser} from "./authorized-user.store";
import {required} from "../../../shared/validations/validations";
import {IUser} from "../types/user.types";

export type TUserAuthorizedCallback = (user: IUser | false) => void;

export const setUserAuthorizationUsername = createEvent<string>();
export const setUserAuthorizationPassword = createEvent<string>();
export const resetUserAuthorizationForm = createEvent<void>();
export const authorizeUser = createEvent<TUserAuthorizedCallback>();

export const authorizeUserFx = createEffect<
    {
        username: string,
        password: string,
        isUsernameValid: boolean,
        isPasswordValid: boolean,
        userAuthorizedCallback: TUserAuthorizedCallback,
    },
    IAuthorizeUserHttpResponse
>(async ({
    username,
    password,
    isUsernameValid,
    isPasswordValid,
    userAuthorizedCallback,
}) => {
    try {
        if (isUsernameValid && isPasswordValid) {
            return await authorizeUserHttp(username, password);
        }
    } catch (e: unknown) {
        userAuthorizedCallback(false);
    }

    throw new Error("validation error");
});

export const $userAuthorizationUsername = restore<string>(
    setUserAuthorizationUsername,
    "",
)
    .reset(resetUserAuthorizationForm);

export const $userAuthorizationIsUsernameValid = createStore(false)
    .on($userAuthorizationUsername, (_, username) => required(username))
    .reset(resetUserAuthorizationForm);

export const $userAuthorizationPassword = restore<string>(
    setUserAuthorizationPassword,
    "",
)
    .reset(resetUserAuthorizationForm);

export const $userAuthorizationIsPasswordValid = createStore(false)
    .on($userAuthorizationPassword, (_, password) => required(password))
    .reset(resetUserAuthorizationForm);

sample({
    clock: authorizeUser,
    source: combine({
        username: $userAuthorizationUsername,
        password: $userAuthorizationPassword,
        isUsernameValid: $userAuthorizationIsUsernameValid,
        isPasswordValid: $userAuthorizationIsPasswordValid,
    }),
    target: authorizeUserFx,
    fn: (store, userAuthorizedCallback) => {
        return {
            ...store,
            userAuthorizedCallback,
        };
    },
});

forward({
    from: authorizeUserFx.done.map(({params}) => params.userAuthorizedCallback),
    to: getAuthorizedUser,
});

authorizeUserFx.doneData.watch(({
    token_type,
    access_token,
}) => {
    localStorage.setItem("authTokenType", token_type);
    localStorage.setItem("authToken", access_token);
});
