import React, {FC, forwardRef, useCallback, useState} from "react";
import "./passing-flight-form.css"
import {Input} from "../../../../../components/input/input";
import {dateTimeToDMY} from "../../../../../shared/utils/dateUtils";
import {Button, Card, FormLabel, FormSelect} from "react-bootstrap";
import {SearchSelect} from "../../../../../components/search-select/search-select";
import DatePicker from "react-datepicker";
import {useStore} from "effector-react";
import {$airports} from "../../../../airport/store/airport.store";
import {$aircrafts} from "../../../../aircraft/store/aircrafts.store";
import {
    $passingArrivalAirport,
    $passingDepartureAirport, $passingFormAircraft,
    $passingFormDate, $passingFormPlaces, $passingFormPrice, passingFlightFormCreate,
    passingFormSetAircraft,
    passingFormSetArrivalAirport,
    passingFormSetDate,
    passingFormSetDepartureAirport,
    passingFormSetPlaces, passingFormSetPrice, resetPassingForm,
} from "../../../store/passing-form-ak.store";
import warningIcon from "../../../../escort/views/escort-form/warning.svg";
import {getPassingFlightsAk} from "../../../store/passing-flights-ak.store";

export const PassingFlightForm: FC = () => {

    const airports = useStore($airports);
    const aircrafts = useStore($aircrafts)
    const departureAirport = useStore($passingDepartureAirport);
    const arrivalAirport = useStore($passingArrivalAirport);
    const date = useStore($passingFormDate);
    const places = useStore($passingFormPlaces);
    const aircraft = useStore($passingFormAircraft);
    const price = useStore($passingFormPrice);


    const [isDepartureAirportValid, setIsDepartureAirportValid] = useState(true);
    const [isArrivalAirportValid, setIsArrivalAirportValid] = useState(true);
    const [isDateValid, setIsDateValid] = useState(true);
    const [isPlacesValid, setIsPlacesValid] = useState(true);
    const [isAircraftValid, setIsAircraftValid] = useState(true);
    const [isPriceValid, setIsPriceValid] = useState(true);

    const DatePickerInput = forwardRef<HTMLInputElement>(({ value, onClick }: any, ref) => {
        return (
            <Input
                variant="solid"
                value={value.length === 0 ? "" : dateTimeToDMY(value)}
                onChange={() => null}
                onClick={onClick}
                inputRef={ref}
            />
        );
    });

    const validateAndCreate = useCallback(() => {
        let isFormValid = true;

        if (
            departureAirport.toString().length === 0
        ) {
            setIsDepartureAirportValid(false);
            isFormValid = false;
        }
        if (
            arrivalAirport.toString().length === 0
        ) {
            setIsArrivalAirportValid(false);
            isFormValid = false;
        }
        if (
            date === null
        ) {
            setIsDateValid(false);
            isFormValid = false;
        }
        if (
            places.length === 0
        ) {
            setIsPlacesValid(false);
            isFormValid = false;
        }
        if (
            aircraft === null
        ) {
            setIsAircraftValid(false);
            isFormValid = false;
        }
        if (
            price.toString().length === 0
        ) {
            setIsPriceValid(false);
            isFormValid = false;
        }

        if (isFormValid) {
            passingFlightFormCreate();
            resetPassingForm();
            const timeout = setTimeout(() =>  getPassingFlightsAk(), 1000);
            return () => clearTimeout(timeout);
        }
    }, [departureAirport, arrivalAirport, date, places, aircraft, price]);

    return <Card>
        <div className="passing-flight-wrapper">
            <div className="passing-form-line">
                <div className="passing-form-line-half">
                    <div className="passing-form-departure">
                        <div>
                        <FormLabel>Вылет</FormLabel>
                        {
                            !isDepartureAirportValid && (
                                <img className="mb-2 passing-form__invalid-field-icon" src={warningIcon} />
                            )
                        }
                        </div>
                        <SearchSelect
                            name="departure_airport"
                            placeholder="Выберите аэропорт"
                            onChange={(event) => {
                                setIsDepartureAirportValid(true);
                                passingFormSetDepartureAirport(event);
                            }}
                        >
                            {
                                airports?.map((airport) => (
                                    <option key={airport.id} value={airport.id}>
                                        {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                    </option>
                                ))
                            }
                        </SearchSelect>
                    </div>
                    <div className="passing-form-departure">
                        <div>
                        <FormLabel>Прилет</FormLabel>
                        {
                            !isArrivalAirportValid && (
                                <img className="mb-2 passing-form__invalid-field-icon" src={warningIcon} />
                            )
                        }
                        </div>
                        <SearchSelect
                            name="arrival_airport"
                            placeholder="Выберите аэропорт"
                            onChange={(event) => {
                                setIsArrivalAirportValid(true);
                                passingFormSetArrivalAirport(event);
                            }}
                        >
                            {
                                airports?.map((airport) => (
                                    <option key={airport.id} value={airport.id}>
                                        {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                    </option>
                                ))
                            }
                        </SearchSelect>
                    </div>
                </div>
                <div className="passing-form-line-half">
                    <div className="passing-form-date">
                        <FormLabel>Дата и время</FormLabel>
                        {
                            !isDateValid && (
                                <img className="mb-2 passing-form__invalid-field-icon" src={warningIcon} />
                            )
                        }
                        <DatePicker
                            selected={date}
                            onChange={(date: Date) => {
                                setIsDateValid(true);
                                passingFormSetDate(date);
                            }}
                            customInput={<DatePickerInput />}
                            minDate={new Date()}
                            timeFormat="HH:mm"
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeIntervals={10}
                            showTimeSelect
                        />
                    </div>
                    <div className="addOrder-item">
                        <FormLabel>Колличество доступных мест</FormLabel>
                            {
                                !isPlacesValid && (
                                    <img className="mb-2 passing-form__invalid-field-icon" src={warningIcon} />
                                )
                            }
                        <Input
                            variant="solid"
                            className={isPlacesValid ? "" : "passing-form__invalid-field-icon"}
                            value={places}
                            onChange={(event) => {
                                let preparedValue = event.target.value.replace(/\D/g, "");
                                preparedValue = preparedValue === "" ? "" : preparedValue;
                                setIsPlacesValid(true);
                                passingFormSetPlaces(preparedValue);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="passing-form-line">
                <div className="passing-form-aircraft">
                    <FormLabel>Выберите самолет</FormLabel>
                    {
                        !isAircraftValid && (
                            <img className="mb-2 passing-form__invalid-field-icon" src={warningIcon} />
                        )
                    }
                    <FormSelect
                        className="form-control form-control-solid"
                        aria-label="Выберите самолет"
                        onChange={(event) => {
                            let preparedValue = parseInt(event.target.value, 10);
                            setIsAircraftValid(true);
                            passingFormSetAircraft(preparedValue);
                        }}
                    >
                        <option value="">Выберите самолет</option>
                        {
                            aircrafts?.map((currentAircraft) => (
                                <option
                                    key={currentAircraft.id}
                                    value={currentAircraft.id}
                                >
                                    {currentAircraft.type}
                                </option>
                            ))
                        }
                    </FormSelect>
                </div>
                <div className="passing-form-price">
                    <FormLabel>Укажите цену за перелет</FormLabel>
                    {
                        !isPriceValid && (
                            <img className="mb-2 passing-form__invalid-field-icon" src={warningIcon} />
                        )
                    }
                    <Input
                        variant="solid"
                        value={price}
                        onChange={(event) => {
                            let preparedValue = event.target.value.replace(/\D/g, "");
                            preparedValue = preparedValue === "" ? "" : preparedValue;
                            setIsPriceValid(true);
                            passingFormSetPrice(preparedValue);
                        }}
                    />
                </div>
            </div>
            <Button
                onClick={(() => {
                    validateAndCreate();
                })}>Опубликовать рейс
            </Button>
        </div>
    </Card>
}

