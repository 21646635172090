import {httpRequest} from "../../../shared/http/http-request/http-request";

export interface TGetResetPasswordResponse {
    userMail: string
}
export const getResetPasswordHttp = async (
    userMail: string,
): Promise<TGetResetPasswordResponse> => {
    const getResetPasswordResponse = await httpRequest(`auth/reset-password?username=${userMail}`);

    return await getResetPasswordResponse.json();
};
