import {IUser} from "../types/user.types";
import {httpRequest} from "../../../shared/http/http-request/http-request";

export type TGetUserHttpResponse = IUser;

export const getUserHttp = async ( id: number ): Promise<TGetUserHttpResponse> => {

    const getUserResponse = await httpRequest(`user/${id}`);

    return getUserResponse.json();
};
