import React, {FC, useEffect, useMemo} from "react";
import { Stack } from "react-bootstrap";
import './team-list.css';
import {$teamList, getActualTeamList} from "../../store/team-list.store";
import {useStore} from "effector-react";
import {TeamListItem} from "./team-list-item/team-list-item";
import dayjs from 'dayjs';

export const TeamList: FC = () => {

    useEffect(() => {
        getActualTeamList();
    }, []);

    const teams = useStore($teamList) || [];

    const currentDate = useMemo(() => {
        return dayjs().format("DD.MM.YYYY")
    }, []);

    if (teams === null) {
        return null;
    }

    return (
        <div className="row">
            <Stack direction="horizontal" className="content-header">
                <div className="title">
                    <div className="title-text">Список команд</div>
                    <div className="title-date">{`На ${currentDate}`}</div>
                </div>
            </Stack>
            {
                teams.length === 0
                    ? (
                        <div className="title-text">Заявок нет</div>
                    )
                    : (
                        <div className="container_type_table">
                            <div className="list">
                                <div className="list-header">
                                    <div className="list-header-item min-w-75px px-0">Номер заявки</div>
                                    <div className="list-header-item min-w-75px px-0">Название команды</div>
                                    <div className="list-header-item min-w-75px px-0">Контактное лицо</div>
                                </div>
                                {
                                    teams.map((team) => (
                                        <TeamListItem
                                            key={team.id}
                                            team={team}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
        </div>
    );
}

