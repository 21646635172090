import React, {FC} from "react";
import {Text} from "../../../../../components/text";
import {FormLabel, FormSelect} from "react-bootstrap";
import {Input} from "../../../../../components/input/input";
import {TextArea} from "../../../../../components/textArea";
import {FilesInput} from "../../../../../components/filesInput";
import "./offer-form.css";
import {useStore} from "effector-react";
import {$aircrafts} from "../../../../aircraft/store/aircrafts.store";
import {IOffer} from "../../../types/offer.types";
import {changeOfferAircraft, changeOfferComment, changeOfferFiles, changeOfferPrice} from "../../../store/offer.store";

interface IProps {
    offer: IOffer;
}

export const OfferForm: FC<IProps> = ({
    offer,
}) => {
    const aircrafts = useStore($aircrafts);

    return (
        <>
            <div className="aviaOffer-separator" />
            <Text className="aviaOffer-title" type="subtitle">Предложение по заявке</Text>
            <div className="aviaOffer-line">
                <div className="aviaOffer-half">
                    <FormLabel>Выберите самолет</FormLabel>
                    <FormSelect
                        className="form-control form-control-solid"
                        aria-label="Выберите самолет"
                        value={offer.aircraft?.id}
                        onChange={(event) => {
                            changeOfferAircraft({
                                aircraft: parseInt(event.target.value, 10),
                            });
                        }}
                    >
                        <option value="">Выберите самолет</option>
                        {
                            aircrafts?.map((currentAircraft) => (
                                <option
                                    key={currentAircraft.id}
                                    value={currentAircraft.id}
                                >
                                    {currentAircraft.type}
                                </option>
                            ))
                        }
                    </FormSelect>
                </div>
                <div className="aviaOffer-half">
                    <FormLabel>Укажите цену за перелет</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        value={offer.price === 0 ? "" : offer.price.toString(10)}
                        onChange={(event) => {
                            let preparedValue = event.target.value.replace(/\D/g, "");
                            preparedValue = preparedValue === "" ? "0" : preparedValue;
                            changeOfferPrice({
                                price: preparedValue,
                            });
                        }}
                    />
                </div>
            </div>
            <div className="aviaOffer-line">
                <div className="aviaOffer-half">
                    <FormLabel>Комментарий</FormLabel>
                    <TextArea
                        value={offer.comment}
                        onChange={(event) => {
                            changeOfferComment({
                                comment: event.target.value,
                            });
                        }}
                    />
                </div>
                <div className="aviaOffer-half">
                    <FormLabel>Файлы</FormLabel>
                    <FilesInput
                        onChange={(files) => {
                            changeOfferFiles({
                                files,
                            });
                        }}
                    />
                </div>
            </div>
        </>
    )
};
