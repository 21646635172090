import React, {FC, useCallback, useEffect} from "react";
import {useStore} from "effector-react";
import {Button, Card, FormLabel} from "react-bootstrap";
import {
    $userSettingsName,
    $userSettingsOrganizationName,
    $userSettingsPhone,
    getUserSettings
} from "../../../user/store/user-settings.store";
import {Link, useParams} from "react-router-dom";
import {Text} from "../../../../components/text";
import {Input} from "../../../../components/input/input";
import {$gamesCalendar, getGamesCalendar} from "../../store/games-calendar.store";
import {GameCalendarItem} from "./game-caledar-item/game-calendar-item";
import {
    $gamesCalendarForm, addGameCalendarForm, saveNewGameForm,
} from "../../store/game-form.store";
import {GameCalendarForm} from "../game-calendar-form/game-calendar-form";
import "../game-calendar/game-calendar.css"
import plus2 from "./plus2.png";
import {EUserStatus} from "../../../user/types/user.types";
import {$authorizedUser} from "../../../user/store/authorized-user.store";


export const GameCalendar: FC = () => {

    const user = useStore($authorizedUser);
    const {id} = useParams();

    const teamName = useStore($userSettingsOrganizationName);
    const contactName = useStore($userSettingsName);
    const contactPhone = useStore($userSettingsPhone);
    const gamesCalendar = useStore($gamesCalendar) ?? [];
    const gameCalendarForm = useStore($gamesCalendarForm);

    useEffect(() => {
        if (id) {
            getGamesCalendar(parseInt(id, 10));
        }
    }, [id]);

    useEffect(() => {
        getUserSettings();
    }, []);

    const saveNewGameCalendar = useCallback(() => {
        gameCalendarForm.forEach((gameCalendar) => {
            saveNewGameForm({gameName: gameCalendar.game_name, gameDate: gameCalendar.game_date, teamId: Number(id)});
        })
    }, [gameCalendarForm]);

    if(gamesCalendar === null) {
        return null;
    }

    if(user === null) {
        return null;
    }

    return <>
            <Card className="addTeamName">
            <>
                <div className="addTeamName-top">
                    <Text type="subtitle" className="addOrder-title">Страница команды</Text>
                    { user.status === EUserStatus.SUPERVISOR ? (
                        <Link to={"/dashboard/teams"}>
                            <Button className="addOrder-desktop" variant="link">{'<'} Список команд</Button>
                        </Link>
                    ) : null }
                </div>
                <div>
                    <div className="game-calendar-line">
                        <FormLabel className="team-info-header">Название</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={teamName}
                                onChange={()=>{}}
                            ></Input>
                    </div>
                    <FormLabel className="team-info-header">Контактная информация</FormLabel>
                    <div className="team-info">
                        <div className="game-calendar-line">
                            <FormLabel>Имя и фамилия</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={contactName}
                                onChange={()=>{}}
                            ></Input>
                        </div>
                        <div className="game-calendar-line">
                        <FormLabel>Телефон</FormLabel>
                        <Input
                            variant="solid"
                            type="text"
                            value={contactPhone}
                            onChange={()=>{}}
                        ></Input>
                        </div>
                    </div>
                </div>
                <div className="game-calendar-header">
                <FormLabel className="team-info-header">Календарь игр</FormLabel>
                <button
                    className="btn-add-game"
                    onClick={() => addGameCalendarForm()}
                ><img className="add-icon-image" src={plus2} alt="add button" /></button>
                </div>
                <div className="game-calendar">
                    {
                        gamesCalendar.map((game) =>
                                <GameCalendarItem key={game.id} game={game} ></GameCalendarItem>
                        )
                    }
                    {
                        gameCalendarForm.map((gameCalendarForm) => (
                            <GameCalendarForm key={gameCalendarForm.id} formGame={gameCalendarForm}/>
                        ))
                    }
                </div>
            </>
            </Card>
            <div className="addOrder-submit-game-cal">
                <Button
                    variant="primary"
                    onClick={saveNewGameCalendar}
                >Сохранить изменения</Button>
            </div>
    </>
}
