import {httpRequest} from "../../../shared/http/http-request/http-request";
import {getUrlencodedString} from "../../../shared/http/get-urlencoded-string/get-urlencoded-string";

export interface IAuthorizeUserHttpResponse {
    access_token: string;
    token_type: string;
}

export const authorizeUserHttp = async (
    username: string,
    password: string,
): Promise<IAuthorizeUserHttpResponse> => {
    const authorizeUserResponse = await httpRequest("auth/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: getUrlencodedString({
            username,
            password,
        }),
    });

    return authorizeUserResponse.json();
};
