import {FC} from "react";
import "./notice-bubble-item.css";

interface IProps {
    id: number;
    theme: "primary" | "danger";
    content: string;
    onRemove(id: number): void;
}

export const NoticeBubbleItem: FC<IProps> = ({
    id,
    theme,
    content,
    onRemove,
}) => {
    return (
        <div className={`alert alert-dismissible bg-${theme} d-flex flex-column flex-sm-row p-5`}>
            {/*<span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0">...</span>*/}
            <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                <h4 className="mb-2 text-light">
                    {
                        theme === "primary"
                            ? "Уведомление"
                            : "Ошибка"
                    }
                </h4>
                <span>{content}</span>
            </div>
            <button
                type="button"
                className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                onClick={() => {
                    onRemove(id);
                }}
            >
                <span className="svg-icon svg-icon-2x svg-icon-light">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                    </svg>
                </span>
            </button>
        </div>
    );
};
