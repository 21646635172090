import React, {RefObject, useCallback, useMemo} from 'react';
import { Button } from '../../../../../components/button';
import { Comment } from '../../../../../components/comment';
import { Text } from '../../../../../components/text';
import './application-offer.css';
import {IApplication, IOffer} from "../../../types/application.types";
import {approveOfferByTeam, cancelOfferByTeam, getApplication} from "../../../store/application.store";
import {useNavigate} from "react-router-dom";
import {getApplicationChatList} from "../../../store/application-chat.store";

interface IProps {
    offer: IOffer;
    application: IApplication;
    chatEl: RefObject<HTMLDivElement> | null;
}

export const ApplicationOffer: React.FC<IProps> = ({
    offer,
    application,
    chatEl
}) => {

    const navigate = useNavigate();

    const handleWriteToAk = useCallback((id: number) => {
        scrollToChat();
        navigate(`/dashboard/applications/${application.id}/airline/${id}`);
    }, [application.id, navigate]);

    const handleApproveOfferByTeam = useCallback((offerId: number, appId: number) => {
        approveOfferByTeam(offerId);

        const timeout = setTimeout(() => getApplication(appId), 1000);
        return () => clearTimeout(timeout);
    }, [application.id, application.offers])

    const handleCancelOfferByTeam = useCallback((offerId: number, appId: number) => {
        cancelOfferByTeam(offerId);

        const timeout = setTimeout(() => getApplication(appId), 1000);
        return () => clearTimeout(timeout);
    }, [application.id, application.offers])

    const scrollToChat = () => {
        if(!chatEl?.current) return;
        chatEl.current.scrollIntoView();
    };

    const files: string[] = useMemo(() => {
        if (offer.additional_file) {
            return [
                offer.additional_file,
            ];
        }
        return [];
    }, [offer]);

    return (
        <div className="offer">
            <div className="offer-description">
                <div className="offer-description-main">
                        {
                            Boolean(offer.airline.logo.length) && (
                                <div className="offer-company-logo">
                                    <img src={`/api/${offer.airline.logo}`} alt={`Logo of ${offer.airline.name}`} />
                                </div>
                            )
                        }
                        <div className="offer-company-text">
                            <div className="offer-status">
                                <div className={`status status_type_offered`}>
                                    { offer.status }
                                </div>
                            </div>
                            <div className="offer-company-name">{offer.airline.name}</div>
                            <div className="offer-company-plane">
                                {
                                    offer.aircraft !== null && (
                                        <>
                                            {offer.aircraft.type} <br/>
                                            Количество мест {offer.aircraft.max_pax}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                </div>
                <div className="offer-description-comment">
                    {
                        Boolean(offer.comment.length) && (
                            <div className="offer-comment">
                                <Comment text={offer.comment} />
                            </div>
                        )
                    }
                </div>
            </div>
            {
                (offer.status === "Предложение сформировано" || offer.status === "Предложение просмотрено"
                    || offer.status === "Предложение отклонено" || offer.status === "Предложение принято"
                    || offer.status === "Перелет выполнен" || offer.status === "Исполнен"
                    || offer.status === "Ожидает оплаты" || offer.status === "Билет выписан") && (<div className="offer-price">
                    <Text className="offer-price-value" type="text">
                        {offer.price + offer.additional_price} ₽
                    </Text>
                </div>)
            }
            <div className="offer-buttons">
                <Button
                    className="offer-control button-accept"
                    variant="primary"
                    onClick={() => {
                        handleWriteToAk(offer.airline.id);
                    }}
                >Написать в АК
                </Button>
                {
                    offer.status !== "Предложение отклонено" && offer.status !== "Предложение принято" && (<>
                    <Button
                        className="offer-control button-accept"
                        variant="green"
                        onClick={() => {
                            handleApproveOfferByTeam(offer.id, application.id);
                        }}>
                        Принять предложение
                    </Button>
                    <Button
                        className="offer-control"
                        variant="red"
                        onClick={() => {
                            handleCancelOfferByTeam(offer.id, application.id);
                        }}>
                        Отклонить предложение
                    </Button>
                    </>)}
            </div>
        </div>
    );
};

