import React, {FC, useEffect, useMemo} from "react";
import {Stack} from "react-bootstrap";
import dayjs from "dayjs";
import {UsersListItem} from "./users-list-item/users-list-item";
import {$usersList, getUsersList} from "../../store/get-users-list.store";
import {useStore} from "effector-react";

export const UsersList: FC = () => {

    useEffect(() => {
        getUsersList();
    }, []);

    const users = useStore($usersList) || [];

    const currentDate = useMemo(() => {
        return dayjs().format("DD.MM.YYYY")
    }, []);

    if (users === null) {
        return null;
    }

    return <div className="row">
        <Stack direction="horizontal" className="content-header">
            <div className="title">
                <div className="title-text">Пользователи</div>
                <div className="title-date">{`На ${currentDate}`}</div>
            </div>
        </Stack>
        {
            users.length === 0
                ? (
                    <div className="title-text">Заявок нет</div>
                )
                : (
                    <div className="container_type_table">
                        <div className="list">
                            <div className="list-header">
                                <div className="list-header-item min-w-75px px-0">Id</div>
                                <div className="list-header-item min-w-75px px-0">Имя пользователя</div>
                                <div className="list-header-item min-w-75px px-0">Логин</div>
                                <div className="list-header-item min-w-75px px-0">Роль</div>
                                <div className="list-header-item min-w-75px px-0">Статус</div>
                                <div className="list-header-item min-w-75px px-0">Действия</div>
                            </div>
                            {
                                users.map((user) => (
                                    <UsersListItem
                                        key={user.id}
                                        user={user}
                                    />
                                ))
                            }
                        </div>
                    </div>
                )
        }
    </div>
}
