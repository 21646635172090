import React, {FC} from "react";
import dayjs from "dayjs";
import "../passing-flight-form/passing-flight-form.css";
import {IPassingFlight} from "../../../types/passing-flight.types";
import {FormLabel} from "react-bootstrap";
import {Input} from "../../../../../components/input/input";
import {useStore} from "effector-react";
import {$airports} from "../../../../airport/store/airport.store";
import userLogo from "../../../../user/views/user-settings/userLogo.png";

interface IProps {
    passingFlight: IPassingFlight;
}

export const PassingFlightAkItem: FC<IProps> = ({
   passingFlight,
}) => {

    const airports = useStore($airports);
    const logoAircraft = passingFlight.image ? `/api/${passingFlight.image}` : userLogo;

    if(airports === null) {
        return null
    }

    const getDepartureAirport = () => {
        return airports.find((airport) => {
            return airport.id === passingFlight.departure_airport;
        });
    };

    const getArrivalAirport = () => {
        return airports.find((airport) => {
            return airport.id === passingFlight.arrival_airport;
        });
    };

    return (
        <div className="passing-flight-wrapper">
            <div className="passing-form-line">
                <div className="passing-plane">
                    <div className="passing-plane-image">
                        <img className="passing-plane-logo" src={logoAircraft} alt="Лого ак"/>
                    </div>
                </div>
                <div className="passing-form-departure">
                    <FormLabel>Вылет</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={`${getDepartureAirport()?.city_rus} (${getDepartureAirport()?.name}) ${getDepartureAirport()?.iata}`}
                        onChange={()=>{}}
                    ></Input>
                </div>
                <div className="passing-form-departure">
                    <FormLabel>Прилет</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={`${getArrivalAirport()?.city_rus} (${getArrivalAirport()?.name}) ${getArrivalAirport()?.iata}`}
                        onChange={()=>{}}
                    ></Input>
                </div>
                <div className="passing-form-date">
                    <FormLabel>Дата и время</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={dayjs(passingFlight.departure_datetime).format("D MMMM в H:mm")}
                        onChange={()=>{}}
                    ></Input>
                </div>
                <div className="addOrder-item">
                    <FormLabel>Колличество доступных мест</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={passingFlight.places.toString()}
                        onChange={()=>{}}
                    ></Input>
                </div>
            </div>
            <div className="passing-form-line">
                <div className="passing-form-aircraft">
                    <FormLabel>Самолет</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={passingFlight.aircraft_type}
                        onChange={()=>{}}
                    ></Input>
                </div>
                <div className="passing-form-price">
                    <FormLabel>Цена за билет</FormLabel>
                    <Input
                        variant="solid"
                        type="text"
                        disable={true}
                        value={passingFlight.price.toString()}
                        onChange={()=>{}}
                    ></Input>
                </div>
            </div>
            <div className="passing-flight-separator"/>
        </div>
    );
};

