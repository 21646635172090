import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IApplication} from "../types/application.types";

export type TGetApplicationsResponse = IApplication[];

export const getApplicationsHttp = async (): Promise<TGetApplicationsResponse> => {
    const getApplicationsResponse = await httpRequest("application");

    return getApplicationsResponse.json();
};
