import React, {FC} from "react";
import dayjs from "dayjs";
import {ISeatBlock} from "../../../types/seat-blocks.types";
import "./seat-blocks-list-item.css";

interface IProps {
    emptyLeg: ISeatBlock;
}

export const SeatBlocksListItem: FC<IProps> = ({
                                                 emptyLeg,
                                             }) => {
    return (
        <div className="item item_type_table">
            <div className="item-cell item-plane el">
                <div className="plane">
                    <div className="plane-image">
                        <img src={`/${emptyLeg.image}`} alt={emptyLeg.type}/>
                    </div>
                    <div className="plane-name">
                        <div className="plane-name-text">{emptyLeg.type}</div>
                    </div>
                </div>
            </div>
            <div className="item-cell item-paths el">
                <div className="item-path-name">
                    {emptyLeg.departure_place} - {emptyLeg.arrival_place}
                </div>
                <div className="item-path-date">
                    {dayjs(emptyLeg.departure_datetime).format("D MMMM в H:mm")}
                    {" - "}
                    {dayjs(emptyLeg.arrival_datetime).format("D MMMM в H:mm")}
                </div>
            </div>
            <div className="item-cell item-price el">
                {emptyLeg.price}
            </div>
            <div className="item-cell item-price el">
                {emptyLeg.pax}
            </div>
            <div className="item-points mt-4">
                <div className="item-point">
                    <div className="item-point-name">Вылет</div>
                    <div className="item-point-city">{emptyLeg.departure_place}</div>
                    <div className="item-point-about">{dayjs(emptyLeg.departure_datetime).format("D MMMM в H:mm")}</div>
                </div>
                <div className="item-point">
                    <div className="item-point-name">Прилет</div>
                    <div className="item-point-city">{emptyLeg.arrival_place}</div>
                    <div className="item-point-about">{dayjs(emptyLeg.arrival_datetime).format("D MMMM в H:mm")}</div>
                </div>
            </div>
            <div className="item-points mt-5">
                <div className="item-point">
                    <div className="item-point-name">Цена</div>
                    <div className="item-point-city">{emptyLeg.price}</div>
                </div>
                <div className="item-point">
                    <div className="item-point-name">Количество мест</div>
                    <div className="item-point-city">{emptyLeg.pax}</div>
                </div>
            </div>
        </div>
    );
};
