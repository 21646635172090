import {httpRequest} from "../../../shared/http/http-request/http-request";

export const attachFileToFoodAppHttp = async (
    foodId: string,
    file: File,
) => {
    const formData = new FormData();
    formData.append("document", file);

    return httpRequest(`food/${foodId}/file`, {
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        body: formData,
    });
};
