import {createEffect, createEvent, createStore, forward} from "effector";
import {getFoodAppsHttp, TGetFoodAppsResponse} from "../http/get-food-apps.http";
import {IFoodApp} from "../types/food.types";

export const getFoodApps = createEvent<void>();

export const getFoodAppsFx = createEffect<
    void,
    TGetFoodAppsResponse
>(() => {
    return getFoodAppsHttp();
});

export const $foodApps = createStore<IFoodApp[] | null>(null)
    .on(getFoodAppsFx.doneData, (_, payload) => {
        const sortedPayload = [...payload];
        sortedPayload.sort((a1, a2) => {
            if (a1.id < a2.id) {
                return 1;
            }

            return -1;
        });

        return sortedPayload;
    })
    .on(getFoodAppsFx.fail, () => null);

forward({
    from: getFoodApps,
    to: getFoodAppsFx,
});
