import {createEffect, createEvent, createStore, forward} from "effector";
import {getApplicationsHttp, TGetApplicationsResponse} from "../http/get-applications.http";
import {IApplication} from "../types/application.types";

export const getApplications = createEvent<void>();

export const getApplicationsFx = createEffect<
    void,
    TGetApplicationsResponse
>(() => {
    return getApplicationsHttp();
});

export const $applications = createStore<IApplication[] | null>(null)
    .on(getApplicationsFx.doneData, (_, payload) => {
        const sortedPayload = [...payload];
        sortedPayload.sort((a1, a2) => {
            if (a1.id < a2.id) {
                return 1;
            }

            return -1;
        });

        return sortedPayload;
    })
    .on(getApplicationsFx.fail, () => null);

forward({
    from: getApplications,
    to: getApplicationsFx,
});
