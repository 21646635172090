import {createEffect, createEvent, createStore, forward} from "effector";
import {getOffersHttp, TGetOffersResponse} from "../http/get-offers.http";
import {IOffer} from "../types/offer.types";

export const getOffers = createEvent<void>();

export const getOffersFx = createEffect<
    void,
    TGetOffersResponse
>(() => {
    return getOffersHttp();
});

export const $offers = createStore<IOffer[] | null>(null)
    .on(getOffersFx.doneData, (_, payload) => {
        const sortedPayload = [...payload];
        sortedPayload.sort((a1, a2) => {
            if (a1.id < a2.id) {
                return 1;
            }

            return -1;
        });

        return sortedPayload;
    })
    .on(getOffersFx.fail, () => null);

forward({
    from: getOffers,
    to: getOffersFx,
});
