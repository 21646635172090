import {combine, createEffect, createEvent, createStore, forward, restore, sample} from "effector";
import {getUserHttp, TGetUserHttpResponse} from "../http/get-user-settings-by-admin.http";
import {validateTelegram} from "../../../shared/validations/validations";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";
import {editUserByAdminHttp} from "../http/edit-user-by-admin.http";

export const setUserSettingsNameByAdmin = createEvent<string>();
export const setUserSettingsPhoneByAdmin = createEvent<string>();
export const setUserSettingsTelegramByAdmin = createEvent<string>();
export const setUserSettingsEmailByAdmin = createEvent<string>();
export const setUserSettingsOrganizationNameByAdmin = createEvent<string>();
export const setUserSettingsGroupEmailByAdmin = createEvent<string>();
export const setUserSettingsCharterEmailByAdmin = createEvent<string>();
export const setUserSettingsSiteByAdmin = createEvent<string>();
export const setUserSettingLogoByAdmin = createEvent<File | null>();
export const getUserByAdmin = createEvent<number>();
export const resetUserSettingsFormByAdmin = createEvent<void>();
export const saveUserSettingsByAdmin = createEvent<void>();


export const getUserByAdminFx = createEffect<
    number,
    TGetUserHttpResponse
>((id: number) => {
    return getUserHttp(id);
});


export const saveUserSettingsByAdminFx = createEffect(async ({
    id,
    name,
    groupEmail,
    charterEmail,
    phone,
    telegram,
    organizationName,
    site,
    }: any) => {
    await editUserByAdminHttp(id, organizationName, groupEmail, charterEmail, name, phone, `@${telegram}`, site);

    setUserSettingLogoByAdmin(null);
});

export const $userType = createStore<string>('')
    .on(getUserByAdminFx.doneData, (_, {type}) => type);

export const $userId = createStore<number>(0)
    .on(getUserByAdminFx.doneData, (_, {id}) => id);

export const $userSettingsNameByAdmin = restore(
    setUserSettingsNameByAdmin,
    "",
)
    .on(getUserByAdminFx.doneData, (_, {contact_name}) => contact_name)
    .reset(resetUserSettingsFormByAdmin);

export const $userSettingsPhoneByAdmin = restore(
    setUserSettingsPhoneByAdmin,
    "",
)
    .on(getUserByAdminFx.doneData, (_, {phone}) => phone)
    .reset(resetUserSettingsFormByAdmin);

export const $userSettingsTelegramByAdmin = restore(
    setUserSettingsTelegramByAdmin,
    ""
)
    .on(getUserByAdminFx.doneData, (_, {telegram}) => telegram.replace("@", ""))
    .reset(resetUserSettingsFormByAdmin);

export const $userSettingsIsTelegramValidByAdmin = createStore(false)
    .on($userSettingsTelegramByAdmin, (_, userTelegram) => validateTelegram(userTelegram))
    .reset(resetUserSettingsFormByAdmin);

export const $userSettingsEmailByAdmin = restore(
    setUserSettingsEmailByAdmin,
    "",
)
    .on(getUserByAdminFx.doneData, (_, {username}) => username)
    .reset(resetUserSettingsFormByAdmin);

export const $userSettingsOrganizationNameByAdmin = restore(
    setUserSettingsOrganizationNameByAdmin,
    "",
)
    .on(getUserByAdminFx.doneData, (_, {organization_name}) => organization_name)
    .reset(resetUserSettingsFormByAdmin);

export const $userSettingsGroupEmailByAdmin = restore(
    setUserSettingsGroupEmailByAdmin,
    ""
)
    .on(getUserByAdminFx.doneData, (_, {group_email}) => group_email)
    .reset(resetUserSettingsFormByAdmin);
export const $userSettingsCharterEmailByAdmin = restore(
    setUserSettingsCharterEmailByAdmin,
    ""
)
    .on(getUserByAdminFx.doneData, (_, {charter_email}) => charter_email)
    .reset(resetUserSettingsFormByAdmin);

export const $userSettingsSiteByAdmin = restore(
    setUserSettingsSiteByAdmin,
    "",
)
    .on(getUserByAdminFx.doneData, (_, {site}) => site)
    .reset(resetUserSettingsFormByAdmin);


sample({
    clock: saveUserSettingsByAdmin,
    source: combine({
        id: $userId,
        name: $userSettingsNameByAdmin,
        groupEmail: $userSettingsGroupEmailByAdmin,
        charterEmail: $userSettingsCharterEmailByAdmin,
        phone: $userSettingsPhoneByAdmin,
        telegram: $userSettingsTelegramByAdmin,
        email: $userSettingsEmailByAdmin,
        organizationName: $userSettingsOrganizationNameByAdmin,
        site: $userSettingsSiteByAdmin,
    }),
    target: saveUserSettingsByAdminFx,
});


forward({
    from: getUserByAdmin,
    to: getUserByAdminFx,
});

saveUserSettingsByAdminFx.done.watch(() => {
    showNotice("primary", "Настройки успешно сохранены.");
});

saveUserSettingsByAdminFx.fail.watch(() => {
    showNotice("danger", "Во время сохранения настроек произошла ошибка. Повторите операцию позже.");
});

