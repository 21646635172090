import {httpRequest} from "../../../shared/http/http-request/http-request";

export const sendMessageOfferHttp = (
    offerId: number,
    message: string,
): Promise<Response> => {
    return httpRequest(`offer/${offerId}/message`, {
        method: "POST",
        body: JSON.stringify({
            body: message
        })
    })
}
