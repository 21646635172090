import React, {FC, forwardRef} from "react";
import {Input} from "../../../../components/input/input";
import DatePicker from "react-datepicker";
import {dateTimeToDMY} from "../../../../shared/utils/dateUtils";
import {changeGamesCalendarForm, IGameCalendarForm} from "../../store/game-form.store";

interface IProps {
    formGame: IGameCalendarForm,
}

export const GameCalendarForm: FC<IProps> = ({
    formGame
}) => {
    const DatePickerInput: any = forwardRef<HTMLInputElement>(({value, onClick}: any, ref) => {
        return (
            <Input
                variant="solid"
                value={value.length === 0 ? "" : dateTimeToDMY(value)}
                onChange={() => null}
                onClick={onClick}
                inputRef={ref}
                placeholder="Выберите дату игры"
            />
        );
    });

    return <div className="game-calendar-item">
        <Input
            variant="solid"
            type="text"
            value={formGame.game_name}
            onChange={(event)=> {
                changeGamesCalendarForm({
                    ...formGame,
                    game_name: event.target.value
                })
            }}
            placeholder="Введите название команды"
        />
        <div className="data-picker-form">
            <DatePicker
                selected={formGame.game_date}
                onChange={(date: Date) => {
                    changeGamesCalendarForm({
                        ...formGame,
                        game_date: date,
                    });
                }}
                isClearable
                customInput={(<DatePickerInput />)}
                minDate={new Date()}
                dateFormat="yyyy-MM-dd"
                timeIntervals={10}
            />
        </div>
    </div>
}
