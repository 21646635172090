import {httpRequest} from "../../../shared/http/http-request/http-request";

export const editUserHttp = (
    organization_name: string,
    group_email: string,
    charter_email: string,
    contact_name: string,
    phone: string,
    telegram: string,
    site: string,
): Promise<Response> => {
    return httpRequest("auth/user", {
        method: "PUT",
        body: JSON.stringify({
            organization_name,
            group_email,
            charter_email,
            contact_name,
            phone,
            telegram,
            site
        }),
    })
};
