import {httpRequest} from "../../../shared/http/http-request/http-request";

export const activateUserHttp =  (
    id: number
): Promise<Response> => {
    return httpRequest(`activate-user/${id}`, {
        method: "POST",
        body: JSON.stringify({
            id: id
        })
    })
}

export const deactivateUserHttp = (
    id: number
): Promise<Response> => {
    return httpRequest(`deactivate-user/${id}`, {
        method: "POST",
        body: JSON.stringify({
            id: id
        })
    })
}
