import React, { useMemo } from "react";

import file from './upload/file.png';
import doc from './upload/doc.png';
import jpg from './upload/jpg.png';
import pdf from './upload/pdf.png';
import png from './upload/png.png';
import xls from './upload/xls.png';

import { Text } from "../text";

import './file.css';

interface IFileProps {
    name: string;
}

export const File: React.FC<IFileProps> = ({name }) => {
    const [filename, filetype] = useMemo(() => {
        const filenameParts = name.split("/");
        const fileFullName = filenameParts[filenameParts.length - 1];

        const [tail, ...head] = fileFullName.split(".").reverse();

        return [head.reverse().join("."), tail];
    }, [name]);

    const icon = useMemo(() => {
        switch(filetype) {
            case 'jpg':
                return <img className="file-icon-image" src={jpg} alt="PDF" />
            case 'png':
                return <img className="file-icon-image" src={png} alt="PDF" />
            case 'xls':
            case 'xlsx':
                return <img className="file-icon-image" src={xls} alt="PDF" />
            case 'pdf':
                return <img className="file-icon-image" src={pdf} alt="PDF" />
            case 'doc':
            case 'docx':
                return <img className="file-icon-image" src={doc} alt="DOC" />
            default:
                return <img className="file-icon-image" src={file} alt="DOC" />;
        }
    }, [filetype]);

    return (
        <a className="file" target="_blank" href={`/api/${name}`}>
            <div className="file-icon">
                {icon}
            </div>
            <div>
                <Text type="file">{filename}</Text>
            </div>
        </a>
    )
}
