import React, {FC, useEffect} from "react";
import {$filteredSeatBlocks, getSeatBlocks} from "../../store/seat-blocks.store";
import {useStore} from "effector-react";
import {SeatBlocksListItem} from "./seat-blocks-list-item/seat-blocks-list-item";
import "./seat-blocks-list.css";
import {ISeatBlock} from "../../types/seat-blocks.types";
import {SeatBlocksForm} from "./seat-blocks-form/seat-blocks-form";

export const SeatBlocksList: FC = () => {
    useEffect(() => {
        getSeatBlocks();
    }, []);

    const seatBlocks = useStore($filteredSeatBlocks);

    if (seatBlocks === null) {
        return null;
    }

    return (
        <div className="row">
            <div className="title">
                <div className="title-text mb-4">Блоки мест</div>
                <div className="title-under-text mb-3">
                    Многие авиакомпании предлагают оформление блока мест в самолете для групповых и корпоративных перевозок. При этом стоимость каждого места будет фиксированной и значительно ниже, чем при приобретении отдельных билетов на каждого пассажира.
                </div>
                <div className="title-under-text mb-3">
                    Для бронирования и покупки блока мест в самолете по выбранным направлениям свяжитесь с нами по тел: <a href="tel:+74955055797">+7 (495) 505-57-97</a> или email: <a href="mailto:charter@globus.aero">charter@globus.aero</a>
                </div>
            </div>
            <div className="container_type_table">
                {
                    seatBlocks.length === 0
                        ? (
                            <p className="title-under-text mb-9">На данный момент актуальных предложений нет</p>
                        )
                        : (
                            <div className="list">
                                <div className="list-header">
                                    <div className="list-header-item min-w-300px">Воздушное судно</div>
                                    <div className="list-header-item min-w-200px">Направление</div>
                                    <div className="list-header-item min-w-100px">Стоимость<br/>1 билета *</div>
                                    <div className="list-header-item min-w-100px">Количество мест</div>
                                </div>
                                {
                                    seatBlocks.map((application: ISeatBlock) => (
                                        <SeatBlocksListItem
                                            emptyLeg={application}
                                        />
                                    ))
                                }
                            </div>
                        )
                }
            </div>
            <div className="title-under-text mt-5">
                * Стоимость билета указана при оформлении от 10 мест для групповой перевозки.
            </div>
            <div className="mt-14">
                <SeatBlocksForm />
            </div>
        </div>
    );
};
