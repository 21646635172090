import {combine, createEffect, createEvent, restore, sample} from "effector";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";
import {sendSeatBlocksFormHttp} from "../http/send-seat-blocks-form.http";
import { dateToISOString } from '../../../shared/utils/dateUtils';

export const seatBlocksFormSetName = createEvent<string>();
export const seatBlocksFormSetPhone = createEvent<string>();
export const seatBlocksFormSetEmail = createEvent<string>();
export const seatBlocksFormSetComment = createEvent<string>();
export const seatBlocksFormSetDate = createEvent<Date | null>();
export const seatBlocksFormSetDeparture = createEvent<string>();
export const seatBlocksFormSetArrival = createEvent<string>();
export const seatBlocksFormSetPassengerNumber = createEvent<number>();
export const seatBlocksFormBigLuggage = createEvent<boolean>();
export const seatBlocksFormSetModalIsVisible = createEvent<boolean>();
export const seatBlocksFormSend = createEvent<void>();
export const resetSeatBlocksForm = createEvent<void>();

export const seatBlocksFormSendFx = createEffect(async (args: any) => {
    try {
        await sendSeatBlocksFormHttp(
            {
                "creation_date": dateToISOString(new Date()),
                "name": args.name,
                "phone": args.phone,
                "email": args.email,
                "departure_datetime": args.date ? dateToISOString(args.date) : '',
                "departure_airport": parseInt(args.departure, 10),
                "arrival_airport": parseInt(args.arrival, 10),
                "notes": args.comment,
                "pax": args.passengerNumber,
                "excess_luggage": args.bigLuggage,
            },
        );
    } catch (e: unknown) {
        throw e;
    }
});

seatBlocksFormSendFx.fail.watch(() => {
    showNotice("danger", "Во время создания заявки произошла ошибка. Повторите операцию позже.");
});

export const $seatBlocksFormComment = restore(
    seatBlocksFormSetComment,
    "",
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksFormName = restore(
    seatBlocksFormSetName,
    "",
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksFormPhone = restore(
    seatBlocksFormSetPhone,
    "",
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksFormEmail = restore(
    seatBlocksFormSetEmail,
    "",
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksPassengerNumber = restore(
    seatBlocksFormSetPassengerNumber,
    0,
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksBigLuggage = restore(
    seatBlocksFormBigLuggage,
    false,
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksFormDate = restore(
    seatBlocksFormSetDate,
    null,
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksFormDeparture = restore(
    seatBlocksFormSetDeparture,
    "",
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksFormArrival = restore(
    seatBlocksFormSetArrival,
    "",
)
    .reset(resetSeatBlocksForm);

export const $seatBlocksModalIsVisible = restore(
    seatBlocksFormSetModalIsVisible,
    false,
)
    .on(seatBlocksFormSendFx.done, () => true)
    .reset(resetSeatBlocksForm);

sample({
    clock: seatBlocksFormSend,
    target: seatBlocksFormSendFx,
    source: combine({
        comment: $seatBlocksFormComment,
        name: $seatBlocksFormName,
        phone: $seatBlocksFormPhone,
        email: $seatBlocksFormEmail,
        date: $seatBlocksFormDate,
        departure: $seatBlocksFormDeparture,
        arrival: $seatBlocksFormArrival,
        passengerNumber: $seatBlocksPassengerNumber,
        bigLuggage: $seatBlocksBigLuggage,
    }),
});
