import React, {FC} from "react";
import {IFoodApp} from "../../../types/food.types";
import {useStore} from "effector-react";
import {$airports} from "../../../../airport/store/airport.store";
import dayjs from "dayjs";
import {Link} from "react-router-dom";

interface IProps {
    foodApp: IFoodApp
}

export const FoodAppsItem: FC<IProps> = ({
    foodApp
    }) => {

    const airports = useStore($airports);

    const departureAirport = airports?.find((airport) => {
        return airport.id === foodApp.food_legs[0].departure_airport;
    });

    const arrivalAirport = airports?.find((airport) => {
        return airport.id === foodApp.food_legs[0].arrival_airport;
    });

    return (
        <Link className={`item item_type_table`} to={`/dashboard/food-list/${foodApp.id}`}>
            <div className="item-cell item-number">
                <div className="item-number-status">
                    <span>
                        <span className="item-number-card item-number-table-mobile">Заявка: </span>
                        {foodApp.id}
                    </span>
                </div>
            </div>
            <div className="item-cell item-path app">
                <div className="item-path-name">
                    {departureAirport?.city_rus} — {arrivalAirport?.city_rus}
                </div>
                <div className="item-path-date">
                    {dayjs(foodApp.food_legs[0].departure_datetime).format("D MMMM в H:mm")}
                </div>
            </div>
            <div className="item-cell item-passengers app">
                {foodApp.status}
            </div>
            <div className="item-points">
                <div className="item-point">
                    <div className="item-point-name">Вылет</div>
                    <div className="item-point-city">
                        {departureAirport?.name} {departureAirport?.iata}
                    </div>
                    <div className="item-point-about">
                        {departureAirport?.city_rus} в {dayjs(foodApp.food_legs[0].departure_datetime).format("H:mm")}
                    </div>
                </div>
                <div className="item-point">
                    <div className="item-point-name">Прилет</div>
                    <div className="item-point-city">
                        {arrivalAirport?.name} {arrivalAirport?.iata}
                    </div>
                </div>
            </div>
            <div className="item-points mt-5">
                <div className="item-point">
                    <div className="item-point-name">Статус</div>
                    <div className="item-point-city">
                        {foodApp.status}
                    </div>
                </div>
            </div>
        </Link>
    )
}
