import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IMessages} from "../../application/types/application.types";

export type TGetMessagesOfferResponse = {messages: IMessages[]};

export const getMessagesOfferHttp = async (
    offerId: number,
): Promise<TGetMessagesOfferResponse> => {
    const TGetMessagesOfferResponse = await httpRequest(`offer/${offerId}/messages`);

    return await TGetMessagesOfferResponse.json();
};
