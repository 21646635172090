import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IMessages} from "../types/application.types";

export type TGetMessagesResponse = {messages: IMessages[]};

export const getMessagesHttp = async (
    applicationId: number,
    airlineId: number,
): Promise<TGetMessagesResponse> => {
    const getMessagesResponse = await httpRequest(`application/${applicationId}/messages?airline_id=${airlineId}`);

    return await getMessagesResponse.json();
};
