import {httpRequest} from "../../../shared/http/http-request/http-request";
import {ITeam} from "../types/team.type";

export type TGetTeamListResponse = ITeam[];

export const getTeamListHttp = async (): Promise<TGetTeamListResponse> => {
    const getTeamListResponse = await httpRequest("/team ");

    return getTeamListResponse.json();
};
