export enum EUserType {
    TEAM = "team",
    AIRLINE = "airline"
}

export enum EUserStatus {
    NEW = "0",
    EMAIL_CONFIRMED = "1",
    BROKER_CONFIRMED = "2",
    SUPERVISOR = "3"
}

export interface IUser {
    id: number;
    username: string;
    type: EUserType;
    organization_name: string;
    group_email: string;
    charter_email: string;
    site: string;
    contact_name: string;
    telegram: string;
    phone: string;
    status: EUserStatus;
    logo?: string;
}

export interface IUserList {
    id: number;
    name: string;
    login: string;
    role: string;
    status: string;
}
