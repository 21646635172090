import {createEffect, createEvent, createStore, forward} from "effector";
import {IPassingFlight} from "../types/passing-flight.types";
import {getPassingFlightsTeamHttp, TGetPassingFlightsTeamResponse} from "../http/get-passing-flights-team.http";

export const getPassingFlightsTeam = createEvent<void>();

export const getPassingFlightsTeamFx = createEffect<
    void,
    TGetPassingFlightsTeamResponse
>(() => {
    return getPassingFlightsTeamHttp();
});

export const $passingFlightsTeam = createStore<IPassingFlight[] | null>(null)
    .on(getPassingFlightsTeamFx.doneData, (_, payload) => {
        const sortedPayload = [...payload];
        sortedPayload.sort((a1, a2) => {
            if (a1.id < a2.id) {
                return 1;
            }

            return -1;
        });

        return sortedPayload;
    })
    .on(getPassingFlightsTeamFx.fail, () => null);


forward({
    from: getPassingFlightsTeam,
    to: getPassingFlightsTeamFx,
});


