import React, {useCallback, useEffect, useState} from 'react';
import "./tabs.css"
import {IAirline} from "../../../../airline/types/airline.types";
import notificationIcon from "./notification.png";
import {useStore} from "effector-react";
import {$newMessages} from "../../../store/get-new-messages.store";

interface IProps {
    airlines: IAirline[];
    activeTab: number;
    onTabChange(id: number): void;
}

export const Tabs:React.FC<IProps> = ({airlines, activeTab, onTabChange}) => {

    const [localActiveTab, setLocalActiveTab] = useState(activeTab);
    const newMessagesId = useStore($newMessages);

    useEffect(() => {setLocalActiveTab(activeTab)}, [activeTab]);

    const handleTabChange = (id: number) => {
        setLocalActiveTab(id);
        onTabChange(id);
    }

    const isNewMessage = useCallback((airlineId: number) => {
        return !!newMessagesId?.find((newMessageId: number) => newMessageId === airlineId);
    }, [newMessagesId]);

    return <div className="chat-tabs">
        <div
            className={0 === localActiveTab ? "active-tab" : "hide-tab"}
            key={0}
            onClick={() => {handleTabChange(0)}}>
            <span className="airline-name">Globus</span>
        </div>
            {
                airlines.map((airline) => <div
                    className={airline.id === localActiveTab ? "active-tab" : "hide-tab"}
                    key={airline.id}
                    onClick={() => {handleTabChange(airline.id)}}><span className="user-logo-wrapper">
                    <img className="airline-logo" src={`/api/${airline.logo}`} alt="logo"/></span>
                    <span className="airline-name">{airline.name}</span>
                    {
                        isNewMessage(airline.id) && (<div>
                            <img className="sms-notification" src={notificationIcon} alt="notification"/>
                        </div>)
                    }
                </div>)
            }
    </div>
}


