import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IOffer} from "../types/offer.types";

export type TGetOffersResponse = IOffer[];

export const getOffersHttp = async (): Promise<TGetOffersResponse> => {
    const getOffersResponse = await httpRequest("offers");

    return getOffersResponse.json();
};
