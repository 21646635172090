import {TQueryObject} from "../http.types";

export const getUrlencodedString = (
    queryObject: TQueryObject,
): string => {
    const urlSearchParams = new URLSearchParams();

    for (const queryObjectKey in queryObject) {
        urlSearchParams.set(
            queryObjectKey,
            queryObject[queryObjectKey].toString(),
        );
    }

    return urlSearchParams.toString();
};
