import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IAircraft} from "../types/aircraft.types";

export type TGetAircraftsHttpResponse = IAircraft[];

export const getAircraftsHttp = async (
    airlineId: number,
): Promise<TGetAircraftsHttpResponse> => {
    const getAircraftsResponse = await httpRequest(`aircraft/${airlineId}`);

    return getAircraftsResponse.json();
};
