import React, {FC, useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Aside} from "../../aside";
import {Navigation} from "../../navigation";
import {Header} from "../../header/header";
import {Content} from "../../content/content";
import {getAirlines} from "../../../domains/airline/store/airlines.store";
import {$authorizedUser, getAuthorizedUser} from "../../../domains/user/store/authorized-user.store";
import {Applications} from "./applications/applications";
import {UserSettings} from "../../../domains/user/views/user-settings/user-settings";
import {getAirports} from "../../../domains/airport/store/airport.store";
import './dashboard.css';
import {getAircrafts} from "../../../domains/aircraft/store/aircrafts.store";
import {EmptyLegList} from "../../../domains/empty-legs/views/empty-leg-list/empty-leg-list";
import {useStore} from "effector-react";
import {AircraftList} from "../../../domains/aircraft/views/aircraft-list/aircraft-list";
import {AirTickets} from "../../../domains/airticket/views/air-tickets";
import {SeatBlocksList} from "../../../domains/seat-blocks/views/seat-blocks-list/seat-blocks-list";
import {EscortForm} from "../../../domains/escort/views/escort-form/escort-form";
import {Teams} from "./teams/teams";
import {ConfirmPassword} from "../../../domains/user/views/user-recover-password-form/confirm-password";
import {Foods} from "./foods/foods";
import {PassingFlights} from "./passing-flights/passing-flights";
import {Users} from "./users/users";


export const Dashboard: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getAuthorizedUser((user) => {
            if (user === false) {
                navigate("/?authRedirect=" + location.pathname + location.search);
            }

            if (user && user.type === "airline") {
                getAircrafts();
            }
        });

        getAirlines();
        getAirports();
    }, [navigate]);

    const user = useStore($authorizedUser);

    if (user === null) {
        return null;
    }

    return (
        <>
            <Aside>
                <Navigation />
            </Aside>
            <div className="wrapper d-flex flex-column flex-row-fluid">
                <Header />
                <Content>
                    <Routes>
                        <Route
                            path="/seat-blocks"
                            element={(
                                <SeatBlocksList />
                            )}
                        />
                        <Route
                            path="/passing-flights/*"
                            element={(
                                <PassingFlights />
                            )}
                        />
                        <Route
                            path="/applications/*"
                            element={(
                                <Applications />
                            )}
                        />
                        <Route
                            path="/settings"
                            element={(
                                <UserSettings />
                            )}
                        />
                        <Route
                            path="/aircrafts"
                            element={(
                                <AircraftList />
                            )}
                        />
                        <Route
                            path="/air-tickets"
                            element={(
                                <AirTickets />
                            )}
                        />
                        <Route
                            path="/escort"
                            element={(
                                <EscortForm />
                            )}
                        />
                        <Route
                            path="/food-list/*"
                            element={(
                             <Foods />
                            )}
                        />
                        <Route
                            path="/teams/*"
                            element={(
                                <Teams />
                            )}
                        />
                        <Route
                            path="/users-list/*"
                            element={(
                                <Users />
                            )}
                        />
                        <Route
                            path="/reset-password/*"
                            element={(
                                <ConfirmPassword />)}
                        />
                    </Routes>
                </Content>
            </div>
        </>
    );
};
