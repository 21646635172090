import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {
    $allAircraftAirlineList,
    $allAircraftChosenAirlineList,
    $allAircraftChosenMaxPaxList, $allAircraftChosenTypeList,
    $allAircraftMaxPaxList,
    $allAircraftTypeList, $filteredAllAircraftList,
    choseAirline,
    choseMaxPax, choseType,
    getAllAircrafts, getAllAircraftsFx,
    resetAllAircrafts
} from "../../store/aircraft-list.store";
import {useStore} from "effector-react";
import "./aircraft-list.css";
import {AircraftListItem} from "./aircraft-list-item/aircraft-list-item";
import {Button} from "../../../../components/button";
import {Checkbox} from "../../../../components/checkbox/checkbox";

export const AircraftList: FC = () => {
    const filterContainer = useRef<any>();

    const [visibleFilter, setVisibleFilter] = useState<string | false>(false);

    useEffect(() => {
        getAllAircrafts();

        return () => {
            resetAllAircrafts();
        };
    }, []);

    const showFilters = useCallback(() => {
        if (filterContainer.current) {
            filterContainer.current.classList.toggle("d-flex");
        }
    }, []);

    const isAircraftListPending = useStore(getAllAircraftsFx.pending);

    const allAirlines = useStore($allAircraftAirlineList);
    const chosenAirlines = useStore($allAircraftChosenAirlineList);

    const allMaxPax = useStore($allAircraftMaxPaxList);
    const chosenMaxPax = useStore($allAircraftChosenMaxPaxList);

    const allType = useStore($allAircraftTypeList);
    const chosenType = useStore($allAircraftChosenTypeList);

    const filteredAircrafts = useStore($filteredAllAircraftList);

    return (
        <div>
            <div className="aircraft-list-header">
                <div className="aircraft-list-header-left">
                    <h1 className="mb-0">Каталог самолетов</h1>
                    <Button
                        variant="white"
                        onClick={showFilters}
                        className="aircraft-list-filter-toggle"
                    >
                        Фильтры
                    </Button>
                </div>
                <div
                    ref={filterContainer}
                    className="aircraft-list-header-filter-container"
                >
                    <Button
                        variant="white"
                        onClick={() => {
                            setVisibleFilter(visibleFilter === "airline" ? false : "airline");
                        }}
                    >
                        {
                            allAirlines.length === chosenAirlines.length
                                ? "Авиакомпании (все)"
                                : `Авиакомпании (${chosenAirlines.length})`
                        }
                    </Button>
                    <Button
                        variant="white"
                        onClick={() => {
                            setVisibleFilter(visibleFilter === "type" ? false : "type");
                        }}
                    >
                        {
                            allType.length === chosenType.length
                                ? "Типы ВС (все)"
                                : `Типы ВС (${chosenType.length})`
                        }
                    </Button>
                    <Button
                        variant="white"
                        onClick={() => {
                            setVisibleFilter(visibleFilter === "max_pax" ? false : "max_pax");
                        }}
                    >
                        {
                            allMaxPax.length === chosenMaxPax.length
                                ? "Количество мест (любое)"
                                : `Количество мест (${chosenMaxPax.length})`
                        }
                    </Button>
                </div>
            </div>
            <div className="aircraft-list-filters">
                {
                    visibleFilter === "airline" && (
                        <div>
                            {
                                allAirlines.map((airline) => (
                                    <label>
                                        <Checkbox
                                            checked={chosenAirlines.includes(airline)}
                                            onChange={() => {
                                                choseAirline(airline);
                                            }}
                                        />
                                        <p>{airline}</p>
                                    </label>
                                ))
                            }
                        </div>
                    )
                }
                {
                    visibleFilter === "type" && (
                        <div>
                            {
                                allType.map((type) => (
                                    <label>
                                        <Checkbox
                                            checked={chosenType.includes(type)}
                                            onChange={() => {
                                                choseType(type);
                                            }}
                                        />
                                        <p>{type}</p>
                                    </label>
                                ))
                            }
                        </div>
                    )
                }
                {
                    visibleFilter === "max_pax" && (
                        <div>
                            {
                                allMaxPax.map((maxPax) => (
                                    <label>
                                        <Checkbox
                                            checked={chosenMaxPax.includes(maxPax)}
                                            onChange={() => {
                                                choseMaxPax(maxPax);
                                            }}
                                        />
                                        <p>{maxPax}</p>
                                    </label>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            <div
                className="aircraft-list-container"
            >
                {
                    filteredAircrafts.length === 0 && !isAircraftListPending
                        ? (
                            <p className="aircraft-list-empty">
                                Ни одного самолета, подходящего под выбранные условия не найдено
                            </p>
                        )
                        : (
                            <>
                                {
                                    filteredAircrafts.map((aircraft) => (
                                        <AircraftListItem
                                            key={aircraft.id}
                                            aircraft={aircraft}
                                        />
                                    ))
                                }
                            </>
                        )
                }
            </div>
        </div>
    );
};
