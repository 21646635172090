import React, {FC, useCallback, useState} from "react";
import {IUserList} from "../../../types/user.types";
import {Text} from "../../../../../components/text";
import {Link} from "react-router-dom";
import editUser from "./editUser.png";
import activateUser from "./activateUser.png";
import deleteUser from "./deleteUser.png";
import "./users-list-item.css";
import {activateUserHttp, deactivateUserHttp} from "../../../http/activate-deactivate-user.http";
import {Button} from "react-bootstrap";
import {getUsersList} from "../../../store/get-users-list.store";
import {getApplicationChatList} from "../../../../application/store/application-chat.store";
import {getPassingFlightsAk} from "../../../../passing-flights/store/passing-flights-ak.store";

interface UsersListItemProps {
    user: IUserList;
}
export const UsersListItem: FC<UsersListItemProps> = ({
    user
}) => {

    const [isModalIsVisible, setModalIsVisible] = useState(false);

    const handleActivateUser = useCallback((id: number,  event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        activateUserHttp(user.id);
        setModalIsVisible(true);

        const timeout = setTimeout(() =>  getUsersList(), 1000);
        return () => clearTimeout(timeout);
    }, [user.id]);

    const handleDeleteUser = useCallback((id: number, event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        deactivateUserHttp(user.id);
        setModalIsVisible(true);

        const timeout = setTimeout(() =>  getUsersList(), 1000);
        return () => clearTimeout(timeout);
    }, [user.id])


    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    return <>
        {
            isModalIsVisible && (
                <div className="escort-form__overlay">
                    <div className="escort-form__modal">
                        <p className="escort-form__modal-title">Успешно!</p>
                        <Button
                            className="escort-form__modal-button"
                            onClick={(event) => {
                                event.preventDefault();
                                setModalIsVisible(false);
                            }}
                        >Ok
                        </Button>
                    </div>
                </div>
            )
        }
        <Link className={`item item_type_table`} to={`/dashboard/users-list/edit-user/${user.id}`}>
            <div className="item-cell">
                <Text type="caption" className="list-item">{user.id}</Text>
            </div>
            <div className="item-cell">
                <Text type="caption" className="list-item">{user.name}</Text>
            </div>
            <div className="item-cell">
                <Text type="caption" className="list-item">{user.login}</Text>
            </div>
            <div className="item-cell">
                <Text type="caption" className="list-item">{user.role}</Text>
            </div>
            <div className="item-cell">
                <Text type="caption" className="list-item">{user.status}</Text>
            </div>
            <div className="item-cell">
                <button
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Редактировать"
                    className="btn-user-actions"
                    >
                    <img className="icon-image" src={editUser} alt="edit user button" /></button>
                <button
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Активировать"
                    className="btn-user-actions"
                    onClick={(event) => handleActivateUser(user.id, event)}>
                    <img className="icon-image" src={activateUser} alt="edit user button" />
                </button>
                <button
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Деактивировать"
                    className="btn-user-actions"
                    onClick={(event) => handleDeleteUser(user.id, event)}>
                    <img className="icon-image" src={deleteUser} alt="edit user button" /></button>
            </div>
        </Link>
    </>
}
