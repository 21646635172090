import React, {FC, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {$airports} from "../../../airport/store/airport.store";
import {useStore} from "effector-react";
import {Text} from "../../../../components/text";
import {Button, Card} from "react-bootstrap";
import dayjs from "dayjs";
import {Point} from "../../../../components/details/point";
import {Count} from "../../../../components/details/count";
import baggage from "../../../../components/details/upload/baggage.svg";
import pax from "../../../../components/details/upload/pax.svg";
import bpax from "../../../../components/details/upload/bpax.svg";
import {Comment} from "../../../../components/comment";
import "./offer.css";
import {$offer, getOffer, saveOffer, saveOfferFx} from "../../store/offer.store";
import {File} from "../../../../components/file";
import {OfferForm} from "./offer-form/offer-form";
import {OfferItem} from "./offer-item/offer-item";
import {OfferChat} from "../offer-chat/offer-chat";

export const Offer: FC = () => {
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            getOffer(parseInt(id, 10));
        }
    }, [id]);

    const navigate = useNavigate();

    const airports = useStore($airports);
    const offer = useStore($offer);

    const isPending = useStore(saveOfferFx.pending);

    if (offer === null || airports === null) {
        return null;
    }

    const getDepartureAirport = (legNumber: number) => {
        return airports.find((airport) => {
            return airport.id === offer.legs[legNumber].departure_airport;
        });
    };

    const getArrivalAirport = (legNumber: number) => {
        return airports.find((airport) => {
            return airport.id === offer.legs[legNumber].arrival_airport;
        });
    };

    return (
        <>
            {
                offer.legs.map((leg, legNumber) => (
                    <Card key={leg.departure_airport} className="order">
                        <div className="order-top">
                            <Text type="caption">
                                {offer?.team.organization_name}
                            </Text>
                            <div className="order-back">
                                <Link to="/dashboard/applications">
                                    <Button variant="link">{'<'} Актуальные заявки</Button>
                                </Link>
                            </div>
                        </div>
                        <div className="order-title">
                            <Text type="title">
                                {getDepartureAirport(legNumber)?.city_rus} — {getArrivalAirport(legNumber)?.city_rus}
                            </Text>
                            <Text type="title">
                                {dayjs(offer.legs[legNumber].departure_datetime).format("D MMMM в H:mm")}
                            </Text>
                            <Text type="title">
                                {offer.legs[legNumber].regular_number}
                            </Text>
                        </div>

                        <div className="order-layout">
                            <div className="order-left">
                                <div className="order-points">
                                    <Point
                                        title="Вылет"
                                        airport={`${getDepartureAirport(legNumber)?.name} ${getDepartureAirport(legNumber)?.iata}`}
                                        data={`${getDepartureAirport(legNumber)?.city_rus} в ${dayjs(offer.legs[legNumber].departure_datetime).format("H:mm")}`}
                                    />
                                    <Point
                                        title="Прилет"
                                        airport={`${getArrivalAirport(legNumber)?.name} ${getArrivalAirport(legNumber)?.iata}`}
                                        data={`${getArrivalAirport(legNumber)?.city_rus}`}
                                    />
                                </div>

                                <div className="order-counts">
                                    <Count count={offer.legs[legNumber].pax.toString()} text="Пассажиров" icon={pax} />
                                    <Count count={offer.legs[legNumber].business_pax.toString()} text="Бизнес классом" icon={bpax} />
                                    <Count count={offer.legs[legNumber].baggage_number.toString()} text="Мест багажа" icon={baggage} />
                                </div>

                                <div className="order-contacts">
                                    <Text type="caption">Контактная информация</Text>
                                    <div className="order-contacts-list">
                                        <Text type="text">{offer.team.contact_name}</Text>
                                        <Text type="text">{offer.team.phone}</Text>
                                        <Text type="text">{offer?.team.username}</Text>
                                    </div>
                                </div>
                                {
                                    offer && (offer.status === "Предложение отправлено" || offer.status === "Предложение получено"
                                    || offer.status === "Предложение просмотрено" || offer.status === "Предложение отклонено"
                                    || offer.status ===  "Предложение принято" || offer.status === "Перелет выполнен"
                                    || offer.status === "Исполнен" || offer.status === "Ожидает оплаты"
                                    || offer.status === "Билет выписан" || offer.status === "Отменена командой")
                                    && (
                                        <div className="order-offers">
                                            <OfferItem
                                                offer={offer}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="order-right">
                                {
                                    leg.baggage.length !== 0 && (
                                        <div className="order-comments">
                                            <Text type="caption">Негабаритный багаж</Text>
                                            <Comment text={leg.baggage} />
                                        </div>
                                    )
                                }
                                {
                                    offer.comment.length !== 0 && (
                                        <div className="order-comments">
                                            <Text type="caption">Комментарий к заказу</Text>
                                            <Comment text={offer.comment} />
                                        </div>
                                    )
                                }
                                <div className="order-files">
                                    {
                                        offer.files.length !== 0 && (
                                            <>
                                                <Text className="order-files-title" type="caption">Файлы</Text>
                                                <div className="order-files-list">
                                                    {
                                                        offer.files.map((file) => (
                                                            <File key={file} name={file} />
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>

                                <div className="order-type">
                                    <Text type="caption">Тип рейса</Text>
                                    {
                                        offer.flight_type === "regular" && (
                                            <Text type="text">{offer.flight_type === "regular" ? "Регулярный рейс" : ''}</Text>)
                                    }
                                    {
                                        offer.flight_type === "plane" && (
                                            <Text type="text">Чартер</Text>)
                                    }
                                    {
                                        offer.flight_type === "places" && (
                                            <Text type="text">Блок мест</Text>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="aviaOfferChat-separator">
                            </div>
                                 <Card className="offer-chat">
                                      <OfferChat offer={offer}/>
                                 </Card>
                        {
                            (
                                offer?.status === "Новая" ||
                                offer?.status === "В работе" ||
                                offer?.status === "Предложение готовится"
                            ) && (
                                <OfferForm
                                    offer={offer}
                                />
                            )
                        }
                    </Card>
                ))
            }
            {
                (
                    offer?.status === "Новая" ||
                    offer?.status === "В работе" ||
                    offer?.status === "Предложение готовится"
                ) && (
                    <Button
                        className="offer-send"
                        variant="primary"
                        disabled={isPending}
                        onClick={() => {
                            saveOffer(() => {
                                navigate("/dashboard/applications");
                            });
                        }}
                    >
                        Отправить заявку клубу
                    </Button>
                )
            }
        </>
    );
};
