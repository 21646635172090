import React, {FC} from "react";
import dayjs from "dayjs";
import {IApplication} from "../../../types/application.types";
import {useStore} from "effector-react";
import {$airports} from "../../../../airport/store/airport.store";
import "./application-list-item.css";
import {Link} from "react-router-dom";

interface IProps {
    application: IApplication;
}

export const ApplicationListItem: FC<IProps> = ({
    application,
}) => {
    const airports = useStore($airports);

    const departureAirport = airports?.find((airport) => {
        return airport.id === application.legs[0].departure_airport;
    });

    const arrivalAirport = airports?.find((airport) => {
        return airport.id === application.legs[0].arrival_airport;
    });


    return (
        <Link className={`item item_type_table`} to={`/dashboard/applications/${application.id}/airline/${application.airlines[0].id}`}>
            <div className="item-cell item-number">
                <div className="item-number-status">
                    <span>
                        <span className="item-number-card item-number-table-mobile">Заявка: </span>
                        {application.id}
                    </span>
                </div>
            </div>
            <div className="item-cell item-path app">
                <div className="item-path-name">
                    {departureAirport?.city_rus} — {arrivalAirport?.city_rus}
                </div>
                {
                    application.legs[0].departure_datetime ?
                        <div className="item-path-date">
                            {dayjs(application.legs[0].departure_datetime).format("D MMMM в H:mm")}
                        </div> : ""
                }
            </div>
            <div className="item-cell item-path app">
                {
                    application.flight_type === "regular" && (<div className="item-path-name">
                        Регулярный рейс
                    </div>)
                }
                {
                    application.flight_type === "plane" && (<div className="item-path-name">
                        Чартер
                    </div>)
                }
                {
                    application.flight_type === "passing" && (<div className="item-path-name">
                        Попутный рейс
                    </div>)
                }
            </div>
            <div className="item-cell item-passengers app">
              {application.app_status}
            </div>
            <div className="item-cell item-list-message app">
                {
                    application.new_messages && (
                    <div className="item-point-city">
                        Новое сообщение
                    </div>)
                }
                {
                    application.app_status === "Предложение сформировано" && (
                    <div className="item-point-city">
                        Получен расчет от АК
                    </div>)
                }
            </div>
            <div className="item-points">
                <div className="item-point">
                    <div className="item-point-name">Вылет</div>
                    <div className="item-point-city">
                        {departureAirport?.name} {departureAirport?.iata}
                    </div>
                    <div className="item-point-about">
                        {departureAirport?.city_rus} в {dayjs(application.legs[0].departure_datetime).format("H:mm")}
                    </div>
                </div>
                <div className="item-point">
                    <div className="item-point-name">Прилет</div>
                    <div className="item-point-city">
                        {arrivalAirport?.name} {arrivalAirport?.iata}
                    </div>
                </div>
            </div>
            <div className="item-points mt-5">
                <div className="item-point">
                    <div className="item-point-name">Статус</div>
                    <div className="item-point-city">
                      {application.app_status}
                    </div>
                </div>
                <div className="item-point">
                    <div className="item-point-city">Уведомления</div>
                    {
                        application.new_messages && (<div className="item-point-name">
                            Новое сообщение
                        </div>)
                    }
                    {
                        application.app_status === "Предложение сформировано" && (
                            <div className="item-point-name">
                                Получен расчет от АК
                            </div>)
                    }
                </div>
            </div>
            <div className="item-points mt-5">
                <div className="item-point">
                    <div className="item-point-name">Тип перелета</div>
                    {
                        application.flight_type === "regular" && (<div className="item-point-city">
                            Регулярный рейс
                        </div>)
                    }
                    {
                        application.flight_type === "plane" && (<div className="item-point-city">
                            Чартер
                        </div>)
                    }
                    {
                        application.flight_type === "passing" && (<div className="item-point-city">
                            Попутный рейс
                        </div>)
                    }
                </div>
            </div>
        </Link>
    );
};
