import {FC, PropsWithChildren} from "react";

import './aside.css';

export const Aside: FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <div className="aside aside-default aside-hoverable">
            <div className="aside-menu flex-column-fluid">
                {children}
            </div>
        </div>
    );
};
