import React from "react";
import {IMessages} from "../../../domains/application/types/application.types";
import "./chat-item.css"
import userLogo from "../../../domains/user/views/user-settings/userLogo.png"

interface IProps {
    message: IMessages;
    userEmail: string;
}

export const MessageItem:React.FC<IProps> = ({message, userEmail}) => {

    function extractEmails (text: string) {
        return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    }

    const client = extractEmails(message.author);

    const isAuthor = userEmail === client!?.toString();
    const author = message.author.split(/\(|\</)[0];
    const date = message.created.split('T')[0];
    const body = message.body.split('\n\n')[0];
    const linkToOrder = message.body.split('\n\n')[1];
    const logo = message.logo ? `/api/${message.logo}` : userLogo;

    return <>
        <div className="message-wrapper">
            <div className={isAuthor ? "current-user" : "agent"}>
                        <p className={isAuthor ? "current-user" : "agent"}>{body}</p>
                        <p className={isAuthor ? "current-user" : "agent"} dangerouslySetInnerHTML={{__html: linkToOrder}}></p>
                        <div className="message-author">
                            <p>{author} {date}</p>
                        </div>
            </div>
            <div className={isAuthor ? "current-user-logo" : "agent-logo"}>
                <img className="icon-size" src={logo} alt="logo"/>
            </div>
        </div>
    </>
}
