import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {$airports} from "../../../airport/store/airport.store";
import {$application, cancelApplication, getApplication} from "../../store/application.store";
import {useStore} from "effector-react";
import {Text} from "../../../../components/text";
import {Button, Card} from "react-bootstrap";
import dayjs from "dayjs";
import "./application.css";
import {ApplicationOffer} from "./application-offer/application-offer";
import {File} from "../../../../components/file";
import {Checkbox} from "../../../../components/checkbox/checkbox";
import {AppChat} from "../application-chat/app-chat";

const services = [{
    id: 1,
    name: "Разгрузо/погрузочные работы",
    value: false,
    field: "need_loader",
    checked: false
},{
    id: 2,
    name: "Флайт менеджер",
    value: false,
    field: "need_manager",
    checked: false
},{
    id: 3,
    name: "Трансфер",
    value: false,
    field: "transfer",
    checked: false
},{
    id: 4,
    name: "Постоплата",
    value: false,
    field: "post_payment",
    checked: false
},{
    id: 5,
    name: "Заказ питания",
    value: false,
    field: "food_order",
    checked: false
}];

export const Application: FC = () => {
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            getApplication(parseInt(id, 10));
        }
    }, [id]);

    const airports = useStore($airports);
    const application = useStore($application);
    const navigate = useNavigate();
    const appId = Number(id);
    const chatElement = useRef<HTMLDivElement>(null);

    const [cancelModalIsVisible, setCancelModalIsVisible] = useState(false);

    const handleCancelApplication = useCallback(() => {
        cancelApplication(appId);
        setCancelModalIsVisible(false);
        navigate("/dashboard/applications");
    }, [appId, navigate]);

    const mappedServices = useMemo(() => {
        return services.map((service) => ({
            ...service,
            value: !!application?.[service.field as keyof typeof application],
            checked: !!application?.[service.field as keyof typeof application]
        }))
    }, [application]).sort((a, b) => {
        return a.checked && !b.checked ? -1 : 1;
    });

    if (application === null || airports === null) {
        return null;
    }

    const getDepartureAirport = (legNumber: number) => {
        return airports.find((airport) => {
            return airport.id === application.legs[legNumber].departure_airport;
        });
    };

    const getArrivalAirport = (legNumber: number) => {
        return airports.find((airport) => {
            return airport.id === application.legs[legNumber].arrival_airport;
        });
    };

    const getPaxEconomy = (legNumber: number) => {
        const economyPax = application.legs[legNumber].pax - application.legs[legNumber].business_pax;
        return economyPax;
    }

    return (
        <>
            <Card key={application.id} className="order">
                {
                    cancelModalIsVisible && (
                        <div className="escort-form__overlay">
                            <div className="escort-form__modal">
                                <p className="escort-form__modal-title">Вы уверены, что хотите отменить заказ?</p>
                                <p className="escort-form__modal-content">После отмены заказа любые действия с заказом будут недоступны.</p>
                                <Button
                                    className="escort-form__modal-button"
                                    onClick={() => {
                                        handleCancelApplication();
                                    }}
                                >
                                    Да
                                </Button>
                                <Button
                                className="escort-form__modal-button"
                                onClick={() => {
                                    setCancelModalIsVisible(false);
                                }}
                                >
                                    Нет
                                </Button>
                            </div>
                        </div>
                    )
                }
                <div className="order-top">
                    <Text type="caption">Заказ: {application.id}</Text>
                    <div className="order-back">
                        <Link to="/dashboard/applications">
                            <Button variant="link">{'<'} Актуальные заявки</Button>
                        </Link>
                    </div>
                </div>
                <div className="order-top">
                    <div className="application-search-data flight-type">
                        <Text type="text" className="order-data-title">Тип рейса</Text>
                        {
                            application.flight_type === "regular" && (
                                <Text type="text" className="order-data">Регулярный рейс</Text>)
                        }
                        {
                            application.flight_type === "plane" && (
                                <Text type="text" className="order-data">Чартер</Text>)
                        }
                        {
                            application.flight_type === "passing" && (
                                <Text type="text" className="order-data">Попутный рейс</Text>)
                        }
                    </div>
                <div className="order-status-cancel">
                    <div className="order-status">{application.app_status}</div>
                    {
                        (application.app_status === "Новая" || application.app_status === "Предложение готовится"
                        || application.app_status === "Предложение получено" || application.app_status === "Предложение просмотрено")
                        && (
                            <button
                                className="order-cancel"
                                onClick={() => {
                                    setCancelModalIsVisible(true);
                                }}
                            >Отменить</button>
                        )
                    }
                    </div>
                </div>
                {
                    application.legs.map((leg, legNumber) => (
                        <>
                            <div className="order-title addOrder-line">
                                <div className="order-line-item">
                                    <Text type="title">
                                        {getDepartureAirport(legNumber)?.city_rus}
                                    </Text>
                                    <Text type="text">
                                        {`${getDepartureAirport(legNumber)?.name} ${getDepartureAirport(legNumber)?.iata}`}
                                    </Text>
                                </div>
                                <div className="order-line-item">
                                    <Text type="title">
                                        {getArrivalAirport(legNumber)?.city_rus}
                                    </Text>
                                    <Text type="text">
                                        {`${getArrivalAirport(legNumber)?.name} ${getArrivalAirport(legNumber)?.iata}`}
                                    </Text>
                                </div>
                                    <Text type="title">
                                        {dayjs(application.legs[legNumber].departure_datetime).format("D MMMM в H:mm")}
                                    </Text>
                                {
                                    application.legs[legNumber].departure_back_datetime && (
                                        <Text type="title">
                                            {dayjs(application.legs[legNumber].departure_back_datetime).format("D MMMM в H:mm")}
                                        </Text>
                                    )
                                }
                                    <Text type="title">
                                        {application.legs[legNumber].regular_number}
                                    </Text>
                            </div>
                            <div className="addOrder-line">
                                <div className="application-search-data">
                                    <Text type="text" className="order-data-title">Колличество пассажиров</Text>
                                    <Text type="text" className="order-data">{getPaxEconomy(legNumber)}</Text>
                                </div>
                                <div className="application-search-data">
                                    <Text type="text" className="order-data-title">Класс перелета</Text>
                                    <Text type="text" className="order-data">Эконом</Text>
                                </div>
                                <div className="application-search-data">
                                    <Text type="text" className="order-data-title">Колличество пассажиров</Text>
                                    <Text type="text" className="order-data">{application.legs[legNumber].business_pax.toString()}</Text>
                                </div>
                                <div className="application-search-data">
                                    <Text type="text" className="order-data-title">Класс перелета</Text>
                                    <Text type="text" className="order-data">Бизнес</Text>
                                </div>
                                <div className="application-search-data">
                                    <Text type="text" className="order-data-title">Мест багажа</Text>
                                    <Text type="text" className="order-data">{application.legs[legNumber].baggage_number.toString()}</Text>
                                </div>
                                <div className="application-search-data">
                                    <Text type="text" className="order-data-title">Негабаритный багаж</Text>
                                    <Text type="text" className="order-data">{leg.baggage}</Text>
                                </div>
                            </div>
                        </>
                    ))
                }
                <div>
                    <Text type="text" className="order-data-comment">Комментарий</Text>
                    <Text type="text" className="order-data">{application.comment}</Text>
                </div>
            </Card>
            {
                application.flight_type !== "passing" && application?.offers.length !== 0 && (
                    <Card className="order">
                        <div className="order-offers">
                            <div className="order-offers-list">
                                {
                                    application.offers.map((offer) => (
                                        <ApplicationOffer
                                            key={offer.id}
                                            offer={offer}
                                            application={application}
                                            chatEl={chatElement}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </Card>
                )
            }
            <div>
                {
                    application.flight_type !== "passing" && (
                        <Card className="order">
                            <Text type="text" className="сustom-title">Дополнительные услуги</Text>
                            <div className="services">
                                {mappedServices.map((service) => (
                                    <div key={service.id} className="service-item">
                                        <Checkbox
                                            checked={service.value}
                                            onChange={() => {}}>
                                            <label>{service.name}</label>
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        </Card>
                    )
                }
            </div>
            <Card className="order card" ref={chatElement}>
                <AppChat application={application}/>
            </Card>
            <div className="order-files">
                {
                    application.flight_type !== "passing" && application?.documents.length !== 0 && (
                        <Card className="order">
                            <div className="order-files">
                                <Text className="сustom-title" type="text">Файлы заказа</Text>
                                <div className="order-files-list">
                                    {
                                        application?.documents.map((file) => (
                                            <File key={file} name={file} />
                                        ))
                                    }
                                </div>
                            </div>
                        </Card>
                    )
                }
            </div>
        </>
    );
};
