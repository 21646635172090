import React, { ChangeEvent, useCallback, useEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import {Text} from "../text";
import {Button} from "../button";
import icon from './upload/icon.svg';
import iconDelete from './upload/iconDelete.svg';

import './fileInputMessage.css';

interface IFilesInputProps {
    onChange: (files: FileList | null) => void;
    onClear?: () => void;
}

export const FileInputMessage = forwardRef<{ reset(): void; }, IFilesInputProps>((props, ref) => {
    const { onClear, onChange } = props;
    const [dragging, setDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const [files, setFiles] = useState<File[]>([]);

    useImperativeHandle(ref, () => ({
      reset() {
        handleRemove();
      }
    }));

    const dropRef = React.createRef<HTMLDivElement>();

    const handleDrag = useCallback((e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragIn = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setDragCounter(dragCounter);
            if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length >= 0) {
                setDragging(true);
            }
        },
        [dragCounter, setDragging]
    );

    const handleDragOut = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setDragCounter(dragCounter - 1);
            if (dragCounter === 0) {
                setDragging(false);
            }
        },
        [dragCounter, setDragging]
    );

    const handleUploadFiles = useCallback(
        (uploadedFiles: FileList) => {
            const newFiles = [];
            for (let i = 0, file; (file = uploadedFiles[i++]); ) {
                const reader = new FileReader();
                newFiles.push(file);
                reader.readAsDataURL(file);
            }

            const dt = new DataTransfer();
            newFiles.forEach(file => {
                dt.items.add(file);
            });
            onChange(dt.files);
            setFiles(newFiles);
        },
        []
    );

    const handleDrop = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                handleUploadFiles(e.dataTransfer.files);
            }
        },
        [handleUploadFiles]
    );

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.files && e.target.files.length > 0) {
                handleUploadFiles(e.target.files);
            }
        },
        []
    );

    const handleRemove = useCallback(
        () => {
            const form = document.getElementById('chatFileInputForm');
            if (form) { // @ts-ignore
              form.reset();
            }
            onChange(null);
            setFiles([]);
        },
        []
    );

    useEffect(() => {
        const div = dropRef.current;

        if (div) {
            div.addEventListener('dragenter', handleDragIn);
            div.addEventListener('dragleave', handleDragOut);
            div.addEventListener('dragover', handleDrag);
            div.addEventListener('drop', handleDrop);
        }

        return () => {
            if (div) {
                div.removeEventListener('dragenter', handleDragIn);
                div.removeEventListener('dragleave', handleDragOut);
                div.removeEventListener('dragover', handleDrag);
                div.removeEventListener('drop', handleDrop);
            }
        };
    }, [dropRef, handleDrag, handleDragIn, handleDragOut, handleDrop]);

    const randomInputId = useMemo(
        () =>
            Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '')
                .substr(0, 5),
        []
    );

    return (
        <form id="chatFileInputForm" className="chatFileInputForm">
            <div className={`filesInputMessage${dragging ? ' filesInput_dragging' : ''}${files.length === 0 ? ' filesInput_empty' : ''}`} ref={dropRef}>
                <label htmlFor={randomInputId} className="filesInput-panel-message">
                    <div>
                        <img src={icon} alt="upload" />
                    </div>
                    <input
                        className="filesInput-input-message"
                        tabIndex={-1}
                        type="file"
                        id={randomInputId}
                        onChange={handleChange}
                        multiple
                    />
                </label>
                {files.length > 0 && (
                    <div className="filesInput-filelist-message">
                        {files.map((file, index) => (
                            <div key={file.name} className="filesInput-file-message">
                                <Text type="text">{file.name}</Text>
                                <Button variant="link" onClick={handleRemove}>
                                    <div>
                                        <img src={iconDelete} alt="delete" />
                                    </div>
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </form>
    );
});
