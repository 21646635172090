import { createEffect, createEvent, createStore, restore, forward, sample, combine } from "effector";
import {sendMessageOfferHttp} from "../http/send-offer-message.http";
import {getMessagesOfferHttp, TGetMessagesOfferResponse} from "../http/get-offer-messages.http";
import {IMessages} from "../../application/types/application.types";
import {attachFileToOfferHttp} from "../http/attach-file-to-offer.http";
import {getOffer} from "./offer.store";

export const getOfferChatList = createEvent<number>();
export const sendOneMessageOffer = createEvent<number>();
export const setOfferMessage = createEvent<string>();
export const resetOfferMessageForm = createEvent<void>();
export const setOfferMessageFile = createEvent<FileList | null>();


export const getOfferListFx = createEffect<
    number,
    TGetMessagesOfferResponse
>(   (id: number) => {

    return getMessagesOfferHttp(id);
});

export const $allMessagesOfferList = createStore<IMessages[] | null>(null)
    .on(getOfferListFx.doneData, (messages, payload) => {return payload.messages})
    .on(getOfferListFx.fail, () => null)
    .reset(resetOfferMessageForm)

forward({
    from: getOfferChatList,
    to: getOfferListFx,
});

export const sendOneMessageOfferFx = createEffect(async ({
   message,
   files,
   offerId
}: any) => {
  if (message) {
    await sendMessageOfferHttp(offerId, message);
    setOfferMessage('')
  }
  if (files) {
    for (const file of Array.from(files as FileList)) {
      Promise.all([
        await attachFileToOfferHttp(offerId, file),
        await sendMessageOfferHttp(offerId, `файл отправлен: ${file.name}`)
      ]).finally(() => {
        getOffer(offerId);
      })
    }
  }
  setOfferMessageFile(null);
});

export const $offerMessage = restore<string>(
    setOfferMessage,
    ""
)
    .reset(resetOfferMessageForm);

export const $offerMessageFile = restore<FileList | null>(
    setOfferMessageFile,
    null,
)
    .reset(resetOfferMessageForm);

sample({
  clock: sendOneMessageOffer,
  source: combine({
    message: $offerMessage,
    files: $offerMessageFile
  }),
  target: sendOneMessageOfferFx,
  fn: (source, offerId) => ({
    ...source,
      offerId,
  }),
});
