import {Button, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {FC, useEffect, useMemo} from "react";
import dayjs from "dayjs";
import {useStore} from "effector-react";
import {$foodApps, getFoodApps} from "../../store/food-apps.store";
import "../../../application/views/application-list/application-list.css"
import {FoodAppsItem} from "./food-list-item/food-list-item";

export const FoodList: FC = () => {

    useEffect(() => {
        getFoodApps();
    }, []);

    const foodApps = useStore($foodApps);

    const currentDate = useMemo(() => {
        return dayjs().format("DD.MM.YYYY")
    }, []);

    if (foodApps === null) {
        return null;
    }

    return (
        <>
            <div className="row">
                <Stack direction="horizontal" className="content-header">
                    <div className="title">
                        <div className="title-text">Актуальные заявки</div>
                        <div className="title-date">На {currentDate}</div>
                    </div>
                    <Stack direction="horizontal" className="controls" gap={3}>
                        <Link to={"/dashboard/food-list/food-form"}>
                            <Button
                                className="button-desktop-visible"
                                variant="primary"
                            >Новая заявка на питание</Button>
                        </Link>
                    </Stack>
                </Stack>
                {
                    foodApps.length === 0 ? (
                        <div className="title-text">Заявок нет</div>
                    ) : (
                        <div className={`container_type_table`}>
                            <div className="list">
                                <div className="list-header">
                                    <div className="list-header-item min-w-75px px-0">Заявка</div>
                                    <div className="list-header-item min-w-250px">Направление</div>
                                    <div className="list-header-item min-w-100px">Статус</div>
                                    <div className="list-header-item min-w-100px" />
                                </div>
                                {
                                    foodApps.map((foodApp) => (
                                        <FoodAppsItem
                                            key={foodApp.id}
                                            foodApp={foodApp}
                                        />
                                    ))
                                }
                            </div>
                            <Stack direction="horizontal" className="controls" gap={3}>
                                <Link to={"/dashboard/food-list/food-form"}>
                                    <Button
                                        className="button-add button-mobile-visible"
                                        variant="primary"
                                    >Новая заявка на питание</Button>
                                </Link>
                            </Stack>
                        </div>
                    )
                }
            </div>
        </>
    )
}
