import React from "react";
import { Text } from "../../text";

import './count.css';

interface ICountProps {
    count: string;
    icon?: string;
    text: string;
}

export const Count: React.FC<ICountProps> = ({ count, icon, text }) => {
    return (
        <div className="count">
            <div className="count-top">
                <div className="count-number">{count}</div>
                {icon && (
                    <div className="count-icon">
                        <img className="count-icon-image" src={icon} alt="icon" />
                    </div>   
                )}
            </div>
            <Text type="text">{text}</Text>
        </div>
    );
}