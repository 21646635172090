import React, {memo} from "react";
import {Text} from "../../../../../components/text";
import {Checkbox} from "../../../../../components/checkbox/checkbox";
import './application-form-services.css';
import {useStore} from "effector-react";
import {
    $applicationFormIsNeedFoodOrder,
    $applicationFormIsNeedLoader,
    $applicationFormIsNeedManager,
    $applicationFormIsNeedPostPayment,
    $applicationFormIsNeedTransfer,
    setApplicationFormIsNeedFoodOrder,
    setApplicationFormIsNeedLoader,
    setApplicationFormIsNeedManager, setApplicationFormIsNeedPostPayment, setApplicationFormIsNeedTransfer,
} from "../../../store/application-form.store";
import {FoodFormLeg} from "../../../../food/views/food-form/food-form-leg/food-form-leg";
import {$foodFormLegs} from "../../../../food/store/food-form.store";

const ApplicationFormServices: React.FC = memo(() => {
    const isNeedLoader = useStore($applicationFormIsNeedLoader);
    const isNeedManager = useStore($applicationFormIsNeedManager);
    const isNeedTransfer = useStore($applicationFormIsNeedTransfer);
    const isNeedPostPayment = useStore($applicationFormIsNeedPostPayment);
    const isNeedFoodOrder = useStore($applicationFormIsNeedFoodOrder);

    const foodFormLegs = useStore($foodFormLegs);

    return (
        <div className="addOrderServices">
            <div className={`addOrderServices-item${isNeedLoader ? ' addOrderServices-item_checked' : ''}`}>
                <div className="addOrderServices-check">
                    <Checkbox id="Погрузка" checked={isNeedLoader} onChange={setApplicationFormIsNeedLoader} />
                </div>
                <label htmlFor="Погрузка">
                    <Text className="addOrderServices-text" type="text">
                        Погрузка/разгрузка спортивного инвентаря вне терминалов аэропортов
                    </Text>
                </label>
            </div>
            <div className={`addOrderServices-item${isNeedManager ? ' addOrderServices-item_checked' : ''}`}>
                <div className="addOrderServices-check">
                    <Checkbox id="Флайт менеджер" checked={isNeedManager} onChange={setApplicationFormIsNeedManager} />
                </div>
                <label htmlFor="Флайт менеджер">
                    <Text className="addOrderServices-text" type="text">
                        Флайт менеджер
                    </Text>
                    <Text className="addOrderServices-underText" type="text">
                        Специалист, который будет находиться с командой на протяжении всего маршрута и будет решать все возникшие проблемы
                    </Text>
                </label>
            </div>
            <div className={`addOrderServices-item${isNeedTransfer ? ' addOrderServices-item_checked' : ''}`}>
                <div className="addOrderServices-check">
                    <Checkbox id="Трансфер" checked={isNeedTransfer} onChange={setApplicationFormIsNeedTransfer} />
                </div>
                <label htmlFor="Трансфер">
                    <Text className="addOrderServices-text" type="text">
                        Трансфер из аэропорта в гостиницу и обратно
                    </Text>
                </label>
            </div>
            <div className={`addOrderServices-item${isNeedPostPayment ? ' addOrderServices-item_checked' : ''}`}>
                <div className="addOrderServices-check">
                    <Checkbox id={'Постоплата'} checked={isNeedPostPayment} onChange={setApplicationFormIsNeedPostPayment} />
                </div>
                <label htmlFor="Постоплата">
                    <Text className="addOrderServices-text" type="text">
                        Постоплата
                    </Text>
                </label>
            </div>
            <div className={`addOrderServices-item${isNeedFoodOrder ? ' addOrderServices-item_checked' : ''}`}>
                <div className="addOrderServices-check">
                    <Checkbox id={'Заказ питания'} checked={isNeedFoodOrder} onChange={setApplicationFormIsNeedFoodOrder} />
                </div>
                <label htmlFor="Постоплата">
                    <Text className="addOrderServices-text" type="text">
                        Заказ питания
                    </Text>
                </label>
            </div>
            <div>
                {
                    isNeedFoodOrder && (
                        <div className="food-order">
                            <Text type="subtitle" className="addOrder-title addOrder-secondary">
                                Детали заказа питания
                            </Text>
                            {
                                foodFormLegs.map((foodLeg) => (
                                    <FoodFormLeg
                                        key={foodLeg.id}
                                        foodLeg={foodLeg}
                                    />))
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
});

export default ApplicationFormServices;
