import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IOffer} from "../types/offer.types";

export type TGetOfferResponse = IOffer;

export const getOfferHttp = async (
    id: number,
): Promise<TGetOfferResponse> => {
    const getOfferResponse = await httpRequest(`offers/${id}`);

    return getOfferResponse.json();
};
