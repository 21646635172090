import {combine, createEffect, createEvent, createStore, restore, sample} from "effector";
import {createFoodAppHttp} from "../http/create-food-app.http";
import {attachFileToFoodAppHttp} from "../http/attach-file-to-food-apps.http";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";

export interface IFoodFormLeg {
    id: number;
    departure_airport: number | null;
    arrival_airport: number | null;
    departure_datetime: Date | null;
    regular_number?: string;
    portions: number;
    launch_box: boolean;
    porcelain: boolean;
    dester: boolean;
}

export const addFoodFormLeg = createEvent<void>();
export const changeFoodFormLeg = createEvent<IFoodFormLeg>();
export const setFoodFormComment = createEvent<string>();
export const setFoodFormFiles = createEvent<FileList>();
export const saveFoodForm = createEvent<() => void>();
export const resetFoodForm = createEvent<void>();


export const saveFoodFormFx = createEffect(async ({
      foodLegs,
      comment,
      files,
      callback,
}: any) => {

    const createFoodAppResponse = await createFoodAppHttp(
        foodLegs,
        comment,
    );

    const foodAppId = await createFoodAppResponse.text();

    if (files) {
        for (const file of Array.from(files as FileList)) {
            attachFileToFoodAppHttp(foodAppId, file);
        }
    }

    callback();
});

export const $foodFormLastLegId = createStore({id: 0, launch_box: false, porcelain: false, dester: false})
    .on(addFoodFormLeg, (store) => ({...store, id: store.id + 1}))
    .reset(resetFoodForm);

export const $foodFormLegs = createStore<IFoodFormLeg[]>([{
    id: 0,
    departure_airport: null,
    arrival_airport: null,
    departure_datetime: null,
    regular_number: "",
    portions: 0,
    launch_box: false,
    porcelain: false,
    dester: false,

}])
    .on($foodFormLastLegId, (store, payload: {id: number, launch_box: boolean, porcelain: boolean, dester: boolean}) => {

        return [
            ...store,
            {
                id: payload.id,
                departure_airport: null,
                arrival_airport: null,
                departure_datetime: null,
                regular_number: "",
                portions: 0,
                launch_box: payload.launch_box,
                porcelain: payload.porcelain,
                dester: payload.dester,
            },
        ];
    })
    .on(changeFoodFormLeg, (store, payload) => {
        return store.map((currentLeg) => {
            if (currentLeg.id === payload.id) {
                return payload;
            }

            return currentLeg;
        })
    })
    .reset(resetFoodForm);

export const $foodFormComment = restore<string>(
    setFoodFormComment,
    "",
)
    .reset(resetFoodForm);

export const $foodFormFiles = restore<FileList>(
    setFoodFormFiles,
    null,
)
    .reset(resetFoodForm);

sample({
    clock: saveFoodForm,
    source: combine({
        foodLegs: $foodFormLegs,
        comment: $foodFormComment,
        files: $foodFormFiles,
    }),
    target: saveFoodFormFx,
    fn: (source, callback) => ({
        ...source,
        callback,
    }),
});

saveFoodFormFx.fail.watch(() => {
    showNotice("danger", "Во время создания заявки произошла ошибка. Повторите операцию позже.");
});
