import {createEffect, createEvent, createStore, forward} from "effector";
import {IApplication} from "../types/application.types";
import {getApplicationHttp, TGetApplicationResponse} from "../http/get-application.http";
import {approveOfferByTeamHttp} from "../../offer/http/approve-offer-by-team.http";
import {cancelOfferByTeamHttp} from "../../offer/http/cancel-offer-by-team.http";
import {cancelApplicationHttp} from "../http/cancel-application.http";

export const getApplication = createEvent<number>();
export const approveOfferByTeam = createEvent<number>();
export const cancelOfferByTeam = createEvent<number>();
export const cancelApplication = createEvent<number>();

export const getApplicationFx = createEffect<
    number,
    TGetApplicationResponse
>((id: number) => {
    return getApplicationHttp(id);
});

export const approveOfferByTeamFx = createEffect<
    number,
    Promise<Response>
>((id) => {
    return approveOfferByTeamHttp(id);
});

export const cancelOfferByTeamFx = createEffect<
    number,
    Promise<Response>
>((id) => {
    return cancelOfferByTeamHttp(id);
});

export const cancelApplicationFx = createEffect<
    number,
    Promise<Response>
>((id) => {
    return cancelApplicationHttp(id);
})

export const $application = createStore<IApplication | null>(null)
    .on(getApplicationFx.doneData, (_, payload) => {
        const sortedOffers = [...payload.offers];

        sortedOffers.sort((a, b) => {
            const aStatus = parseInt(a.status, 10);
            const bStatus = parseInt(b.status, 10);

            return bStatus - aStatus;
        });


        return {
            ...payload,
            offers: sortedOffers,
        };
    })
    .on(getApplicationFx.fail, () => null)
    .on(approveOfferByTeamFx.done, (store, payload) => {
        if (store === null) {
            return null;
        }

        return {
            ...store,
            offers: store.offers.map((offer) => {
                if (offer.id === payload.params) {
                    return {
                        ...offer,
                        status: "12",
                    }
                }

                return {
                    ...offer,
                    status: "11"
                };
            }),
        }
    })
    .on(cancelOfferByTeamFx.done, (store, payload) => {
        if (store === null) {
            return null;
        }

        return {
            ...store,
            offers: store.offers.map((offer) => {
                if (offer.id === payload.params) {
                    return {
                        ...offer,
                        status: "11",
                    }
                }

                return offer;
            }),
        }
    });

forward({
    from: getApplication,
    to: getApplicationFx,
});

forward({
    from: approveOfferByTeam,
    to: approveOfferByTeamFx,
});

forward({
    from: cancelOfferByTeam,
    to: cancelOfferByTeamFx,
});

forward({
    from: cancelApplication,
    to: cancelApplicationFx,
});
