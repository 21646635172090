import React, { useCallback, useEffect} from "react";
import {Chat} from "../../../../components/chat/chat";
import {useStore} from "effector-react";
import {IOffer} from "../../types/offer.types";
import {
    $allMessagesOfferList,
    getOfferChatList, resetOfferMessageForm, sendOneMessageOffer,
    setOfferMessage, setOfferMessageFile
} from "../../store/offer-chat.store";


interface IApplicationChatListProps {
    offer: IOffer;
}

export const OfferChat:React.FC<IApplicationChatListProps> = ({offer}) => {

    const offerMessages = useStore($allMessagesOfferList) ?? [];

    useEffect(() => {
        getOfferChatList(offer.id)
    }, [offer]);

    useEffect(() => {
        const interval = setInterval(() => {
            getOfferChatList(offer.id);
        }, 30000);
        return () => clearInterval(interval);
    }, [offer.id]);


    useEffect(() => () => resetOfferMessageForm(), []);

    const handleSendMessage = useCallback((message: string, file: FileList | null) => {
        setOfferMessage(message);
        setOfferMessageFile(file);

        sendOneMessageOffer(offer.id);

        const interval = setInterval (() => getOfferChatList(offer.id), 1000);
        return () => clearInterval(interval);
    }, [offer.id])

    return <div>
        <Chat messages={offerMessages} onSend={handleSendMessage} />
    </div>
}
