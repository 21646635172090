import React, {FC, useEffect, useMemo} from "react";
import {$applications, getApplications} from "../../store/applications.store";
import {useStore} from "effector-react";
import {Button, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {ApplicationListItem} from "./application-list-item/application-list-item";
import "./application-list.css";

export const ApplicationList: FC = () => {
    useEffect(() => {
        getApplications();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getApplications();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const applications = useStore($applications);

    const currentDate = useMemo(() => {
        return dayjs().format("DD.MM.YYYY")
    }, []);

    if (applications === null) {
        return null;
    }

    return (
        <div className="row">
            <Stack direction="horizontal" className="content-header">
                <div className="title">
                    <div className="title-text">Актуальные заявки</div>
                    <div className="title-date">На {currentDate}</div>
                </div>
                <Stack direction="horizontal" className="controls" gap={3}>
                    <Link to={"/dashboard/applications/form"}>
                        <Button
                            className="button-desktop-visible"
                            variant="primary"
                        >
                            Новая заявка на полет
                        </Button>
                    </Link>
                </Stack>
            </Stack>
            {
                applications.length === 0
                    ? (
                        <div className="title-text">Заявок нет</div>
                    )
                    : (
                        <div className={`container_type_table`}>
                            <div className="list">
                                <div className="list-header">
                                    <div className="list-header-item min-w-75px px-0">Заявка</div>
                                    <div className="list-header-item min-w-250px">Направление</div>
                                    <div className="list-header-item min-w-250px">Тип перелета</div>
                                    <div className="list-header-item min-w-100px">Статус</div>
                                    <div className="list-header-item min-w-100px">Уведомления</div>
                                </div>
                                {
                                    applications.map((application) => (
                                        <ApplicationListItem
                                            key={application.id}
                                            application={application}
                                        />
                                    ))
                                }
                            </div>
                            <Link to={"/dashboard/applications/form"}>
                                <Button
                                    className="button-add button-mobile-visible"
                                    variant="primary"
                                >
                                    Новая заявка на полет
                                </Button>
                            </Link>
                        </div>
                    )
            }
        </div>
    );
};
