import { createEffect, createEvent, createStore, restore, forward, sample, combine } from "effector";
import {getMessagesHttp, TGetMessagesResponse} from "../http/get-messages.http";
import {IMessages} from "../types/application.types";
import { sendMessageHttp } from '../http/send-message.http';
import { getApplication } from './application.store';
import { attachFileToApplicationHttp } from '../http/attach-file-to-application.http';

export const getApplicationChatList = createEvent<IGetApplicationListProps>();
export const sendOneMessage = createEvent<IGetApplicationListProps>();
export const setApplicationMessage = createEvent<string>();
export const resetApplicationMessageForm = createEvent<void>();
export const setApplicationMessageFile = createEvent<FileList | null>();

export interface IGetApplicationListProps {
  id: number,
  airlineId: number
}

export const getApplicationListFx = createEffect<
    IGetApplicationListProps,
    TGetMessagesResponse
>(   ({id, airlineId}: IGetApplicationListProps) => {

    return getMessagesHttp(id, airlineId);
});

export const $allMessagesList = createStore<IMessages[] | null>(null)
    .on(getApplicationListFx.doneData, (messages, payload) => {return payload.messages})
    .on(getApplicationListFx.fail, () => null)
    .reset(resetApplicationMessageForm)

forward({
    from: getApplicationChatList,
    to: getApplicationListFx,
});

export const sendOneMessageFx = createEffect(async ({
  message,
  files,
  applicationId,
  airlineId,
}: any) => {
  if (message) {
    await sendMessageHttp(applicationId, airlineId, message);
    setApplicationMessage('')
  }
  if (files) {
    for (const file of Array.from(files as FileList)) {
      Promise.all([
        await attachFileToApplicationHttp(applicationId, file),
        await sendMessageHttp(applicationId, airlineId, `файл отправлен: ${file.name}`)
      ]).finally(() => {
        getApplication(applicationId);
      })
    }
  }
  setApplicationMessageFile(null);
});

export const $applicationMessage = restore<string>(
    setApplicationMessage,
    ""
)
    .reset(resetApplicationMessageForm);

export const $applicationMessageFile = restore<FileList | null>(
    setApplicationMessageFile,
    null,
)
    .reset(resetApplicationMessageForm);

sample({
  clock: sendOneMessage,
  source: combine({
    message: $applicationMessage,
    files: $applicationMessageFile
  }),
  target: sendOneMessageFx,
  fn: (source, {id: applicationId, airlineId}) => ({
    ...source,
    applicationId,
    airlineId,
  }),
});
