import {IFoodFormLeg} from "../store/food-form.store";
import {httpRequest} from "../../../shared/http/http-request/http-request";
import {dateToISOString} from "../../../shared/utils/dateUtils";

export const createFoodAppHttp = (
    food_legs: IFoodFormLeg[],
    comment: string,
): Promise<Response> => {
    return httpRequest(`food`, {
        method: "POST",
        body: JSON.stringify({
            food_legs: food_legs.map((food_leg) => ({
                ...food_leg,
                departure_datetime: food_leg.departure_datetime ? dateToISOString(food_leg.departure_datetime) : null,
                id: undefined,
            })),
            comment
        }),
    });
};
