import React, {FC} from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { Main } from "../main";
import {Dashboard} from "./dashboard/dashboard";
import {NoticeBubble} from "../notice-bubble/notice-bubble";
import {NonAuthLayout} from "./non-auth-layout/non-auth-layout";

export const App: FC = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={<Main />}
                    />
                    <Route
                        path="/dashboard/*"
                        element={<Dashboard />}
                    />
                    <Route
                        path="/*"
                        element={(
                            <NonAuthLayout />
                        )}
                    />
                </Routes>
            </BrowserRouter>
            <NoticeBubble />
        </>
    );
};
