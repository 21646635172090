import React, {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {useStore} from "effector-react";
import {$authorizedUser} from "../../../../domains/user/store/authorized-user.store";
import {EUserStatus, EUserType} from "../../../../domains/user/types/user.types";
import {PassingFlightsTeam} from "../../../../domains/passing-flights/views/passing-flights-team/passing-flights-team";
import {PassingFlightsAk} from "../../../../domains/passing-flights/views/passing-flights-ak/passing-flights-ak";

export const PassingFlights: FC = () => {
    const user = useStore($authorizedUser);

    if (user === null) {
        return null;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    (user.type === EUserType.TEAM || user.status === EUserStatus.SUPERVISOR) ?
                        (
                           <PassingFlightsTeam />
                        ) :
                        (
                            <PassingFlightsAk />
                        )
                }
            />
        </Routes>
    );
};
