import {combine, createEffect, createEvent, createStore, restore, sample} from "effector";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";
import {createApplicationNonAuthHttp} from "../http/create-application-non-auth.http";
import {attachFileToApplicationNonAuthHttp} from "../http/attach-file-to-application-non-auth.http";
import {saveUserSettingsFx} from "../../user/store/user-settings.store";

export interface IApplicationFormNonAuthLeg {
    id: number;
    departure_airport: number | null;
    arrival_airport: number | null;
    departure_datetime: Date | null;
    departure_back_datetime: Date | null;
    pax: number;
    business_pax: number;
    baggage: string;
    baggage_number: number;
}

export const applicationNonAuthFormSetName = createEvent<string>();
export const applicationNonAuthFormSetPhone = createEvent<string>();
export const applicationNonAuthFormSetEmail = createEvent<string>();
export const addApplicationFormNonAuthLeg = createEvent<void>();
export const changeApplicationFormNonAuthLeg = createEvent<IApplicationFormNonAuthLeg>();
export const setApplicationFormNonAuthIsNeedLoader = createEvent<boolean>();
export const setApplicationFormNonAuthIsNeedFood = createEvent<boolean>();
export const setApplicationFormNonAuthIsNeedManager = createEvent<boolean>();
export const setApplicationFormNonAuthIsNeedTransfer = createEvent<boolean>();
export const setApplicationFormNonAuthIsNeedPostPayment = createEvent<boolean>();
export const setApplicationFormNonAuthComment = createEvent<string>();
export const setApplicationFormNonAuthFlight = createEvent<string>();
export const setApplicationFormNonAuthFiles = createEvent<FileList>();
export const saveApplicationFormNonAuth = createEvent<void>();
export const resetApplicationFormNonAuth = createEvent<void>();


export const saveApplicationFormNonAuthFx = createEffect(async ({
    name,
    phone,
    email,
    legs,
    isNeedLoader,
    isNeedManager,
    isNeedTransfer,
    isNeedFood,
    isNeedPostPayment,
    comment,
    files,
    callback,
    flight,
}: any) => {

    const createApplicationResponse = await createApplicationNonAuthHttp(
        name,
        phone,
        email,
        legs,
        isNeedLoader,
        isNeedFood,
        isNeedManager,
        isNeedTransfer,
        isNeedPostPayment,
        comment,
        flight,
    );

    const applicationId = await createApplicationResponse.text();

    if (files) {
        for (const file of Array.from(files as FileList)) {
            attachFileToApplicationNonAuthHttp(applicationId, file);
        }
    }

    callback();
});

export const $applicationFormNonAuthLastLegId = createStore(0)
    .on(addApplicationFormNonAuthLeg, (store) => store + 1)
    .reset(resetApplicationFormNonAuth);

export const $applicationFormNonAuthLegs = createStore<IApplicationFormNonAuthLeg[]>([{
    id: 0,
    departure_airport: null,
    arrival_airport: null,
    departure_datetime: null,
    departure_back_datetime: null,
    pax: 0,
    business_pax: 0,
    baggage: "",
    baggage_number: 0,
}])
    .on($applicationFormNonAuthLastLegId, (store, payload) => {

        return [
            ...store,
            {
                id: payload,
                departure_airport: null,
                arrival_airport: null,
                departure_datetime: null,
                departure_back_datetime: null,
                pax: 0,
                business_pax: 0,
                baggage: "",
                baggage_number: 0,
            },
        ];
    })
    .on(changeApplicationFormNonAuthLeg, (store, payload) => {
        return store.map((currentLeg) => {
            if (currentLeg.id === payload.id) {
                return payload;
            }

            return currentLeg;
        })
    })
    .reset(resetApplicationFormNonAuth);

export const $appFormNonAuthName = restore(
    applicationNonAuthFormSetName,
    "",
)
    .reset(resetApplicationFormNonAuth);

export const $appFormNonAuthPhone = restore(
    applicationNonAuthFormSetPhone,
    "",
)
    .reset(resetApplicationFormNonAuth);

export const $appFormNonAuthEmail = restore(
    applicationNonAuthFormSetEmail,
    "",
)
    .reset(resetApplicationFormNonAuth);
export const $applicationFormNonAuthIsNeedLoader = restore<boolean>(
    setApplicationFormNonAuthIsNeedLoader,
    false,
)
    .reset(resetApplicationFormNonAuth);

export const $applicationFormNonAuthIsNeedFood = restore<boolean>(
    setApplicationFormNonAuthIsNeedFood,
    false,
)
    .reset(resetApplicationFormNonAuth);

export const $applicationFormNonAuthIsNeedManager = restore<boolean>(
    setApplicationFormNonAuthIsNeedManager,
    false,
)
    .reset(resetApplicationFormNonAuth);

export const $applicationFormNonAuthIsNeedTransfer = restore<boolean>(
    setApplicationFormNonAuthIsNeedTransfer,
    false,
)
    .reset(resetApplicationFormNonAuth);

export const $applicationFormNonAuthIsNeedPostPayment = restore<boolean>(
    setApplicationFormNonAuthIsNeedPostPayment,
    false,
)
    .reset(resetApplicationFormNonAuth);

export const $applicationFormNonAuthComment = restore<string>(
    setApplicationFormNonAuthComment,
    "",
)
    .reset(resetApplicationFormNonAuth);

export const $applicationFormNonAuthFlight = restore<string>(
    setApplicationFormNonAuthFlight,
    "ow"
)
    .reset(resetApplicationFormNonAuth);

export const $applicationFormNonAuthFiles = restore<FileList>(
    setApplicationFormNonAuthFiles,
    null,
)
    .reset(resetApplicationFormNonAuth);


sample({
    clock: saveApplicationFormNonAuth,
    source: combine({
        name: $appFormNonAuthName,
        phone: $appFormNonAuthPhone,
        email: $appFormNonAuthEmail,
        flight: $applicationFormNonAuthFlight,
        legs: $applicationFormNonAuthLegs,
        isNeedLoader: $applicationFormNonAuthIsNeedLoader,
        isNeedManager: $applicationFormNonAuthIsNeedManager,
        isNeedTransfer: $applicationFormNonAuthIsNeedTransfer,
        isNeedFood: $applicationFormNonAuthIsNeedFood,
        isNeedPostPayment: $applicationFormNonAuthIsNeedPostPayment,
        comment: $applicationFormNonAuthComment,
        files: $applicationFormNonAuthFiles,
    }),
    target: saveApplicationFormNonAuthFx,
    fn: (source, callback) => ({
        ...source,
        callback,
    }),
});

