import {combine, createEffect, createEvent, createStore, forward, restore, sample} from "effector";
import {editUserHttp} from "../http/edit-user.http";
import {getAuthorizedUserHttp, TGetAuthorizedUserHttpResponse} from "../http/get-authorized-user.http";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";
import {validateTelegram} from "../../../shared/validations/validations";
import {editUserLogoHttp} from "../http/edit-user-logo.http";

export const setUserSettingsName = createEvent<string>();
export const setUserSettingsPhone = createEvent<string>();
export const setUserSettingsTelegram = createEvent<string>();
export const setUserSettingsEmail = createEvent<string>();
export const setUserSettingsOrganizationName = createEvent<string>();
export const setUserSettingsGroupEmail = createEvent<string>();
export const setUserSettingsCharterEmail = createEvent<string>();
export const setUserSettingsSite = createEvent<string>();
export const setUserSettingLogo = createEvent<File | null>()
export const resetUserSettingsForm = createEvent<void>();
export const getUserSettings = createEvent<void>();
export const saveUserSettings = createEvent<void>();

export const getUserSettingsFx = createEffect<
    void,
    TGetAuthorizedUserHttpResponse
>(async () => {
    return getAuthorizedUserHttp();
});

export const saveUserSettingsFx = createEffect(async ({
    name,
    groupEmail,
    charterEmail,
    phone,
    telegram,
    organizationName,
    site,
    file
}: any) => {
    await editUserHttp(organizationName, groupEmail, charterEmail, name, phone, `@${telegram}`, site);

    if(file) {
        await editUserLogoHttp(file);
    }
    setUserSettingLogo(null);
});

export const $userSettingsName = restore(
    setUserSettingsName,
    "",
)
    .on(getUserSettingsFx.doneData, (_, {contact_name}) => contact_name)
    .reset(resetUserSettingsForm);

export const $userSettingsPhone = restore(
    setUserSettingsPhone,
    "",
)
    .on(getUserSettingsFx.doneData, (_, {phone}) => phone)
    .reset(resetUserSettingsForm);

export const $userSettingsTelegram = restore(
    setUserSettingsTelegram,
    ""
)
    .on(getUserSettingsFx.doneData, (_, {telegram}) => telegram.replace("@", ""))
    .reset(resetUserSettingsForm);

export const $userSettingsIsTelegramValid = createStore(false)
    .on($userSettingsTelegram, (_, userTelegram) => validateTelegram(userTelegram))
    .reset(resetUserSettingsForm);

export const $userSettingsEmail = restore(
    setUserSettingsEmail,
    "",
)
    .on(getUserSettingsFx.doneData, (_, {username}) => username)
    .reset(resetUserSettingsForm);

export const $userSettingsOrganizationName = restore(
    setUserSettingsOrganizationName,
    "",
)
    .on(getUserSettingsFx.doneData, (_, {organization_name}) => organization_name)
    .reset(resetUserSettingsForm);

export const $userSettingsGroupEmail = restore(
    setUserSettingsGroupEmail,
    ""
)
    .on(getUserSettingsFx.doneData, (_, {group_email}) => group_email)
    .reset(resetUserSettingsForm);
export const $userSettingsCharterEmail = restore(
    setUserSettingsCharterEmail,
    ""
)
    .on(getUserSettingsFx.doneData, (_, {charter_email}) => charter_email)
    .reset(resetUserSettingsForm);

export const $userSettingsSite = restore(
    setUserSettingsSite,
    "",
)
    .on(getUserSettingsFx.doneData, (_, {site}) => site)
    .reset(resetUserSettingsForm);

export const $userSettingsLogo = restore<File | null>(
    setUserSettingLogo,
    null,
)
    .reset(resetUserSettingsForm);

sample({
    clock: saveUserSettings,
    source: combine({
        name: $userSettingsName,
        groupEmail: $userSettingsGroupEmail,
        charterEmail: $userSettingsCharterEmail,
        phone: $userSettingsPhone,
        telegram: $userSettingsTelegram,
        email: $userSettingsEmail,
        organizationName: $userSettingsOrganizationName,
        site: $userSettingsSite,
        file: $userSettingsLogo,
    }),
    target: saveUserSettingsFx,
});

forward({
    from: getUserSettings,
    to: getUserSettingsFx,
});

saveUserSettingsFx.done.watch(() => {
    showNotice("primary", "Настройки успешно сохранены.");
});

saveUserSettingsFx.fail.watch(() => {
    showNotice("danger", "Во время сохранения настроек произошла ошибка. Повторите операцию позже.");
});
