import React, {FC, useCallback} from 'react';
import {Stack, Navbar, Nav, Carousel} from 'react-bootstrap';
import { Button } from '../../button';
import logo from './uploads/logo.svg';
import image1 from './uploads/image 1.png';
import image2 from './uploads/1.png';
import image3 from './uploads/2.png';
import image4 from './uploads/3.png';
import userIcon from "./uploads/user.svg";
import {useStore} from "effector-react";
import {$authorizedUser, logoutUser} from "../../../domains/user/store/authorized-user.store";
import {useNavigate, useSearchParams} from "react-router-dom";
import './start.css';

interface IProps {
    showSignForm(): void;
}

export const StartSection: FC<IProps> = ({
    showSignForm,
}) => {
    const navigate = useNavigate();
    const [_, setSearchParams] = useSearchParams();
    const user = useStore($authorizedUser);

    const sendApp = useCallback(() => {
        if (user) {
            navigate("/dashboard/applications/form")
        } else {
            navigate("/form");
        }
    }, [user, navigate]);

    const openAircraftList = useCallback(() => {
        if (user) {
            navigate("/dashboard/aircrafts");
        } else {
            navigate("/aircrafts");
        }
    }, [user, navigate]);

    const openMeals = useCallback(() => {
        if (user) {
            navigate("/dashboard/meals");
        } else {
            navigate("/meals");
        }
    }, [user, navigate]);

    const openEscort = useCallback(() => {
        if (user) {
            navigate("/dashboard/escort");
        } else {
            navigate("/escort");
        }
    }, [user, navigate]);

    const openPassingFlights = useCallback(() => {
        if (user) {
            navigate("/dashboard/passing-flights");
        } else {
            navigate("/passing-flights");
        }
    }, [user, navigate]);

    const openSeatBlocks = useCallback(() => {
        if (user) {
            navigate("/dashboard/seat-blocks");
        } else {
            navigate("/seat-blocks");
        }
    }, [user, navigate]);

    const openAirTickets = useCallback(() => {
        if (user) {
            navigate("/dashboard/air-tickets");
        } else {
            navigate("/air-tickets");
        }
    }, [user, navigate]);

    return (
        <section className="section start-section">
            <div className="d-flex justify-content-between">
                <div className="main-start-logo flex-grow-1">
                    <img src={logo} />
                </div>

                <Navbar collapseOnSelect expand="lg" className="flex-grow-2">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link className="start-link" href="#steps">Работа сервиса</Nav.Link>
                            <Nav.Link className="start-link" href="#about">О проекте</Nav.Link>
                            <Nav.Link className="start-link" href="#faq">F.A.Q</Nav.Link>
                            <Nav.Link className="start-link" href="#contacts">Контакты</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className='start-nav-right'>
                        {
                            user === null
                                ? (
                                    <>
                                        <Nav.Item className="start-user-menu-wrapper">
                                            <div onClick={showSignForm} className="start-user-menu">
                                                <img src={userIcon} alt="Header-logo" />
                                            </div>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Button
                                                variant="white"
                                                onClick={showSignForm}
                                            >
                                                Вход
                                            </Button>
                                        </Nav.Item>
                                    </>
                                )
                                : (
                                    <>
                                        <Nav.Item className="start-phone">
                                            {user.organization_name}
                                        </Nav.Item>
                                        <Nav.Item className="start-user-menu-wrapper">
                                            <div onClick={showSignForm} className="start-user-menu">
                                                <img src={userIcon} alt="Header-logo" />
                                            </div>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Button
                                                variant="white"
                                                onClick={() => logoutUser()}
                                            >
                                                Выход
                                            </Button>
                                        </Nav.Item>
                                    </>
                                )
                        }
                    </Nav>
                </Navbar>
            </div>

            <Stack className="start-head" direction="vertical" gap={5}>
                <Stack direction="vertical" gap={4}>
                    <h1 className="start-title">Онлайн-сервис бронирования<br/> и&nbsp;продаж чартерных авиаперевозок</h1>
                    <div className="start-text">В интересах спортивных лиг и состоящих в них клубов</div>
                </Stack>
                <Stack className="start-buttons" direction="horizontal" gap={3}>
                    <Button
                        className="btn-rounded"
                        variant="action"
                        onClick={sendApp}
                    >
                        Заказать чартер
                    </Button>
                    <Button
                        className="btn-rounded btn-action-light"
                        variant="primary"
                        onClick={openAircraftList}
                    >
                        Каталог самолетов
                    </Button>
                    <Button
                        className="btn-rounded btn-action-light"
                        variant="primary"
                        onClick={openPassingFlights}
                    >
                        Попутные рейсы
                    </Button>
                </Stack>
                <Stack className="start-buttons" direction="horizontal" gap={3}>
                    <Button
                        className="btn-rounded"
                        variant="primary"
                        onClick={openAirTickets}
                    >
                        Авиабилеты
                    </Button>
                    {/*<Button*/}
                    {/*    className="btn-rounded btn-primary-light"*/}
                    {/*    variant="primary"*/}
                    {/*    onClick={openMeals}*/}
                    {/*>*/}
                    {/*    Питание на борту*/}
                    {/*</Button>*/}
                    <Button
                        className="btn-rounded btn-primary-light"
                        variant="primary"
                        onClick={openEscort}
                    >
                        Сопровождение в полете
                    </Button>
                    <Button
                        className="btn-rounded btn-primary-light"
                        variant="primary"
                        onClick={openSeatBlocks}
                    >
                        Блоки мест
                    </Button>
                    <Button
                        className="btn-rounded"
                        variant="info"
                        onClick={() => {
                            window.open("https://hotels.globus.aero/");
                        }}
                    >
                        Гостиницы
                    </Button>
                </Stack>
            </Stack>

            <img src={image1} className="start-image" />

            <div className="start-clients">Наши партнеры</div>
            <div className="clients-list">
                {[image3, image4, image2].map((image) => (
                    <div key={image} className="clients-item">
                        <img src={image} alt="client" />
                    </div>
                ))}
            </div>
            <div className="clients-list-mobile">
                <Carousel
                    indicators={false}
                >
                    {[image3, image4, image2].map((image) => (
                        <Carousel.Item>
                            <div key={image} className="clients-item">
                                <img src={image} alt="client" />
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </section>
    );
};
