import React, {FC, useCallback, useEffect, useState} from "react";
import {StartSection} from "./start";
import {StepsSection} from "./steps";
import {ServiceSection} from "./service";
import {AboutSection} from "./about";
import {FaqSection} from "./faq";
import {FooterSection} from "./footer";

import './main.css';
import {UserSignForm} from "../../domains/user/views/user-sign-form/user-sign-form";
import {useStore} from "effector-react";
import {$authorizedUser, getAuthorizedUser} from "../../domains/user/store/authorized-user.store";
import {useNavigate, useSearchParams} from "react-router-dom";
import {EUserStatus} from "../../domains/user/types/user.types";

export const Main: FC = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [isSignFormVisible, setIsSignFormVisible] = useState(false);

    const airlineNotice = searchParams.get("airlineNotice");
    const authRedirect = searchParams.get("authRedirect");

    useEffect(() => {
        getAuthorizedUser(() => {});

        const emailConfirmToken = searchParams.get("email_confirm_token");

        if (emailConfirmToken || airlineNotice) {
            setIsSignFormVisible(true);
        }
    }, [airlineNotice]);

    useEffect(() => {
        if (authRedirect) {
            setIsSignFormVisible(true);
        }
    }, [authRedirect]);

    const user = useStore($authorizedUser);

    const showSignForm = useCallback(() => {
        if (user && user.status === EUserStatus.BROKER_CONFIRMED) {
            navigate("/dashboard/applications");
        } else {
            setIsSignFormVisible(true);
        }
    }, [user, navigate]);

    const hideSignForm = useCallback(() => {
        setIsSignFormVisible(false);
    }, []);

    return (
        <div className="main-layout">
            <StartSection showSignForm={showSignForm} />
            <StepsSection />
            <ServiceSection showSignForm={showSignForm} />
            <AboutSection />
            <FaqSection />
            <FooterSection />
            <UserSignForm
                isVisible={isSignFormVisible}
                hideSignForm={hideSignForm}
            />
        </div>
    )
}
