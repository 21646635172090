import React, {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {useStore} from "effector-react";
import {$authorizedUser} from "../../../../domains/user/store/authorized-user.store";
import {EUserStatus, EUserType} from "../../../../domains/user/types/user.types";
import {TeamList} from "../../../../domains/team/views/team-list/team-list";
import {GameCalendar} from "../../../../domains/team/views/game-calendar/game-calendar";

export const Teams: FC = () => {
    const user = useStore($authorizedUser);

    if (user === null) {
        return null;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    user.type === EUserType.TEAM && user.status === EUserStatus.SUPERVISOR ?
                        (
                            <TeamList />
                        ) :
                        (
                           <GameCalendar />
                        )
                }
            />
            <Route
                path="/:id"
                element={
                    user.type === EUserType.TEAM
                        && (
                            <GameCalendar />
                        )
                }
            />
        </Routes>
    );
};
