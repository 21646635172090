import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IAirline} from "../types/airline.types";

export type TGetAirlinesHttpResponse = IAirline[];

export const getAirlinesHttp = async (): Promise<TGetAirlinesHttpResponse> => {
    const getAirlinesResponse = await httpRequest("airlines");

    return getAirlinesResponse.json();
};
