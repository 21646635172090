import {combine, createEffect, createEvent, restore, sample} from "effector";
import {dateToISOString} from "../../../shared/utils/dateUtils";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";
import {
    createPassingFlightFormHttp,
} from "../http/create-passing-form.http";

export const passingFormSetDepartureAirport = createEvent<string>();
export const passingFormSetArrivalAirport = createEvent<string>();
export const passingFormSetDate = createEvent<Date>();
export const passingFormSetPlaces = createEvent<string>();
export const passingFormSetAircraft = createEvent<number>();
export const passingFormSetPrice = createEvent<string>();
export const passingFlightFormCreate = createEvent<void>();
export const resetPassingForm = createEvent<void>();


export const passingFlightFormCreateFx = createEffect(async (args: any) => {
    try {
        await createPassingFlightFormHttp(
            {
                "departure_airport": parseInt(args.departure, 10),
                "arrival_airport": parseInt(args.arrival, 10),
                "departure_datetime": args.date ? dateToISOString(args.date) : '',
                "places": args.places,
                "aircraft": args.aircraft,
                "price": args.price,
            }
        );
    } catch (e: unknown) {
        throw e;
    }
});

passingFlightFormCreateFx.fail.watch(() => {
    showNotice("danger", "Во время создания заявки произошла ошибка. Повторите операцию позже.");
});

export const $passingDepartureAirport = restore(
    passingFormSetDepartureAirport,
    "",
)
    .reset(resetPassingForm);

export const $passingArrivalAirport = restore(
    passingFormSetArrivalAirport,
    "",
)
    .reset(resetPassingForm);

export const $passingFormDate = restore(
    passingFormSetDate,
    null
)
    .reset(resetPassingForm);

export const $passingFormPlaces = restore(
    passingFormSetPlaces,
    ""
)
    .reset(resetPassingForm);

export const $passingFormAircraft = restore(
    passingFormSetAircraft,
    null
)
    .reset(resetPassingForm);

export const $passingFormPrice = restore(
    passingFormSetPrice,
    ""
)
    .reset(resetPassingForm);

sample({
    clock: passingFlightFormCreate,
    target: passingFlightFormCreateFx,
    source: combine({
        date: $passingFormDate,
        departure: $passingDepartureAirport,
        arrival: $passingArrivalAirport,
        places: $passingFormPlaces,
        aircraft: $passingFormAircraft,
        price: $passingFormPrice
    }),
});
