import {createEffect, createEvent, createStore, sample} from "effector";
import {getAircraftsHttp, TGetAircraftsHttpResponse} from "../http/get-aircrafts.http";
import {IAircraft} from "../types/aircraft.types";
import {$authorizedUser} from "../../user/store/authorized-user.store";
import {IUser} from "../../user/types/user.types";

export const getAircrafts = createEvent<void>();

export const getAircraftsFx = createEffect<
    number,
    TGetAircraftsHttpResponse
>((airlineId) => {
    return getAircraftsHttp(airlineId);
});

export const $aircrafts = createStore<IAircraft[] | null>(null)
    .on(getAircraftsFx.doneData, (_, payload) => payload)
    .on(getAircraftsFx.fail, () => null);

sample({
    clock: getAircrafts,
    target: getAircraftsFx,
    source: $authorizedUser,
    fn: (source) => (source as IUser).id,
    filter: (source): source is IUser => source !== null,
});
