import React, {FC, forwardRef, useCallback, useState} from "react";
import {FormLabel} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {useStore} from "effector-react";
import {
    $seatBlocksFormDate,
    $seatBlocksFormEmail,
    $seatBlocksFormName,
    $seatBlocksFormPhone,
    $seatBlocksModalIsVisible,
    seatBlocksFormSend,
    seatBlocksFormSetArrival,
    seatBlocksFormSetDate,
    seatBlocksFormSetDeparture,
    seatBlocksFormSetEmail,
    seatBlocksFormSetName,
    seatBlocksFormSetPhone,
    resetSeatBlocksForm,
    seatBlocksFormSetPassengerNumber,
    $seatBlocksPassengerNumber,
    seatBlocksFormBigLuggage,
    $seatBlocksBigLuggage,
    seatBlocksFormSetComment, $seatBlocksFormComment,
} from "../../../store/seat-blocks-form.store";
import warningIcon from "./warning.svg";
import {$airports} from "../../../../airport/store/airport.store";
import {Input} from "../../../../../components/input/input";
import {dateTimeToDMY} from "../../../../../shared/utils/dateUtils";
import {Button} from "../../../../../components/button";
import {TextArea} from "../../../../../components/textArea";
import {SearchSelect} from "../../../../../components/search-select/search-select";
import {Checkbox} from "../../../../../components/checkbox/checkbox";
import "./seat-blocks-form.css";
import {Text} from "../../../../../components/text";

export const SeatBlocksForm: FC = () => {
    const airports = useStore($airports);

    const comment = useStore($seatBlocksFormComment);
    const name = useStore($seatBlocksFormName);
    const phone = useStore($seatBlocksFormPhone);
    const email = useStore($seatBlocksFormEmail);
    const date = useStore($seatBlocksFormDate);
    const passengerNumber = useStore($seatBlocksPassengerNumber);
    const bigLuggage = useStore($seatBlocksBigLuggage);
    const modalIsVisible = useStore($seatBlocksModalIsVisible);

    const [isNameValid, setIsNameValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const validateAndSend = useCallback(() => {
        let isFormValid = true;

        if (
            name.length === 0 ||
            name.length > 70 ||
            !name
                .toLowerCase()
                .match(/^[a-zA-Zа-яА-Я]+\s*[a-zA-Zа-яА-Я]*$/)
        ) {
            setIsNameValid(false);
            isFormValid = false;
        }

        if (
            phone.length === 0 ||
            phone.length > 20 ||
            !phone
                .toLowerCase()
                .match(/^\+?\d+$/)
        ) {
            setIsPhoneValid(false);
            isFormValid = false;
        }

        if (
            !email
                .toLowerCase()
                .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ) {
            setIsEmailValid(false);
            isFormValid = false;
        }

        if (isFormValid) {
            seatBlocksFormSend();
        }
    }, [name, phone, email]);

    const DatePickerInput: any = forwardRef<HTMLInputElement>(({value, onClick, className}: any, ref) => {
        return (
            <Input
                className={className}
                variant="solid"
                value={value.length === 0 ? "" : dateTimeToDMY(value)}
                onChange={() => null}
                onClick={onClick}
                inputRef={ref}
            />
        );
    });

    return (
        <div className="row">
            {
                modalIsVisible && (
                    <div className="seat-blocks-form__overlay">
                        <div className="seat-blocks-form__modal">
                            <p className="seat-blocks-form__modal-title">Спасибо, заявка отправлена</p>
                            <p className="seat-blocks-form__modal-content">Наш менеджер свяжется с вами для уточнения деталей и
                                подбора вариантов исполнения.</p>
                            <Button
                                className="seat-blocks-form__modal-button"
                                onClick={() => {
                                    resetSeatBlocksForm();
                                    window.location.reload();
                                }}
                            >
                                ОК
                            </Button>
                        </div>
                    </div>
                )
            }
            <div className="title">
                <div className="title-under-text">
                    Заполните форму для запроса блока мест по Вашему расписанию и мы направим вам все подходящие предложения:
                </div>
            </div>
            <div className="card seat-blocks-form">
                <div className="p-12">
                    <h2 className="seat-blocks-form__header">Контактная информация</h2>
                    <div className="seat-blocks-form__row">
                        <div className="seat-blocks-form__column">
                            <div>
                                <div className="d-flex align-items-center">
                                    <FormLabel>Имя и фамилия</FormLabel>
                                    {
                                        !isNameValid && (
                                            <img className="mb-2 seat-blocks-form__invalid-field-icon" src={warningIcon} />
                                        )
                                    }
                                </div>
                                <TextArea
                                    className={isNameValid ? "" : "seat-blocks-form__invalid-field"}
                                    rows={1}
                                    value={name}
                                    onChange={(event) => {
                                        setIsNameValid(true);
                                        seatBlocksFormSetName(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="seat-blocks-form__column seat-blocks-form__contacts">
                            <div>
                                <div className="d-flex align-items-center">
                                    <FormLabel>Телефон</FormLabel>
                                    {
                                        !isPhoneValid && (
                                            <img className="mb-2 seat-blocks-form__invalid-field-icon" src={warningIcon} />
                                        )
                                    }
                                </div>
                                <TextArea
                                    className={isPhoneValid ? "" : "seat-blocks-form__invalid-field"}
                                    rows={1}
                                    value={phone}
                                    onChange={(event) => {
                                        setIsPhoneValid(true);
                                        seatBlocksFormSetPhone(event.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <div className="d-flex align-items-center">
                                    <FormLabel>Электронная почта</FormLabel>
                                    {
                                        !isEmailValid && (
                                            <img className="mb-2 seat-blocks-form__invalid-field-icon" src={warningIcon} />
                                        )
                                    }
                                </div>
                                <TextArea
                                    className={isEmailValid ? "" : "seat-blocks-form__invalid-field"}
                                    rows={1}
                                    value={email}
                                    onChange={(event) => {
                                        setIsEmailValid(true);
                                        seatBlocksFormSetEmail(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="escort-divider"></div>
                <div className="p-12">
                    <h2 className="seat-blocks-form__header">Информация о рейсе</h2>

                    <div className="seat-blocks-form__row mb-7">
                        <div className="seat-blocks-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Аэропорт вылета</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <SearchSelect
                                    name="departure_airport"
                                    placeholder="Выберите аэропорт"
                                    onChange={(event) => {
                                        seatBlocksFormSetDeparture(event);
                                    }}
                                >
                                    {
                                        airports?.map((airport) => (
                                            <option key={airport.id} value={airport.id}>
                                                {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                            </option>
                                        ))
                                    }
                                </SearchSelect>
                            </div>
                        </div>
                        <div className="seat-blocks-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Аэропорт прилета</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <SearchSelect
                                    name="arrival_airport"
                                    placeholder="Выберите аэропорт"
                                    onChange={(event) => {
                                        seatBlocksFormSetArrival(event);
                                    }}
                                >
                                    {
                                        airports?.map((airport) => (
                                            <option key={airport.id} value={airport.id}>
                                                {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                            </option>
                                        ))
                                    }
                                </SearchSelect>
                            </div>
                        </div>
                    </div>
                    <div className="seat-blocks-form__row mb-7">
                        <div className="seat-blocks-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Дата и время вылета</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <DatePicker
                                    selected={date}
                                    onChange={(value) => {
                                        seatBlocksFormSetDate(value);
                                    }}
                                    customInput={(
                                        <DatePickerInput />
                                    )}
                                    minDate={new Date()}
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    timeIntervals={10}
                                    showTimeSelect
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className="seat-blocks-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Количество пассажиров</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <TextArea
                                    rows={1}
                                    value={passengerNumber ? passengerNumber.toString(10) : ""}
                                    onChange={(event) => {
                                        if (event.target.value.length) {
                                            seatBlocksFormSetPassengerNumber(parseInt(event.target.value));
                                        } else {
                                            seatBlocksFormSetPassengerNumber(0);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="seat-blocks-form__row">
                        <div className="seat-blocks-form__column align-items-baseline">
                            <label className="seat-blocks-form__checkbox">
                                <Checkbox
                                    checked={bigLuggage}
                                    onChange={seatBlocksFormBigLuggage}
                                />
                                <Text type="text">
                                    Сверхнормативный багаж
                                </Text>
                            </label>
                        </div>
                        <div className="escort-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Комментарий</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <TextArea
                                    value={comment}
                                    onChange={(event) => {
                                        seatBlocksFormSetComment(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="seat-blocks-form__footer d-flex justify-content-end mt-11 align-items-center">
                <p className="seat-blocks-form__policy">
                    Нажимая кнопку «Отправить» вы подтверждаете, что ознакомились
                    с <a href="/rules.pdf" target="_blank">Пользовательское соглашение</a> компании и соглашаетесь с
                    ней.
                </p>
                <Button
                    className="seat-blocks-form__send"
                    onClick={() => {
                        validateAndSend();
                    }}
                >
                    Отправить
                </Button>
            </div>
        </div>
    );
};
