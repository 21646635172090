import React, {FC} from "react";
import {IGame} from "../../../types/team.type";
import {Input} from "../../../../../components/input/input";

interface IGameCalendarItemProps {
    game: IGame;
}

export const GameCalendarItem: FC<IGameCalendarItemProps> = ({
    game
}) => {

    const gameDate = game.game_date.split('T')[0];

    return <div className="game-calendar-item">
        <Input
            variant="solid"
            type="text"
            value={game.game_name}
            onChange={()=>{}}
        ></Input>
        <Input
            variant="solid"
            type="text"
            value={gameDate}
            onChange={()=>{}}
        ></Input>
    </div>
}
