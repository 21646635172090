import React, {FC, useCallback, useEffect, useState} from "react";
import {Text} from "../../../../../components/text";
import {Input} from "../../../../../components/input/input";
import {Button} from "../../../../../components/button";
import {useStore} from "effector-react";
import {
    $userCharterEmail, $userCharterEmailValid,
    $userGroupEmail, $userGroupEmailValid,
    $userRegistrationContactName,
    $userRegistrationIsContactNameValid,
    $userRegistrationIsOrganizationNameValid,
    $userRegistrationIsPasswordConfirmValid,
    $userRegistrationIsPasswordValid, $userRegistrationIsPhoneValid,
    $userRegistrationIsUsernameValid,
    $userRegistrationOrganizationName,
    $userRegistrationPassword,
    $userRegistrationPasswordConfirm,
    $userRegistrationPhone,
    $userRegistrationTelegram,
    // $userRegistrationIsTelegramValid,
    $userRegistrationType,
    $userRegistrationUsername,
    registerUser, registerUserFx,
    resetUserRegistrationForm, setUserCharterEmail, setUserGroupEmail,
    setUserRegistrationContactName,
    setUserRegistrationOrganizationName,
    setUserRegistrationPassword,
    setUserRegistrationPasswordConfirm,
    setUserRegistrationPhone,
    setUserRegistrationTelegram,
    setUserRegistrationType,
    setUserRegistrationUsername,
} from "../../../store/user-registration.store";
import {RadioBox} from "../../../../../components/radiobox/radio-box";
import "./user-sign-up-form.css";
import {Checkbox} from "../../../../../components/checkbox/checkbox";
import {EUserType} from "../../../types/user.types";

interface IProps {
    toAuthorization(): void;
}

export const UserSignUpForm: FC<IProps> = ({
    toAuthorization,
}) => {
    const [step, setStep] = useState(0);

    const [isFirstStepTouched, setIsFirstStepTouched] = useState(false);
    const [isSecondStepTouched, setIsSecondStepTouched] = useState(false);
    const [isUserTypeAirline, setIsUserTypeAirline] = useState(false);

    const [isEmailFree, setIsEmailFree] = useState(true);

    const [isAgreeWithPolicy, setIsAgreeWithPolicy] = useState(false);

    useEffect(() => {
        return () => {
            resetUserRegistrationForm();
        };
    }, []);

    const username = useStore($userRegistrationUsername);
    const isUsernameValid = useStore($userRegistrationIsUsernameValid);
    const password = useStore($userRegistrationPassword);
    const passwordConfirm = useStore($userRegistrationPasswordConfirm);
    const userTelegram = useStore($userRegistrationTelegram);
    // const isUserTelegramValid = useStore($userRegistrationIsTelegramValid);
    const isPasswordValid = useStore($userRegistrationIsPasswordValid);
    const isPasswordConfirmValid = useStore($userRegistrationIsPasswordConfirmValid);
    const contactName = useStore($userRegistrationContactName);
    const isContactNameValid = useStore($userRegistrationIsContactNameValid);
    const phone = useStore($userRegistrationPhone);
    const isPhoneValid = useStore($userRegistrationIsPhoneValid);
    const organizationName = useStore($userRegistrationOrganizationName);
    const isOrganizationNameValid = useStore($userRegistrationIsOrganizationNameValid);
    const userCharterEmail = useStore($userCharterEmail);
    const isUserCharterEmailValid = useStore($userCharterEmailValid);
    const userGroupEmail = useStore($userGroupEmail);
    const isUserGroupEmailValid = useStore($userGroupEmailValid);
    const type = useStore($userRegistrationType);

    const isRegistrationPending = useStore(registerUserFx.pending);

    const changeUsername = useCallback((event: any) => {
        setUserRegistrationUsername(event.target.value);
        setIsEmailFree(true);
    }, []);

    const changePassword = useCallback((event: any) => {
        setUserRegistrationPassword(event.target.value);
    }, []);

    const changePasswordConfirm = useCallback((event: any) => {
        setUserRegistrationPasswordConfirm(event.target.value);
    }, []);

    const changeNameTelegram = useCallback((event: any) => {
        setUserRegistrationTelegram(event.target.value);
    }, []);

    const changeContactName = useCallback((event: any) => {
        setUserRegistrationContactName(event.target.value);
    }, []);

    const changePhone = useCallback((event: any) => {
        setUserRegistrationPhone(event.target.value);
    }, []);

    const changeOrganizationName = useCallback((event: any) => {
        setUserRegistrationOrganizationName(event.target.value);
    }, []);

    const changeType = useCallback((type: any) => {
        setUserRegistrationType(type);
    }, []);

    const changeUserCharterEmail = useCallback((event: any) => {
        setUserCharterEmail(event.target.value);
    }, [])

    const changeUserGroupEmail = useCallback((event: any) => {
        setUserGroupEmail(event.target.value);
    }, [])

    const toSecondStep = useCallback(() => {
        setIsFirstStepTouched(true);

        if (
            isUsernameValid &&
            isPasswordValid &&
            // isUserTelegramValid &&
            isPasswordConfirmValid &&
            isAgreeWithPolicy
        ) {
            setStep(1);
        }
    }, [isUsernameValid, isPasswordValid, isPasswordConfirmValid, isAgreeWithPolicy]);

    const toThirdStep = useCallback(() => {
        setIsSecondStepTouched(true);

        if (isOrganizationNameValid && isContactNameValid && isPhoneValid) {
            setStep(2);
        }
    }, [isOrganizationNameValid, isContactNameValid, isPhoneValid]);

    const handleChangeAirlineType = useCallback(() => {
        changeType("airline");
        setIsUserTypeAirline(true);
    }, [changeType]);

    const handleChangeTeamType = useCallback(() => {
        changeType("team");
        setIsUserTypeAirline(false);
    }, [changeType]);

    const register = useCallback(() => {
        setIsSecondStepTouched(true);
        registerUser((response) => {
            if (response) {
                if (response.indexOf("exist") !== -1) {
                    setStep(0);
                    setIsEmailFree(false);
                } else if (response.indexOf("Internal") !== -1) {
                    setStep(3);
                } else {
                    toThirdStep();
                }
            }
        });
    }, [toThirdStep]);

    return (
        <>
            <Text type="title" className="user-sign-up-form-title">Регистрация</Text>
            {
                step === 0 && (
                    <React.Fragment key={1}>
                        <Text type="text" className="user-sign-up-form-label">Email</Text>
                        <Input value={username} onChange={changeUsername} />
                        {
                            isFirstStepTouched && !isUsernameValid && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Неверный формат email
                                </Text>
                            )
                        }
                        {
                            !isEmailFree && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Пользователь с таким email уже существует
                                </Text>
                            )
                        }
                        <Text type="text" className="user-sign-up-form-label">Пароль</Text>
                        <Input value={password} onChange={changePassword} type="password" />
                        {
                            isFirstStepTouched && !isPasswordValid && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Ваш пароль небезопасен. Используйте пароль, в котором:
                                    <ul>
                                        <li>Длина не менее 8 символов</li>
                                        <li>В пароле должны присутствовать строчные и заглавные буквы</li>
                                        <li>В пароле должна присутствовать хотя бы одна цифра</li>
                                    </ul>
                                </Text>
                            )
                        }
                        <Text type="text" className="user-sign-up-form-label">Подтвердите пароль</Text>
                        <Input value={passwordConfirm} onChange={changePasswordConfirm} type="password" />
                        {
                            isFirstStepTouched && !isPasswordConfirmValid && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Пароли не совпадают
                                </Text>
                            )
                        }
                        <Text type="text" className="user-sign-up-form-label">Ник в Telegram</Text>
                        <Input placeholder="@telegram" value={userTelegram} onChange={changeNameTelegram}></Input>
                        {/*{*/}
                        {/*    isFirstStepTouched && !isUserTelegramValid && (*/}
                        {/*        <Text type="text" className="user-sign-up-form-validation">*/}
                        {/*            <ul>*/}
                        {/*                <li>Допустим ввод симовлов a-z и нижнее подчеркивание</li>*/}
                        {/*                <li>Длина не менее 5 символов</li>*/}
                        {/*            </ul>*/}
                        {/*        </Text>*/}
                        {/*    )*/}
                        {/*}*/}
                        <div className="user-sign-up-form-policy">
                            <Checkbox
                                checked={isAgreeWithPolicy}
                                onChange={setIsAgreeWithPolicy}
                            >
                                Я согласен с <a target="_blank" href="/rules.pdf">пользовательским соглашением</a>
                            </Checkbox>
                        </div>
                        {
                            isFirstStepTouched && !isAgreeWithPolicy && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Примите пользовательское соглашение
                                </Text>
                            )
                        }
                        <div>
                            <Button onClick={toSecondStep} className="user-sign-up-form-auth-button">Зарегистрироваться</Button>
                        </div>
                        <div>
                            <Button onClick={toAuthorization} className="user-sign-up-form-reg-button" variant="grey">Авторизация</Button>
                        </div>
                    </React.Fragment>
                )
            }
            {
                step === 1 && (
                    <React.Fragment key={2}>
                        <Text type="text" className="user-sign-up-form-second-description">
                            Заполните оставшиеся поля, чтобы зарегистрироваться
                        </Text>
                        <Text type="text" className="user-sign-up-form-label">Укажите имя и фамилию</Text>
                        <Input value={contactName} onChange={changeContactName} />
                        {
                            isSecondStepTouched && !isContactNameValid && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Обязательное поле
                                </Text>
                            )
                        }
                        <Text type="text" className="user-sign-up-form-label">Номер телефона</Text>
                        <Input value={phone} onChange={changePhone} />
                        {
                            isSecondStepTouched && !phone && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Обязательное поле
                                </Text>
                            )
                        }
                        {
                            isSecondStepTouched && phone && !isPhoneValid && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Некорректный номер телефона
                                </Text>
                            )
                        }
                        <Text type="text" className="user-sign-up-form-label">Название организации</Text>
                        <Input value={organizationName} onChange={changeOrganizationName} />
                        {
                            isSecondStepTouched && !isOrganizationNameValid && (
                                <Text type="text" className="user-sign-up-form-validation">
                                    Обязательное поле
                                </Text>
                            )
                        }
                        <Text type="text" className="user-sign-up-form-label">Тип пользователя</Text>
                        <div className="user-sign-up-form-radio-group">
                            <RadioBox
                                label="Клубный аккаунт"
                                name="sign-up-form"
                                value={EUserType.TEAM}
                                onClick={() => handleChangeTeamType()}
                            />
                            <RadioBox
                                label="Авиакомпания"
                                name="sign-up-form"
                                value={EUserType.AIRLINE}
                                onClick={() => handleChangeAirlineType()}
                            />
                        </div>
                        {
                            isUserTypeAirline && (
                                <>
                                    <Text type="text" className="user-sign-up-form-label">Email чартерного отдела</Text>
                                    <Input value={userCharterEmail} onChange={changeUserCharterEmail} />
                                    {
                                        isSecondStepTouched && !isUserCharterEmailValid && (
                                            <Text type="text" className="user-sign-up-form-validation">
                                                Неверный формат email
                                            </Text>
                                        )
                                    }
                                    <Text type="text" className="user-sign-up-form-label">Email группового отдела</Text>
                                    <Input value={userGroupEmail} onChange={changeUserGroupEmail} />
                                    {
                                        isSecondStepTouched && !isUserGroupEmailValid && (
                                            <Text type="text" className="user-sign-up-form-validation">
                                                Неверный формат email
                                            </Text>
                                        )
                                    }
                                </>
                            )
                        }
                        <div>
                            <Button
                                onClick={register}
                                className="user-sign-up-form-auth-button"
                                disabled={isRegistrationPending}
                            >
                                Зарегистрироваться
                            </Button>
                        </div>
                    </React.Fragment>
                )
            }
            {
                step === 2 && (
                    <React.Fragment key={3}>
                        <Text type="text" className="user-sign-up-form-second-description">
                            Мы отправили письмо на указанный email. Пройдите по ссылке из письма для продолжения регистрации.
                        </Text>
                    </React.Fragment>
                )
            }
            {
                step === 3 && (
                    <React.Fragment key={4}>
                        <Text type="text" className="user-sign-up-form-second-description">
                            В данный момент регистрация недоступна из-за технических проблем. Пожалуйста, попробуйте позже
                        </Text>
                    </React.Fragment>
                )
            }
        </>
    );
};

