import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IPassingFlight} from "../types/passing-flight.types";

export type TGetPassingFlightsAkResponse = IPassingFlight[];

export const getPassingFlightAkHttp = async (): Promise<TGetPassingFlightsAkResponse> => {
    const getPassingFlightResponse = await httpRequest("passing-flights");

    return getPassingFlightResponse.json();
};
