import React, {FC, useCallback, useEffect} from "react";
import {Button, Card, FormLabel} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {FoodFormLeg} from "./food-form-leg/food-form-leg";
import {useStore} from "effector-react";
import {
    $foodFormComment,
    $foodFormLegs,
    resetFoodForm, saveFoodForm,
    saveFoodFormFx,
    setFoodFormComment,
    setFoodFormFiles
} from "../../store/food-form.store";
import {TextArea} from "../../../../components/textArea";
import {FilesInput} from "../../../../components/filesInput";

import {Text} from "../../../../components/text";

export const FoodForm: FC = () => {

    const navigate = useNavigate();

    const foodFormLegs = useStore($foodFormLegs);
    const foodFormComment = useStore($foodFormComment);
    const isFoodFormSaving = useStore(saveFoodFormFx.pending);

    useEffect(() => {
        return () => {
            resetFoodForm();
        };
    }, []);

    const saveFoodApp = useCallback(() => {
        saveFoodForm(() => {
            navigate("/dashboard/food-list");
        });
    }, [navigate]);

    return (
        <>
            <Card className="order">
                <>
                <div className="addOrder-top">
                    <Text type="subtitle" className="addOrder-title">Заказ питания</Text>
                    <div>
                        <Link to="/dashboard/food-list">
                            <Button className="addOrder-desktop" variant="link">{'<'} Актуальные заявки</Button>
                        </Link>
                    </div>
                </div>
                    {
                        foodFormLegs.map((foodLeg) => (
                            <FoodFormLeg
                                key={foodLeg.id}
                                foodLeg={foodLeg}
                            />))
                    }
                <div className="addOrder-line-offer addOrder-comments">
                    <div className="addOrder-half addOrder-half_vertical-comment">
                        <FormLabel>Комментарий</FormLabel>
                        <TextArea
                            value={foodFormComment}
                            onChange={(event) => {
                                setFoodFormComment(event.target.value);
                            }}
                        />
                    </div>
                    <div className="addOrder-half addOrder-half_vertical">
                        <FormLabel>Файлы</FormLabel>
                        <FilesInput
                            onChange={(files) => {
                                setFoodFormFiles(files);
                            }}
                        />
                    </div>
                </div>
                <Button
                    className="addOrder-submit addOrder-desktop"
                    variant="primary"
                    disabled={isFoodFormSaving}
                    onClick={saveFoodApp}>
                    Отправить заявку
                </Button>
                </>
            </Card>
            <div className="addOrder-Block addOrder-mobile">
                <Button
                    className="addOrder-submit"
                    variant="primary"
                    disabled={isFoodFormSaving}
                    onClick={saveFoodApp}>
                    Отправить заявку
                </Button>
            </div>
        </>
    )
}
