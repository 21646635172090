import React, { FC } from "react";
import { Accordion } from "react-bootstrap";

import './faq.css';

export const FaqSection: FC = () => {
    return (
        <section id="mainFaq" className="section faq-section">
            <div className="faq-name" id="faq">F.A.Q</div>
            <div className="faq-title">
                Вы спрашиваете,<br/>
                мы отвечаем
            </div>

            <Accordion defaultActiveKey="0">
                <Accordion.Item className="faq-item" eventKey="0">
                    <Accordion.Header className="faq-header">Кто может стать участником Сервиса?</Accordion.Header>
                    <Accordion.Body className="faq-body">
                        Участниками Сервиса могут стать представители спортивных лиг и команд с одной стороны и авиакомпаний, предлагающие чартерные перевозки на самолетах своего флота, с другой стороны. Доступ к Сервису можно получить только после проверки и подтверждения заявки на участие со стороны администратора Сервиса.                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faq-item" eventKey="1">
                    <Accordion.Header className="faq-header">Как начать использование Сервиса? Регистрация.</Accordion.Header>
                    <Accordion.Body className="faq-body">
                        Для того, чтобы получить доступ и стать участником Сервиса, необходимо зарегистрироваться, указав контактные данные и название организации. Администратор Сервиса свяжется с Вами для уточнения информации о Вашей организации, после чего вы получите доступ к услугам Сервиса.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faq-item" eventKey="2">
                    <Accordion.Header className="faq-header">Как оформить заявку на рейс и получить на нее предложения?</Accordion.Header>
                    <Accordion.Body className="faq-body">
                        В личном кабинете необходимо заполнить заявку на необходимый рейс и отправить ее Авиакомпаниям-партнерам Сервиса.  Далее на Ваш email будут поступать уведомления о предложениях от Авиакомпаний. Детали предложения можно посмотреть в личном кабинете Сервиса. Вам останется только выбрать лучшее.                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faq-item" eventKey="3">
                    <Accordion.Header className="faq-header">Что такое "Попутный рейс"?</Accordion.Header>
                    <Accordion.Body className="faq-body">
                        "Попутный рейс" - особо выгодное предложение от Авиакомпании, которая совершает пустой (без пассажиров) перелет по определенному маршруту. При покупке такого рейса, который может совпасть с необходимым Вам маршрутом, Вы можете сэкономить значительную сумму денег.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </section>
    )
}
