import {httpRequest} from "../../../shared/http/http-request/http-request";

export const recoverPasswordHttp = (
    username: string,
    password: string,
    token: string,
): Promise<Response> => {
    return httpRequest({
        path: "auth/reset-password",
        query: {},
    }, {
        method: "POST",
        body: JSON.stringify({
            username,
            password,
            token,
        }),
    });
};
