import { FC } from "react";

interface IProps {
    label: string;
    onClick: () => void;
    name: string;
    id?: string;
    checked?: boolean;
    value: string | number;
}

export const RadioBox: FC<IProps> = ({
    label,
    onClick,
    name,
    id,
    checked,
    value
}) => {
    return (
        <div onClick={onClick} className="form-check form-check-custom form-check-solid">
            <input
              name={name}
              value={value.toString()}
              id={id || value.toString()}
              className="form-check-input"
              type="radio"
              checked={checked} />
            <label htmlFor={value.toString()} className="form-check-label">
                {label}
            </label>
        </div>
    );
};
