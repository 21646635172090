import {combine, createEffect, createEvent, restore, sample} from "effector";
import {sendSupportFormHttp} from "../http/send-support-form.http";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";
import { dateToISOString } from '../../../shared/utils/dateUtils';

export const escortFormSetName = createEvent<string>();
export const escortFormSetPhone = createEvent<string>();
export const escortFormSetEmail = createEvent<string>();
export const escortFormSetFlightNumber = createEvent<string>();
export const escortFormSetDate = createEvent<Date>();
export const escortFormSetDeparture = createEvent<string>();
export const escortFormSetDepartureFastTrack = createEvent<boolean>();
export const escortFormSetDepartureLoader = createEvent<boolean>();
export const escortFormSetDepartureManager = createEvent<boolean>();
export const escortFormSetArrival = createEvent<string>();
export const escortFormSetArrivalFastTrack = createEvent<boolean>();
export const escortFormSetArrivalLoader = createEvent<boolean>();
export const escortFormSetArrivalManager = createEvent<boolean>();
export const escortFormSetComment = createEvent<string>();
export const escortFormSetFile = createEvent<FileList>();
export const escortFormSetModalIsVisible = createEvent<boolean>();
export const escortFormSend = createEvent<void>();
export const resetEscortForm = createEvent<void>();

export const escortFormSendFx = createEffect(async (args: any) => {
    try {
        await sendSupportFormHttp(
            {
                "name": args.name,
                "phone": args.phone,
                "email": args.email,
                "flight_no": args.flightNumber,
                "departure_datetime": args.date ? dateToISOString(args.date) : '',
                "notes": args.comment,
                "departure_airport": parseInt(args.departure, 10),
                "fast_track_dep": args.departureFastTrack,
                "porter_dep": args.departureLoader,
                "flight_manager_dep": args.departureManager,
                "arrival_airport": parseInt(args.arrival, 10),
                "fast_track_arr": args.arrivalFastTrack,
                "porter_arr": args.arrivalLoader,
                "flight_manager_arr": args.arrivalManager
            },
            args.file,
        );
    } catch (e: unknown) {
        throw e;
    }
});

escortFormSendFx.fail.watch(() => {
    showNotice("danger", "Во время создания заявки произошла ошибка. Повторите операцию позже.");
});

export const $escortFormName = restore(
    escortFormSetName,
    "",
)
    .reset(resetEscortForm);

export const $escortFormPhone = restore(
    escortFormSetPhone,
    "",
)
    .reset(resetEscortForm);

export const $escortFormEmail = restore(
    escortFormSetEmail,
    "",
)
    .reset(resetEscortForm);

export const $escortFormFlightNumber = restore(
    escortFormSetFlightNumber,
    "",
)
    .reset(resetEscortForm);

export const $escortFormDate = restore(
    escortFormSetDate,
    null,
)
    .reset(resetEscortForm);

export const $escortFormDeparture = restore(
    escortFormSetDeparture,
    "",
)
    .reset(resetEscortForm);

export const $escortFormDepartureFastTrack = restore(
    escortFormSetDepartureFastTrack,
    false,
)
    .reset(resetEscortForm);

export const $escortFormDepartureLoader = restore(
    escortFormSetDepartureLoader,
    false,
)
    .reset(resetEscortForm);

export const $escortFormDepartureManager = restore(
    escortFormSetDepartureManager,
    false,
)
    .reset(resetEscortForm);

export const $escortFormArrival = restore(
    escortFormSetArrival,
    "",
)
    .reset(resetEscortForm);

export const $escortFormArrivalFastTrack = restore(
    escortFormSetArrivalFastTrack,
    false,
)
    .reset(resetEscortForm);

export const $escortFormArrivalLoader = restore(
    escortFormSetArrivalLoader,
    false,
)
    .reset(resetEscortForm);

export const $escortFormArrivalManager = restore(
    escortFormSetArrivalManager,
    false,
)
    .reset(resetEscortForm);

export const $escortFormComment = restore(
    escortFormSetComment,
    "",
)
    .reset(resetEscortForm);

export const $escortFormFile = restore(
    escortFormSetFile,
    null,
)
    .reset(resetEscortForm);

export const $escortModalIsVisible = restore(
    escortFormSetModalIsVisible,
    false,
)
    .on(escortFormSendFx.done, () => true)
    .reset(resetEscortForm);

sample({
    clock: escortFormSend,
    target: escortFormSendFx,
    source: combine({
        name: $escortFormName,
        phone: $escortFormPhone,
        email: $escortFormEmail,
        flightNumber: $escortFormFlightNumber,
        date: $escortFormDate,
        departure: $escortFormDeparture,
        departureFastTrack: $escortFormDepartureFastTrack,
        departureLoader: $escortFormDepartureLoader,
        departureManager: $escortFormDepartureManager,
        arrival: $escortFormArrival,
        arrivalFastTrack: $escortFormArrivalFastTrack,
        arrivalLoader: $escortFormArrivalLoader,
        arrivalManager: $escortFormArrivalManager,
        comment: $escortFormComment,
        file: $escortFormFile,
    }),
});
