import React, {FC} from "react";
import dayjs from "dayjs";
import {useStore} from "effector-react";
import {$airports} from "../../../../airport/store/airport.store";
import "./offer-list-item.css";
import {Link} from "react-router-dom";
import {IOffer} from "../../../types/offer.types";

interface IProps {
    offer: IOffer;
}

export const OfferListItem: FC<IProps> = ({
    offer,
}) => {
    const airports = useStore($airports);

    const departureAirport = airports?.find((airport) => {
        return airport.id === offer.legs[0].departure_airport;
    });

    const arrivalAirport = airports?.find((airport) => {
        return airport.id === offer.legs[0].arrival_airport;
    });

    return (
        <Link className={`item item_type_card`} to={`/dashboard/applications/${offer.id}`}>
            <div className="item-cell item-number">
                <div className="item-number-status">
                    <span>
                        <span className="item-number-card item-number-table-mobile">
                            {offer.team.organization_name}
                        </span>
                    </span>
                    <div className="item-number-card item-number-table-mobile item-number-value">
                        <div className={`status status_type_offered`}>{offer.status}</div>
                    </div>
                </div>
                <div className="item-number-price item-number-mobile">
                    <div className="item-number-title">Стоимость</div>
                    <div className="item-number-value">
                        {
                            offer.price
                        }
                        {" ₽"}
                    </div>
                </div>
            </div>
            <div className="item-cell item-plane">
                <div className="plane-direction">
                    <div className="item-path-name">
                        {departureAirport?.city_rus} — {arrivalAirport?.city_rus}
                    </div>
                    <div className="item-path-date">
                        {dayjs(offer.legs[0].departure_datetime).format("D MMMM в H:mm")}
                    </div>
                </div>
            </div>
            <div className="item-points">
                <div className="item-point">
                    <div className="item-point-name">Вылет</div>
                    <div className="item-point-city">
                        {departureAirport?.name} {departureAirport?.iata}
                    </div>
                    <div className="item-point-about">
                        {departureAirport?.city_rus} в {dayjs(offer.legs[0].departure_datetime).format("H:mm")}
                    </div>
                </div>
                <div className="item-point">
                    <div className="item-point-name">Прилет</div>
                    <div className="item-point-city">
                        {arrivalAirport?.name} {arrivalAirport?.iata}
                    </div>
                    <div className="item-point-about">
                        {arrivalAirport?.city_rus}
                    </div>
                </div>
                <div className="item-point item-point-card-desktop">
                    <div className="item-point-name">Стоимость</div>
                    <div className="item-point-city">
                        {
                            offer.price
                        }
                        {" ₽"}
                    </div>
                </div>
                <div className="item-point item-point-card-desktop">
                    <div className="item-point-name">Уведомления</div>
                    <div className="item-point-city">
                        {offer.new_messages && (
                            <div className="item-point-city">
                                Новое сообщение
                            </div>)}
                    </div>
                </div>
            </div>
        </Link>
    );
};
