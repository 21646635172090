import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IFullAircraft} from "../types/full-aircraft.types";

export type TGetAllAircraftsHttpResponse = IFullAircraft[];

export const getAllAircraftsHttp = async (): Promise<TGetAllAircraftsHttpResponse> => {
    const getAircraftsResponse = await httpRequest("aircrafts");

    return getAircraftsResponse.json();
};
