export const validateEmail = (email: string): boolean => {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        .test(email);
};

export const validateTelegram = (telegram: string): boolean => {
    return /.*\B(?=\w{4,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/i
        .test(telegram);
}

export const validatePhone = (phone: string): boolean => {
    return /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/i
        .test(phone);
}

export const validatePassword = (
    password: string,
    passwordConfirm?: string,
): boolean => {
    if (passwordConfirm) {
        if (password !== passwordConfirm) {
            return false;
        }
    }

    if (password.length < 8) {
        return false;
    }

    const isContainsUppercase = /[A-Z]/.test(password);
    const isContainsLowercase = /[a-z]/.test(password);
    const isContainsNumber = /\d/.test(password);

    return (
        isContainsUppercase &&
        isContainsLowercase &&
        isContainsNumber
    );
}

export const validatePasswordConfirm = (
    password: string,
    passwordConfirm: string,
): boolean => {
    return passwordConfirm === password;
}

export const required = (value: string): boolean => {
    return value.length > 0;
}
