import React, {FC, useCallback, useEffect, useState} from "react";
import {Card, FormLabel} from "react-bootstrap";
import {ApplicationFormNonAuthLeg} from "../application-form-leg/application-form-leg-non-auth";
import ApplicationFormServices from "../application-form-services/application-form-services";
import {Button} from "../../../../../components/button";
import {useStore} from "effector-react";
import {Text} from "../../../../../components/text";
import {TextArea} from "../../../../../components/textArea";
import {FilesInput} from "../../../../../components/filesInput";
import "../../application-form/application-form.css";
import trip from "../application-form-leg/trip.svg";
import roundTrip from "../application-form-leg/roundTrip.svg";
import difficultFlight from "../application-form-leg/difficultFlight.svg";
import warningIcon from "../../../../escort/views/escort-form/warning.svg";
import {
    $appFormNonAuthEmail,
    $appFormNonAuthName,
    $appFormNonAuthPhone,
    $applicationFormNonAuthComment,
    $applicationFormNonAuthFlight,
    $applicationFormNonAuthLegs,
    applicationNonAuthFormSetEmail,
    applicationNonAuthFormSetName,
    applicationNonAuthFormSetPhone,
    resetApplicationFormNonAuth,
    saveApplicationFormNonAuth,
    saveApplicationFormNonAuthFx, setApplicationFormNonAuthComment, setApplicationFormNonAuthFiles,
    setApplicationFormNonAuthFlight
} from "../../../store/application-form-non-auth.store";
import {useNavigate} from "react-router-dom";
import {resetApplicationForm} from "../../../store/application-form.store";

export const ApplicationFormNonAuth: FC = () => {

    const applicationFormNonAuthLegs = useStore($applicationFormNonAuthLegs);
    const applicationFormNonAuthComment = useStore($applicationFormNonAuthComment);
    const isApplicationFormNonAutSaving = useStore(saveApplicationFormNonAuthFx.pending);
    const flight = useStore($applicationFormNonAuthFlight);
    const name = useStore($appFormNonAuthName);
    const phone = useStore($appFormNonAuthPhone);
    const email = useStore($appFormNonAuthEmail);

    const [isNameValid, setIsNameValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [modalIsVisible, setModalIsVisible] = useState(false);

    useEffect(() => {
        return () => {
            resetApplicationFormNonAuth();
        };
    }, []);

    const validateAndSend = useCallback(() => {
        let isFormValid = true;

        if (
            name.length === 0 ||
            name.length > 70 ||
            !name
                .toLowerCase()
                .match(/^[a-zA-Zа-яА-Я]+\s*[a-zA-Zа-яА-Я]*$/)
        ) {
            setIsNameValid(false);
            isFormValid = false;
        }

        if (
            phone.length === 0 ||
            phone.length > 20 ||
            !phone
                .toLowerCase()
                .match(/^\+?\d+$/)
        ) {
            setIsPhoneValid(false);
            isFormValid = false;
        }

        if (
            !email
                .toLowerCase()
                .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ) {
            setIsEmailValid(false);
            isFormValid = false;
        }

        if (isFormValid) {
            saveApplicationFormNonAuth();
            resetApplicationFormNonAuth();
            setModalIsVisible(true);
        }
    }, [name, phone, email]);

    return (
        <>
            <Card className="addOrder">
                {
                    modalIsVisible && (
                        <div className="escort-form__overlay">
                            <div className="escort-form__modal">
                                <p className="escort-form__modal-title">Спасибо, заявка отправлена</p>
                                <p className="escort-form__modal-content">Наш менеджер свяжется с вами для уточнения деталей и
                                    подбора вариантов исполнения.</p>
                                <Button
                                    className="escort-form__modal-button"
                                    onClick={() => {
                                        resetApplicationFormNonAuth();
                                        window.location.reload();
                                    }}
                                >
                                    ОК
                                </Button>
                            </div>
                        </div>
                    )
                }
                <h2 className="escort-form__header">Контактная информация</h2>
                <div className="escort-form__row">
                    <div className="escort-form__column">
                        <div>
                            <div className="d-flex align-items-center">
                                <FormLabel>Имя и фамилия</FormLabel>
                                {
                                    !isNameValid && (
                                        <img className="mb-2 escort-form__invalid-field-icon" src={warningIcon} />
                                    )
                                }
                            </div>
                            <TextArea
                                className={isNameValid ? "" : "escort-form__invalid-field"}
                                rows={1}
                                value={name}
                                onChange={(event) => {
                                    setIsNameValid(true);
                                    applicationNonAuthFormSetName(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="escort-form__column escort-form__contacts">
                        <div>
                            <div className="d-flex align-items-center">
                                <FormLabel>Телефон</FormLabel>
                                {
                                    !isPhoneValid && (
                                        <img className="mb-2 escort-form__invalid-field-icon" src={warningIcon} />
                                    )
                                }
                            </div>
                            <TextArea
                                className={isPhoneValid ? "" : "escort-form__invalid-field"}
                                rows={1}
                                value={phone}
                                onChange={(event) => {
                                    setIsPhoneValid(true);
                                    applicationNonAuthFormSetPhone(event.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <div className="d-flex align-items-center">
                                <FormLabel>Электронная почта</FormLabel>
                                {
                                    !isEmailValid && (
                                        <img className="mb-2 escort-form__invalid-field-icon" src={warningIcon} />
                                    )
                                }
                            </div>
                            <TextArea
                                className={isEmailValid ? "" : "escort-form__invalid-field"}
                                rows={1}
                                value={email}
                                onChange={(event) => {
                                    setIsEmailValid(true);
                                    applicationNonAuthFormSetEmail(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <div className="escort-divider"></div>
            <Card className="addOrder">
                <div className="addOrder-top">
                    <Text type="subtitle" className="addOrder-title">Укажите маршрут</Text>
                </div>
                <div className="addOrder-line-offer">
                    <div className="addOrder-half addOrder-half_vertical">
                        <FormLabel>Тип перелета</FormLabel>
                        <div className="btn-select-flight">
                            <input
                                className="btn-trip-radio"
                                type="radio"
                                name="flight"
                                id="ow"
                                checked={flight === "ow"}
                                onClick={() => {
                                    setApplicationFormNonAuthFlight("ow");
                                }}
                            />
                            <label
                                htmlFor="ow"
                                className="airplane-icon-label">
                                <img src={trip} alt="one_way_trip"/>
                            </label>
                            <input
                                className="btn-trip-radio"
                                type="radio"
                                name="flight"
                                id="rt"
                                checked={flight === "rt"}
                                onClick={() => {
                                    setApplicationFormNonAuthFlight("rt");
                                }}
                            />
                            <label
                                htmlFor="rt"
                                className="airplane-icon-label">
                                <img src={roundTrip} alt="round_trip"/>
                            </label>
                            <input
                                className="btn-trip-radio"
                                type="radio"
                                name="flight"
                                id="cf"
                                checked={flight === "cf"}
                                onClick={() => {
                                    setApplicationFormNonAuthFlight("cf");
                                }}
                            />
                            <label
                                htmlFor="cf"
                                className="airplane-icon-label">
                                <img src={difficultFlight} alt="difficult_flight"/>
                            </label>
                        </div>
                    </div>
                    <div className="addOrder-half addOrder-half_vertical">
                        <FormLabel>Тип рейса</FormLabel>
                        <Text
                            type="subtitle" className="addOrder-title"
                        >Чартер</Text>
                    </div>
                </div>
                {
                    applicationFormNonAuthLegs.map((leg) => (
                        <ApplicationFormNonAuthLeg
                            key={leg.id}
                            leg={leg}
                        />
                    ))
                }
                <div className="addOrder-separator" />
                <Text type="subtitle" className="addOrder-title addOrder-secondary">Услуги</Text>
                <ApplicationFormServices />
                <div className="addOrder-line-offer addOrder-comments">
                    <div className="addOrder-half addOrder-half_vertical-comment">
                        <FormLabel>Комментарий</FormLabel>
                        <TextArea
                            value={applicationFormNonAuthComment}
                            onChange={(event) => {
                                setApplicationFormNonAuthComment(event.target.value);
                            }}
                        />
                    </div>
                    <div className="addOrder-half addOrder-half_vertical">
                        <FormLabel>Файлы</FormLabel>
                        <FilesInput
                            onChange={(files) => {
                                setApplicationFormNonAuthFiles(files);
                            }}
                        />
                    </div>
                </div>
                <div className="addOrder-separator" />
                <Button
                    className="addOrder-submit addOrder-desktop"
                    variant="primary"
                    disabled={isApplicationFormNonAutSaving}
                    onClick={validateAndSend}>
                    Отправить заявку в авиакомании
                </Button>
            </Card>
            <div className="addOrder-Block addOrder-mobile">
                <Button
                    className="addOrder-submit"
                    variant="primary"
                    disabled={isApplicationFormNonAutSaving}
                    onClick={validateAndSend}>
                    Отправить заявку в авиакомании
                </Button>
            </div>
        </>
    );
};
