import {FC, PropsWithChildren, useCallback} from "react";

interface IProps {
    id?: string;
    checked: boolean;
    onChange(checked: boolean): void;
}

export const Checkbox: FC<PropsWithChildren<IProps>> = ({
    id,
    checked,
    onChange,
    children,
}) => {
    const toggle = useCallback(() => {
        onChange(!checked);
    }, [checked, onChange]);

    return (
        <div onClick={toggle} className="form-check form-check-custom form-check-solid">
            <input id={id} checked={checked} className="form-check-input" type="checkbox"/>
            <label className="form-check-label">
                {children}
            </label>
        </div>
    );
};
