import React, { FC } from "react";
import {Stack, Carousel} from "react-bootstrap";

import step1 from './uploads/step1.png'
import step2 from './uploads/step2.png'
import step3 from './uploads/step3.png'

import './steps.css';

export const StepsSection: FC = () => {
    return (
        <section className="section steps-section">
            <h2 className="steps-title" id="steps">Как устроена работа сервиса в трёх шагах</h2>
            <Carousel controls={false} className="steps-list-mobile">
                <Carousel.Item>
                    <div className="steps-card">
                        <div className="steps-card-number">1</div>
                        <div className="steps-card-image">
                            <img src={step1} alt="Команда"/>
                        </div>
                        <div className="steps-card-about">
                            <div className="steps-card-title">Команда</div>
                            <div className="steps-card-text">
                                Спортивная организация, которой необходимо обеспечить логистику, согласно календарю игр Лиги.
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="steps-card">
                        <div className="steps-card-number">2</div>
                        <div className="steps-card-image">
                            <img src={step2} alt="Авиакомпания" />
                        </div>
                        <div className="steps-card-about">
                            <div className="steps-card-title">Авиакомпания</div>
                            <div className="steps-card-text">
                                Организация, обладающая свидетельством эксплуатанта и собственным парком воздушных судов.                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="steps-card">
                        <div className="steps-card-number">3</div>
                        <div className="steps-card-image">
                            <img src={step3} alt="Globus" />
                        </div>
                        <div className="steps-card-about">
                            <div className="steps-card-title">Globus</div>
                            <div className="steps-card-text">
                                Управление платформой
                                и обеспечение соопутствующих перевозке дополнительных услуг
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
            <Stack className="steps-list" direction="horizontal">
                <div className="steps-card">
                    <div className="steps-card-number">1</div>
                    <div className="steps-card-image">
                        <img src={step1} alt="Команда"/>
                    </div>
                    <div className="steps-card-about">
                        <div className="steps-card-title">Команда</div>
                        <div className="steps-card-text">
                            Спортивная организация, которой необходимо обеспечить логистику, согласно календарю игр Лиги.
                        </div>
                    </div>
                </div>

                <div className="steps-card">
                    <div className="steps-card-number">2</div>
                    <div className="steps-card-image">
                        <img src={step2} alt="Авиакомпания" />
                    </div>
                    <div className="steps-card-about">
                        <div className="steps-card-title">Авиакомпания</div>
                        <div className="steps-card-text">
                            Организация, обладающая свидетельством эксплуатанта и собственным парком воздушных судов.                        </div>
                    </div>
                </div>

                <div className="steps-card">
                    <div className="steps-card-number">3</div>
                    <div className="steps-card-image">
                        <img src={step3} alt="Globus" />
                    </div>
                    <div className="steps-card-about">
                        <div className="steps-card-title">Globus</div>
                        <div className="steps-card-text">
                            Управление платформой
                            и обеспечение соопутствующих перевозке дополнительных услуг
                        </div>
                    </div>
                </div>
            </Stack>
        </section>
    )
}
