import React, {FC, forwardRef, useCallback, useState} from "react";
import "./escort-form.css";
import {FormLabel} from "react-bootstrap";
import {TextArea} from "../../../../components/textArea";
import DatePicker from "react-datepicker";
import {Input} from "../../../../components/input/input";
import {dateTimeToDMY} from "../../../../shared/utils/dateUtils";
import {SearchSelect} from "../../../../components/search-select/search-select";
import {useStore} from "effector-react";
import {$airports} from "../../../airport/store/airport.store";
import {FilesInput} from "../../../../components/filesInput";
import {Checkbox} from "../../../../components/checkbox/checkbox";
import {Text} from "../../../../components/text";
import {Button} from "../../../../components/button";
import {
    $escortFormArrivalFastTrack,
    $escortFormArrivalLoader,
    $escortFormArrivalManager,
    $escortFormComment,
    $escortFormDate,
    $escortFormDepartureFastTrack,
    $escortFormDepartureLoader,
    $escortFormDepartureManager,
    $escortFormEmail,
    $escortFormFlightNumber,
    $escortFormName,
    $escortFormPhone,
    $escortModalIsVisible,
    escortFormSend,
    escortFormSetArrival, escortFormSetArrivalFastTrack, escortFormSetArrivalLoader, escortFormSetArrivalManager,
    escortFormSetComment,
    escortFormSetDate,
    escortFormSetDeparture,
    escortFormSetDepartureFastTrack,
    escortFormSetDepartureLoader,
    escortFormSetDepartureManager,
    escortFormSetEmail,
    escortFormSetFile,
    escortFormSetFlightNumber,
    escortFormSetName,
    escortFormSetPhone, resetEscortForm
} from "../../store/escort-form.store";
import warningIcon from "./warning.svg";

export const EscortForm: FC = () => {
    const airports = useStore($airports);

    const name = useStore($escortFormName);
    const phone = useStore($escortFormPhone);
    const email = useStore($escortFormEmail);
    const flightNumber = useStore($escortFormFlightNumber);
    const date = useStore($escortFormDate);
    const comment = useStore($escortFormComment);
    const departureFastTrack = useStore($escortFormDepartureFastTrack);
    const departureLoader = useStore($escortFormDepartureLoader);
    const departureManager = useStore($escortFormDepartureManager);
    const arrivalFastTrack = useStore($escortFormArrivalFastTrack);
    const arrivalLoader = useStore($escortFormArrivalLoader);
    const arrivalManager = useStore($escortFormArrivalManager);
    const modalIsVisible = useStore($escortModalIsVisible);

    const [isNameValid, setIsNameValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const validateAndSend = useCallback(() => {
        let isFormValid = true;

        if (
            name.length === 0 ||
            name.length > 70 ||
            !name
                .toLowerCase()
                .match(/^[a-zA-Zа-яА-Я]+\s*[a-zA-Zа-яА-Я]*$/)
        ) {
            setIsNameValid(false);
            isFormValid = false;
        }

        if (
            phone.length === 0 ||
            phone.length > 20 ||
            !phone
                .toLowerCase()
                .match(/^\+?\d+$/)
        ) {
            setIsPhoneValid(false);
            isFormValid = false;
        }

        if (
            !email
                .toLowerCase()
                .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ) {
            setIsEmailValid(false);
            isFormValid = false;
        }

        if (isFormValid) {
            escortFormSend();
        }
    }, [name, phone, email]);

    const DatePickerInput = forwardRef<HTMLInputElement>(({value, onClick}: any, ref) => {
        return (
            <Input
                variant="solid"
                value={value.length === 0 ? "" : dateTimeToDMY(value)}
                onChange={() => null}
                onClick={onClick}
                inputRef={ref}
            />
        );
    });

    return (
        <div className="row">
            {
                modalIsVisible && (
                    <div className="escort-form__overlay">
                        <div className="escort-form__modal">
                            <p className="escort-form__modal-title">Спасибо, заявка отправлена</p>
                            <p className="escort-form__modal-content">Наш менеджер свяжется с вами для уточнения деталей и
                                подбора вариантов исполнения.</p>
                            <Button
                                className="escort-form__modal-button"
                                onClick={() => {
                                    resetEscortForm();
                                    window.location.reload();
                                }}
                            >
                                ОК
                            </Button>
                        </div>
                    </div>
                )
            }
            <div className="title">
                <div className="title-text mb-4">Сопровождение в полете</div>
                <div className="title-under-text mb-3">
                    Заполните форму, указав любые необходимые услуги, не входящие в стандартный набор авиакомпании при
                    покупке билета.
                </div>
            </div>
            <div className="card escort-form">
                <div className="p-12">
                    <h2 className="escort-form__header">Контактная информация</h2>
                    <div className="escort-form__row">
                        <div className="escort-form__column">
                            <div>
                                <div className="d-flex align-items-center">
                                    <FormLabel>Имя и фамилия</FormLabel>
                                    {
                                        !isNameValid && (
                                            <img className="mb-2 escort-form__invalid-field-icon" src={warningIcon} />
                                        )
                                    }
                                </div>
                                <TextArea
                                    className={isNameValid ? "" : "escort-form__invalid-field"}
                                    rows={1}
                                    value={name}
                                    onChange={(event) => {
                                        setIsNameValid(true);
                                        escortFormSetName(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="escort-form__column escort-form__contacts">
                            <div>
                                <div className="d-flex align-items-center">
                                    <FormLabel>Телефон</FormLabel>
                                    {
                                        !isPhoneValid && (
                                            <img className="mb-2 escort-form__invalid-field-icon" src={warningIcon} />
                                        )
                                    }
                                </div>
                                <TextArea
                                    className={isPhoneValid ? "" : "escort-form__invalid-field"}
                                    rows={1}
                                    value={phone}
                                    onChange={(event) => {
                                        setIsPhoneValid(true);
                                        escortFormSetPhone(event.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <div className="d-flex align-items-center">
                                    <FormLabel>Электронная почта</FormLabel>
                                    {
                                        !isEmailValid && (
                                            <img className="mb-2 escort-form__invalid-field-icon" src={warningIcon} />
                                        )
                                    }
                                </div>
                                <TextArea
                                    className={isEmailValid ? "" : "escort-form__invalid-field"}
                                    rows={1}
                                    value={email}
                                    onChange={(event) => {
                                        setIsEmailValid(true);
                                        escortFormSetEmail(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="escort-divider"></div>
                <div className="p-12">
                    <h2 className="escort-form__header">Информация о рейсе</h2>
                    <div className="escort-form__row mb-7">
                        <div className="escort-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Номер рейса</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <TextArea
                                    rows={1}
                                    value={flightNumber}
                                    onChange={(event) => {
                                        escortFormSetFlightNumber(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="escort-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Дата и время вылета</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <DatePicker
                                    selected={date}
                                    onChange={escortFormSetDate}
                                    customInput={<DatePickerInput/>}
                                    minDate={new Date()}
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    timeIntervals={10}
                                    showTimeSelect
                                    isClearable={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="escort-form__row mb-7">
                        <div className="escort-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Аэропорт вылета</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <SearchSelect
                                    name="departure_airport"
                                    placeholder="Выберите аэропорт"
                                    onChange={(event) => {
                                        escortFormSetDeparture(event);
                                    }}
                                >
                                    {
                                        airports?.map((airport) => (
                                            <option key={airport.id} value={airport.id}>
                                                {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                            </option>
                                        ))
                                    }
                                </SearchSelect>
                                <div className="mt-5">
                                    <FormLabel>Услуги в аэропорте</FormLabel>
                                    <div className="escort-form__checkbox-list">
                                        <label className="escort-form__checkbox">
                                            <Checkbox
                                                checked={departureFastTrack}
                                                onChange={escortFormSetDepartureFastTrack}
                                            />
                                            <Text type="text">
                                                Быстрая регистрация (fast track)
                                            </Text>
                                        </label>
                                        <label className="escort-form__checkbox">
                                            <Checkbox
                                                checked={departureLoader}
                                                onChange={escortFormSetDepartureLoader}
                                            />
                                            <Text type="text">
                                                Услуги грузчиков
                                            </Text>
                                        </label>
                                        <label className="escort-form__checkbox">
                                            <Checkbox
                                                checked={departureManager}
                                                onChange={escortFormSetDepartureManager}
                                            />
                                            <Text type="text">
                                                Флайт менеджер
                                            </Text>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="escort-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Аэропорт прилета</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <SearchSelect
                                    name="arrival_airport"
                                    placeholder="Выберите аэропорт"
                                    onChange={(event) => {
                                        escortFormSetArrival(event);
                                    }}
                                >
                                    {
                                        airports?.map((airport) => (
                                            <option key={airport.id} value={airport.id}>
                                                {airport.name_rus} ({airport.city_rus}) {airport.icao} {airport.iata}
                                            </option>
                                        ))
                                    }
                                </SearchSelect>
                                <div className="mt-5">
                                    <FormLabel>Услуги в аэропорте</FormLabel>
                                    <div className="escort-form__checkbox-list">
                                        <label className="escort-form__checkbox">
                                            <Checkbox
                                                checked={arrivalFastTrack}
                                                onChange={escortFormSetArrivalFastTrack}
                                            />
                                            <Text type="text">
                                                Быстрая регистрация (fast track)
                                            </Text>
                                        </label>
                                        <label className="escort-form__checkbox">
                                            <Checkbox
                                                checked={arrivalLoader}
                                                onChange={escortFormSetArrivalLoader}
                                            />
                                            <Text type="text">
                                                Услуги грузчиков
                                            </Text>
                                        </label>
                                        <label className="escort-form__checkbox">
                                            <Checkbox
                                                checked={arrivalManager}
                                                onChange={escortFormSetArrivalManager}
                                            />
                                            <Text type="text">
                                                Флайт менеджер
                                            </Text>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="escort-form__row">
                        <div className="escort-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Комментарий</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <TextArea
                                    value={comment}
                                    onChange={(event) => {
                                        escortFormSetComment(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="escort-form__column">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <FormLabel>Файлы</FormLabel>
                                    <p className="escort-form__extra-label form-label">Не обязательно</p>
                                </div>
                                <FilesInput
                                    onChange={(files) => {
                                        escortFormSetFile(files);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="escort-form__footer d-flex justify-content-end mt-11 align-items-center">
                <p className="escort-form__policy">
                    Нажимая кнопку «Отправить» вы подтверждаете, что ознакомились
                    с <a href="/rules.pdf" target="_blank">Пользовательское соглашение</a> компании и соглашаетесь с
                    ней.
                </p>
                <Button
                    className="escort-form__send"
                    onClick={() => {
                        validateAndSend();
                    }}
                >
                    Отправить
                </Button>
            </div>
        </div>
    );
};
