import {httpRequest} from "../../../shared/http/http-request/http-request";

export const sendSeatBlocksFormHttp = async (
    params: {
        "creation_date": string,
        "name": string,
        "phone": string,
        "email": string,
        "departure_datetime": string,
        "departure_airport": number,
        "arrival_airport": number,
        "notes": string,
        "pax": number,
        "excess_luggage": boolean,
    },
) => {
    return httpRequest("seatblock_request", {
        method: "POST",
        body: JSON.stringify(params),
    });
};
