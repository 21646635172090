import {createEffect, createEvent, createStore, forward} from "effector";
import {getUsersListHttp, TGetUsersListResponse} from "../http/get-users-list.http";
import {IUserList} from "../types/user.types";

export const getUsersList = createEvent<void>();

export const getUsersListFX = createEffect<
    void,
    TGetUsersListResponse
>(() => {
    return getUsersListHttp();
})


export const $usersList = createStore<IUserList[] | null>(null)
    .on(getUsersListFX.doneData, (_, payload) => {
        return payload;
    })
    .on(getUsersListFX.fail, () => null);

forward({
    from: getUsersList,
    to: getUsersListFX
})
