import {createEffect, createEvent, createStore, forward} from "effector";
import {getAirportsHttp, TGetAirportsHttpResponse} from "../http/get-airports.http";
import {IAirport} from "../types/airport.types";

export const getAirports = createEvent<void>();

export const getAirportsFx = createEffect<
    void,
    TGetAirportsHttpResponse
>(() => {
    return getAirportsHttp();
});

export const $airports = createStore<IAirport[] | null>(null)
    .on(getAirportsFx.doneData, (_, payload) => payload)
    .on(getAirportsFx.fail, () => null);

forward({
    from: getAirports,
    to: getAirportsFx,
});
