import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IGame} from "../types/team.type";

export type TGetGamesCalendarResponse = IGame[];
export const getGamesCalendarHttp = async (
    team_id: number,
): Promise<TGetGamesCalendarResponse> => {
    const getGamesCalendarResponse = await httpRequest(`game-calendar?team_id=${team_id}`);

    return getGamesCalendarResponse.json();
};
