import {FC, FocusEvent, LegacyRef, useCallback, useMemo} from "react";

interface IProps {
    value: string;
    onChange(value: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    onClick?: () => void;
    variant?: "solid" | "transparent";
    type?: string;
    placeholder?: string;
    inputRef?: LegacyRef<HTMLInputElement>;
    className?: string;
    disable?: boolean;
}

export const Input: FC<IProps> = ({
    value,
    onChange,
    onBlur,
    onClick,
    variant,
    type,
    placeholder,
    inputRef,
    className,
    disable
}) => {
    const inputStyle = useMemo(() => {
        if (variant) {
            return `-${variant}`;
        }

        return "";
    }, [variant]);

    const throwOnChange = useCallback((event: any) => {
        onChange(event);
    }, [onChange]);



    return (
        <input
            ref={inputRef}
            value={value}
            onInput={throwOnChange}
            onClick={onClick}
            onBlur={onBlur}
            className={`form-control form-control${inputStyle} ${className}`}
            type={type}
            placeholder={placeholder}
            disabled={false}
        />
    );
};
