import {createEffect, createEvent, createStore, forward} from "effector";
import {IUser} from "../types/user.types";
import {getAuthorizedUserHttp, TGetAuthorizedUserHttpResponse} from "../http/get-authorized-user.http";
import {TUserAuthorizedCallback} from "./user-authorization.store";

export const logoutUser = createEvent<void>();
export const getAuthorizedUser = createEvent<TUserAuthorizedCallback>();

export const getAuthorizedUserFx = createEffect<
    TUserAuthorizedCallback,
    TGetAuthorizedUserHttpResponse
>(async (callback) => {
    try {
        const user = await getAuthorizedUserHttp();

        if (user.id) {
            callback(user);
            return user;
        }
    } catch (e: unknown) {
        callback(false);
        throw new Error("Unauthorized");
    }

    throw new Error("Unauthorized");
});

export const $authorizedUser = createStore<IUser | null>(null)
    .on(getAuthorizedUserFx.doneData, (_, payload) => payload)
    .on(getAuthorizedUserFx.fail, () => null)
    .on(logoutUser, () => null);

forward({
    from: getAuthorizedUser,
    to: getAuthorizedUserFx,
});

logoutUser.watch(() => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("authTokenType");

    window.location.href = "/";
});
