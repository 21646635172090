import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IPassingFlight} from "../types/passing-flight.types";

export type TGetPassingFlightsTeamResponse = IPassingFlight[];

export const getPassingFlightsTeamHttp = async (): Promise<TGetPassingFlightsTeamResponse> => {
    const getPassingFlightsResponse = await httpRequest("passing-flights-offers");

    return getPassingFlightsResponse.json();
};
