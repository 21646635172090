import React, {ChangeEvent} from "react";

import './textArea.css';

interface ITextAreaProps {
    className?: string;
    rows?: number;
    cols?: number;
    value?: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
}

export const TextArea: React.FC<ITextAreaProps> = ({ className, value, onChange, rows, cols, placeholder }) => {
    return (
        <textarea
            className={`customTextarea form-control form-control-solid${className ? ` ${className}` : ''}`}
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        >
            {value}
        </textarea>
    );
}
