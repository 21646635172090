import {createEffect, createEvent, createStore, forward} from "effector";
import {getTeamListHttp, TGetTeamListResponse} from "../http/get-team-list.http";
import {ITeam} from "../types/team.type";


export const getActualTeamList = createEvent<void>();

export const getActualTeamListFx = createEffect<
    void,
    TGetTeamListResponse
>(() => {
    return getTeamListHttp();
});


export const $teamList = createStore<ITeam[] | null>(null)
    .on(getActualTeamListFx.doneData, (_, payload) => {
        return payload;
    })
    .on(getActualTeamListFx.fail, () => null);

forward({
    from: getActualTeamList,
    to: getActualTeamListFx,
});
