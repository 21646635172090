import React, { FC } from "react";

import './about.css';

export const AboutSection: FC = () => {
    return (
        <section className="section about-section">
            <div className="about-name" id="about">О проекте</div>
            <div className="about-text">
                <div className="about-title">
                    Сервис бронирования
                    и продаж чартерных авиаперевозок
                </div>
                <div className="about-subtitle">
                    Онлайн-сервис по выбору и заказу воздушного чартера для перевозки пассажиров и багажа на самолетах бизнес-авиации является уникальным, реализованным впервые в мире, продуктом компании
                </div>
            </div>

            <div className="about-list">
                <div className="about-item">
                    <div className="about-item-title">97%</div>
                    <div className="about-item-text">
                        Заявок обрабатываются
                        в течение 15 минут с момента оформления
                    </div>
                </div>

                <div className="about-item">
                    <div className="about-item-title">19К</div>
                    <div className="about-item-text">
                        Хоккейных клюшек мы перевезли из Москвы в Омск
                    </div>
                </div>

                <div className="about-item">
                    <div className="about-item-title">1М+</div>
                    <div className="about-item-text">
                        Перелетов за календарный год
                        осуществили клубы используя
                        наш сервис
                    </div>
                </div>

                <div className="about-item">
                    <div className="about-item-title">12</div>
                    <div className="about-item-text">
                        Партнёров авиакомпаний которые интегрированы
                        в систему сервиса
                    </div>
                </div>
            </div>
        </section>
    );
}