import {combine, createEvent, createStore, forward} from "effector";
import {createEffect} from "effector/effector.umd";
import {getAllAircraftsHttp, TGetAllAircraftsHttpResponse} from "../http/get-all-aircrafts.http";
import {IFullAircraft} from "../types/full-aircraft.types";

export const resetAllAircrafts = createEvent<void>();
export const getAllAircrafts = createEvent<void>();
export const choseAirline = createEvent<string>();
export const choseMaxPax = createEvent<number>();
export const choseType = createEvent<string>();

export const getAllAircraftsFx = createEffect<
    void,
    TGetAllAircraftsHttpResponse
>(() => {
    return getAllAircraftsHttp();
});

export const $allAircraftList = createStore<IFullAircraft[]>([])
    .on(getAllAircraftsFx.doneData, (_, payload) => payload)
    .reset(resetAllAircrafts);

export const $allAircraftAirlineList = $allAircraftList
    .map((aircraftList) => {
        const allAirlineList = aircraftList.map(({airline}) => airline);

        return Array.from(new Set(allAirlineList));
    });

export const $allAircraftChosenAirlineList = createStore<string[]>([])
    .on($allAircraftAirlineList, (_, payload) => payload)
    .on(choseAirline, (store, payload) => {
        if (store.includes(payload)) {
            return store.filter((airline) => airline !== payload);
        }

        return [
            ...store,
            payload,
        ];
    })
    .reset(resetAllAircrafts);

export const $allAircraftMaxPaxList = $allAircraftList
    .map((aircraftList) => {
        const allMaxPaxList = aircraftList.map(({max_pax}) => max_pax);

        const arr = Array.from(new Set(allMaxPaxList));
        arr.sort((a, b) => a - b);

        return arr;
    });

export const $allAircraftChosenMaxPaxList = createStore<number[]>([])
    .on($allAircraftMaxPaxList, (_, payload) => payload)
    .on(choseMaxPax, (store, payload) => {
        if (store.includes(payload)) {
            return store.filter((maxPax) => maxPax !== payload);
        }

        return [
            ...store,
            payload,
        ];
    })
    .reset(resetAllAircrafts);

export const $allAircraftTypeList = $allAircraftList
    .map((aircraftList) => {
        const allTypeList = aircraftList.map(({type}) => type);

        return Array.from(new Set(allTypeList));
    });

export const $allAircraftChosenTypeList = createStore<string[]>([])
    .on($allAircraftTypeList, (_, payload) => payload)
    .on(choseType, (store, payload) => {
        if (store.includes(payload)) {
            return store.filter((type) => type !== payload);
        }

        return [
            ...store,
            payload,
        ];
    })
    .reset(resetAllAircrafts);

export const $filteredAllAircraftList = combine({
    allAircraftList: $allAircraftList,
    allAircraftChosenAirlineList: $allAircraftChosenAirlineList,
    allAircraftChosenMaxPaxList: $allAircraftChosenMaxPaxList,
    allAircraftChosenTypeList: $allAircraftChosenTypeList,
}, ({
    allAircraftList,
    allAircraftChosenAirlineList,
    allAircraftChosenMaxPaxList,
    allAircraftChosenTypeList,
}) => {
    return allAircraftList.filter((aircraft) => {
        const isAirlineFits = allAircraftChosenAirlineList.includes(aircraft.airline);
        const isMaxPaxFits = allAircraftChosenMaxPaxList.includes(aircraft.max_pax);
        const isTypeFits = allAircraftChosenTypeList.includes(aircraft.type);

        return isAirlineFits && isMaxPaxFits && isTypeFits;
    });
});

forward({
    from: getAllAircrafts,
    to: getAllAircraftsFx,
});
