import React, {FC, useCallback, useEffect, useState} from "react";
import {Text} from "../../../../../components/text";
import "./user-sign-in-form.css";
import {Input} from "../../../../../components/input/input";
import {useStore} from "effector-react";
import {
    $userAuthorizationIsPasswordValid,
    $userAuthorizationIsUsernameValid,
    $userAuthorizationPassword,
    $userAuthorizationUsername,
    authorizeUser,
    resetUserAuthorizationForm,
    setUserAuthorizationPassword,
    setUserAuthorizationUsername
} from "../../../store/user-authorization.store";
import {Button} from "../../../../../components/button";
import {useNavigate, useSearchParams} from "react-router-dom";
import {EUserStatus} from "../../../types/user.types";
import {$authorizedUser} from "../../../store/authorized-user.store";
import {confirmEmail} from "../../../http/confirm-email.http";

interface IProps {
    toRegistration(): void;
    onResetClick():void;
}

export const UserSignInForm: FC<IProps> = ({
    toRegistration,
    onResetClick
}) => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [step, setStep] = useState(0);
    const [isCredentialsValid, setIsCredentialsValid] = useState(true);

    const [isFormTouched, setIsFormTouched] = useState(false);

    useEffect(() => {
        const emailConfirmToken = searchParams.get("email_confirm_token");
        const airlineNotice = searchParams.get("airlineNotice");

        if (emailConfirmToken) {
            confirmEmail(emailConfirmToken)
                .finally(() => {
                    setStep(2);
                });
        }

        if (airlineNotice) {
            setStep(3);
        }

        return () => {
            resetUserAuthorizationForm();
            setIsFormTouched(false);
        };
    }, []);

    const user = useStore($authorizedUser);

    useEffect(() => {
        if (user?.status === EUserStatus.NEW) {
            setStep(1);
        } else if (user?.status === EUserStatus.EMAIL_CONFIRMED) {
            setStep(2);
        }
    }, [user]);

    const username = useStore($userAuthorizationUsername);
    const password = useStore($userAuthorizationPassword);
    const isUsernameValid = useStore($userAuthorizationIsUsernameValid);
    const isPasswordValid = useStore($userAuthorizationIsPasswordValid);

    const changeUsername = useCallback((event: any) => {
        setUserAuthorizationUsername(event.target.value);
        setIsCredentialsValid(true);
    }, []);

    const changePassword = useCallback((event: any) => {
        setUserAuthorizationPassword(event.target.value);
        setIsCredentialsValid(true);
    }, []);


    const authorizeUserHandler = useCallback(() => {
        setIsFormTouched(true);

        if (isUsernameValid && isPasswordValid) {
            authorizeUser((user) => {
                if (user) {
                    if (user.status === EUserStatus.BROKER_CONFIRMED || user.status === EUserStatus.SUPERVISOR) {
                        const authRedirect = searchParams.get("authRedirect");

                        if (authRedirect) {
                            navigate(authRedirect);
                        } else {
                            navigate("/dashboard/applications");
                        }

                    } else if (user.status === EUserStatus.NEW) {
                        setStep(1);
                    } else {
                        setStep(2);
                    }
                } else {
                    setStep(0);
                    setIsCredentialsValid(false);
                }
            });
        }
    }, [isUsernameValid, isPasswordValid, navigate, searchParams]);

    return (
        <>
            {
                step !== 3 && (
                    <Text
                        type="title"
                        className="user-sign-in-form-title"
                    >
                        Авторизация
                    </Text>
                )
            }
            {
                step === 0 && (
                    <>
                        <Text type="text" className="user-sign-in-form-label">Email</Text>
                        <Input value={username} onChange={changeUsername} />
                        {
                            isFormTouched && !isUsernameValid && (
                                <Text type="text" className="user-sign-in-form-validation">
                                    Обязательное поле
                                </Text>
                            )
                        }
                        <Text type="text" className="user-sign-in-form-label">Пароль</Text>
                        <Input value={password} onChange={changePassword} type="password" />
                        {
                            isFormTouched && !isPasswordValid && (
                                <Text type="text" className="user-sign-in-form-validation">
                                    Обязательное поле
                                </Text>
                            )
                        }
                        {
                            !isCredentialsValid && (
                                <Text type="text" className="user-sign-in-form-validation">
                                    Неверный логин или пароль
                                </Text>
                            )
                        }
                        <div>
                            <Button onClick={authorizeUserHandler} className="user-sign-in-form-auth-button">Войти</Button>
                        </div>
                        <div>
                            <Button onClick={toRegistration} className="user-sign-in-form-reg-button" variant="grey">Создать новый аккаунт</Button>
                        </div>
                        <div>
                            <Button onClick={onResetClick} className="user-reset-form-rec-button" variant="grey">Восстановить пароль</Button>
                        </div>
                    </>
                )
            }
            {
                step === 1 && (
                    <Text type="text" className="user-sign-in-form-second-description">
                        Для пользования сервисом вы должны подтвердить email указанный при регистрации
                    </Text>
                )
            }
            {
                step === 2 && (
                    <Text type="text" className="user-sign-in-form-second-description">
                        Проверка email прошла успешно.
                        <br />
                        Ваш аккаунт ждет подтверждения администратором сайта. По всем вопросам обращайтесь по телефону
                        <br />
                        <a href="tel:+74955055797">+7 (495) 505-57-97</a>
                    </Text>
                )
            }
            {
                step === 3 && (
                    <Text type="text" className="user-sign-in-form-second-description">
                    </Text>
                )
            }
        </>
    );
};
