import React, {FC, useEffect, useState} from "react";
import {Card, FormLabel} from "react-bootstrap";
import {Text} from "../../../../components/text";
import {Input} from "../../../../components/input/input";
import {Button} from "../../../../components/button";
import "./user-settings.css";
import {useStore} from "effector-react";
import {
    $userSettingsName,
    $userSettingsPhone,
    $userSettingsOrganizationName,
    getUserSettings,
    setUserSettingsName,
    setUserSettingsPhone,
    setUserSettingsOrganizationName,
    saveUserSettings,
    $userSettingsTelegram,
    setUserSettingsTelegram,
    $userSettingsIsTelegramValid,
    setUserSettingLogo,
    setUserSettingsGroupEmail, $userSettingsGroupEmail, setUserSettingsCharterEmail, $userSettingsCharterEmail
} from "../../store/user-settings.store";
import userLogo from "./userLogo.png"
import {$authorizedUser} from "../../store/authorized-user.store";
import {EUserType} from "../../types/user.types";


export const UserSettings: FC = () => {
    useEffect(() => {
        getUserSettings();
    }, []);

    const user = useStore($authorizedUser);
    const phone = useStore($userSettingsPhone);
    const contactName = useStore($userSettingsName);
    const telegram = useStore($userSettingsTelegram);
    const isUserTelegramValid = useStore($userSettingsIsTelegramValid);
    const organizationName = useStore($userSettingsOrganizationName);
    const groupEmail = useStore($userSettingsGroupEmail);
    const charterEmail = useStore($userSettingsCharterEmail);

    const [selectedImage, setSelectedImage] = useState<File | null>(null);

    const imageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
        setUserSettingLogo(e.target.files[0]);
    };

    const removeSelectedImage = () => {
        setSelectedImage(null);
    };


    return (
        <>
            <Card className="settings">
                <Text
                    type="subtitle"
                    className="settings-title"
                >
                    Контактная информация
                </Text>
                <div className="settings-line">
                    <div className="settings-half settings-half_vertical">
                        <div className="vertical-field_user_name">
                            <FormLabel>Имя и фамилия</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={contactName}
                                onChange={(event) => {
                                    setUserSettingsName(event.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <FormLabel>Ник в Telegram</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={telegram}
                                onChange={(event) => {
                                    setUserSettingsTelegram(event.target.value);
                                }}
                            />
                            {
                                telegram && !isUserTelegramValid  && (
                                    <Text type="text" className="user-sign-up-form-validation">
                                        <ul>
                                            <li>Допустим ввод симовлов a-z и нижнее подчеркивание</li>
                                            <li>Длина не менее 5 символов</li>
                                        </ul>
                                    </Text>
                                )
                            }
                        </div>
                    </div>
                    <div className="settings-half">
                        <div className="settings-item">
                            <FormLabel>Телефон</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={phone}
                                onChange={(event) => {
                                    setUserSettingsPhone(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="settings-separator"/>
                <div className="settings-line">
                    <div className="setting-logo-wrapper">
                        <Text type="text" className="settings-logo-title">
                            Логотип
                        </Text>
                        <div className="settings-logo-wrapper">
                            {selectedImage ? selectedImage && (
                                <div className="upload-logo-wrapper">
                                    <div className="user-logo">
                                        <img className="user-logo-item"
                                             src={URL.createObjectURL(selectedImage)}
                                             alt="user-logo"
                                        />
                                    </div>
                                    <button className="delete-logo-btn" onClick={removeSelectedImage}>Удалить
                                    </button>
                                </div>
                            ) : <img src={userLogo} alt="user-logo"/>}
                            { !selectedImage && (
                                <label htmlFor="input-logo" className="input-button-label">
                                    Загрузить
                                </label>)
                            }
                            <input
                                className="input-button"
                                id="input-logo"
                                type='file'
                                accept="image/*"
                                onChange={imageChange}
                            />
                        </div>
                    </div>
                    <div className="user-settings-org-data">
                        <div className="settings-half settings-half_vertical">
                            <FormLabel>Название</FormLabel>
                            <Input
                                variant="solid"
                                type="text"
                                value={organizationName}
                                onChange={(event) => {
                                    setUserSettingsOrganizationName(event.target.value);
                                }}
                            />
                        </div>
                        {
                            user?.type === EUserType.AIRLINE && (
                                <>
                                    <div className="settings-half settings-half_vertical">
                                        <FormLabel>Email группового отдела</FormLabel>
                                        <Input
                                            variant="solid"
                                            type="text"
                                            value={groupEmail}
                                            onChange={(event) => {
                                                setUserSettingsGroupEmail(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="settings-half settings-half_vertical">
                                        <FormLabel>Email чартерного отдела</FormLabel>
                                        <Input
                                            variant="solid"
                                            type="text"
                                            value={charterEmail}
                                            onChange={(event) => {
                                                setUserSettingsCharterEmail(event.target.value);
                                            }}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </Card>
            <div className="settings-save">
                <Button
                    className="settings-submit"
                    variant="primary"
                    onClick={() => {
                        saveUserSettings();
                    }}
                >
                    Сохранить изменения
                </Button>
            </div>
        </>
    );
};
