import {httpRequest} from "../../../shared/http/http-request/http-request";

export const confirmEmail = async (
    token: string,
): Promise<Response> => {
    return httpRequest({
        path: `auth/email-confirm/${token}`,
        query: {
            redirect_uri: "/",
        },
    });
};
