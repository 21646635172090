import React, {useCallback, useEffect, useMemo} from "react";
import {Chat} from "../../../../components/chat/chat";
import {
    $allMessagesList,
    getApplicationChatList,
    resetApplicationMessageForm,
    sendOneMessage,
    setApplicationMessage,
    setApplicationMessageFile
} from "../../store/application-chat.store";
import {IApplication} from "../../types/application.types";
import {useStore} from "effector-react";
import {Tabs} from "./tabs/tabs";
import {useNavigate, useParams} from "react-router-dom";
import {getNewMessages} from "../../store/get-new-messages.store";

interface IApplicationChatListProps {
    application: IApplication
}

export const AppChat:React.FC<IApplicationChatListProps> = ({application}) => {

    const applicationMessages = useStore($allMessagesList) ?? [];
    const {airlineId} = useParams();
    const navigate = useNavigate();
    const formattedAirlineId = useMemo(() => Number(airlineId), [airlineId]);
    const airlines = application.flight_type === "passing" ? [] : application.airlines;

    const handleTabChange = useCallback ((id: number) => {
        navigate(`/dashboard/applications/${application.id}/airline/${id}`);
    }, [navigate, application]);

    useEffect(() => {
        getApplicationChatList({id: application.id, airlineId: formattedAirlineId});
        getNewMessages(application.id);
    }, [application, formattedAirlineId]);


    useEffect(() => {
        const interval = setInterval(() => {
            getApplicationChatList({id: application.id, airlineId: formattedAirlineId});
        }, 30000);
        return () => clearInterval(interval);
    }, [application.id, formattedAirlineId]);

    useEffect(() => {
        const intervalNewMessages = setInterval(() => {
            getNewMessages(application.id);
        }, 30000);
        return () => clearInterval(intervalNewMessages);
    }, [application.id]);


    useEffect(() => () => resetApplicationMessageForm(), []);

    const handleSendMessage = useCallback((message: string, file: FileList | null) => {
        setApplicationMessage(message);
        setApplicationMessageFile(file);

        sendOneMessage({id: application.id, airlineId: formattedAirlineId});

        const timeout = setTimeout(() => getApplicationChatList({id: application.id, airlineId: formattedAirlineId}), 1000);
        return () => clearTimeout(timeout);
    }, [application.id, formattedAirlineId]);

    return <div>
        <Tabs activeTab={formattedAirlineId}
              airlines={airlines}
              onTabChange={handleTabChange}/>
        <Chat messages={applicationMessages} onSend={handleSendMessage} />
    </div>
}


