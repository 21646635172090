import {httpRequest} from "../../../shared/http/http-request/http-request";

export const createPassingFlightFormHttp = async (
    params: {
        "departure_airport": number,
        "arrival_airport": number,
        "departure_datetime": string,
        "places": number,
        "aircraft": number,
        "price": number,
    }
) => {
    const response = await httpRequest(`passing-flight`, {
        method: "POST",
        body: JSON.stringify(params),
    });

    const id = await response.text();

};
