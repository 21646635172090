import {createEffect, createEvent, createStore, restore, sample} from "effector";
import {
    resetApplicationForm,
} from "../../application/store/application-form.store";
import {creatGameCalendarHttp} from "../http/creat-game-celandar.http";
import {getGamesCalendar} from "./games-calendar.store";
import {showNotice} from "../../../components/notice-bubble/notice-bubble";

export interface IGameCalendarForm {
    id: number,
    game_date: Date | null,
    game_name: string,
}

export interface IProps {
    teamId: number,
    gameName: string,
    gameDate: Date | null,
}


export const addGameCalendarForm = createEvent<void>();
export const changeGamesCalendarForm = createEvent<IGameCalendarForm>();
export const setNewGameName = createEvent<string>();
export const setDateNewGame = createEvent<Date>();
export const saveNewGameForm = createEvent<IProps>();
export const resetNewGameForm = createEvent<void>();


export const $gameCalendarFormLastId = createStore(0)
    .on(addGameCalendarForm, (store) => store + 1)
    .reset(resetNewGameForm);

export const $gamesCalendarForm = createStore<IGameCalendarForm[]>([{
    id: 0,
    game_name: "",
    game_date: null,
}])
    .on($gameCalendarFormLastId, (store, payload) => {
        return [
            ...store,
            {
                id: payload,
                game_name: "",
                game_date: null,
            },
        ];
    })
    .on(changeGamesCalendarForm, (store, payload) => {
        return store.map((currentGameForm) => {
            if (currentGameForm.id === payload.id) {
                return payload;
            }

            return currentGameForm;
        })
    })
    .reset(resetApplicationForm);

export const $newGameName = restore(
    setNewGameName,
    "",
)
    .reset(resetNewGameForm);

export const $newGameDate = restore(
    setDateNewGame,
    null,
)
    .reset(resetNewGameForm);



export const saveNewGameFormFx = createEffect(async ({
    gameName,
    gameDate,
    teamId,

}: IProps) => {
    const createGameCalendarResponse = await creatGameCalendarHttp(
        teamId,
        gameName,
        gameDate
    );
    getGamesCalendar(teamId);
    const gameCalendarFormId = await createGameCalendarResponse.text();
    resetApplicationForm();
});


sample({
    clock: saveNewGameForm,
    target: saveNewGameFormFx,
    fn: (source) => ({
        ...source
    }),
});

saveNewGameFormFx.done.watch(() => {
    showNotice("primary", "Новая игра успешно сохранена.");
});
