import React, {FC} from "react";
import {useStore} from "effector-react";
import {$authorizedUser} from "../../../../domains/user/store/authorized-user.store";
import {Route, Routes} from "react-router-dom";
import {UsersList} from "../../../../domains/user/views/users-list/users-list";
import {EditUserByAdmin} from "../../../../domains/user/views/users-list/edit-user-by-admin/edit-user-by-admin";

export const Users: FC = () => {
    const user = useStore($authorizedUser);

    if (user === null) {
        return null;
    }

    return <Routes>
        <Route
            path="/"
            element={
                <UsersList />
            }>
        </Route>
        <Route
            path="/edit-user/:id"
            element={
                <EditUserByAdmin />
            }
        >
        </Route>
    </Routes>
}
