import {IFoodApp} from "../types/food.types";
import {httpRequest} from "../../../shared/http/http-request/http-request";

export type TGetFoodAppsResponse = IFoodApp[];

export const getFoodAppsHttp = async (): Promise<TGetFoodAppsResponse> => {
    const getFoodAppsResponse = await httpRequest("food");

    return getFoodAppsResponse.json();
}
