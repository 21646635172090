import {AircraftList} from "../../../domains/aircraft/views/aircraft-list/aircraft-list";
import React, {FC, useCallback, useEffect} from "react";
import {Content} from "../../content/content";
import "./non-auth-layout.css";
import logo from "../../main/start/uploads/logo.svg";
import {Nav, Navbar} from "react-bootstrap";
import userIcon from "../../main/start/uploads/user.svg";
import {Button} from "../../button";
import {$authorizedUser, getAuthorizedUser, logoutUser} from "../../../domains/user/store/authorized-user.store";
import {useStore} from "effector-react";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {AirTickets} from "../../../domains/airticket/views/air-tickets";
import {SeatBlocksList} from "../../../domains/seat-blocks/views/seat-blocks-list/seat-blocks-list";
import {EscortForm} from "../../../domains/escort/views/escort-form/escort-form";
import {getAirlines} from "../../../domains/airline/store/airlines.store";
import {getAirports} from "../../../domains/airport/store/airport.store";
import {Teams} from "../dashboard/teams/teams";
import {ConfirmPassword} from "../../../domains/user/views/user-recover-password-form/confirm-password";
import {
    ApplicationFormNonAuth
} from "../../../domains/application/views/application-form/application-non-auth/application-non-auth";
import {PassingFlights} from "../dashboard/passing-flights/passing-flights";


export const NonAuthLayout: FC = () => {
    const navigate = useNavigate();
    const user = useStore($authorizedUser);

    useEffect(() => {
        getAuthorizedUser(() => {});
        getAirlines();
        getAirports();
    }, []);

    const showSignForm = useCallback(() => {
        if (user) {
            navigate("/dashboard/applications");
        } else {
            navigate("/?authRedirect=dashboard/aircrafts");
        }
    }, [navigate, user]);

    return (
        <div className="aircraft-list-layout">
            <div className="d-flex justify-content-between aircraft-list-layout-nav">
                <div className="aircraft-start-logo">
                    <Link to="/">
                        <img src={logo} />
                    </Link>
                    <p className="navigation-logo-desc navigation-logo-aircraft mb-0">
                        Онлайн-сервис бронирования
                        <br />
                        и продаж чартерных авиаперевозок
                    </p>
                </div>

                <Navbar collapseOnSelect expand="lg" className="flex-grow-2 aircraft-list-layout-auth">
                    <Nav className='start-nav-right'>
                        {
                            user === null
                                ? (
                                    <>
                                        <Nav.Item className="start-user-menu-wrapper">
                                            <div onClick={showSignForm} className="start-user-menu">
                                                <img src={userIcon} alt="Header-logo" />
                                            </div>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Button
                                                variant="white"
                                                onClick={showSignForm}
                                            >
                                                Вход
                                            </Button>
                                        </Nav.Item>
                                    </>
                                )
                                : (
                                    <>
                                        <Nav.Item className="start-phone">
                                            {user.organization_name}
                                        </Nav.Item>
                                        <Nav.Item className="start-user-menu-wrapper">
                                            <div onClick={showSignForm} className="start-user-menu">
                                                <img src={userIcon} alt="Header-logo" />
                                            </div>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Button
                                                variant="white"
                                                onClick={() => logoutUser()}
                                            >
                                                Выход
                                            </Button>
                                        </Nav.Item>
                                    </>
                                )
                        }
                    </Nav>
                </Navbar>
            </div>
            <Content>
                <Routes>
                    <Route
                        path="/aircrafts"
                        element={(
                            <AircraftList />
                        )}
                    />
                    <Route
                        path="/passing-flights"
                        element={(
                            <PassingFlights />
                        )}
                    />
                    <Route
                        path="/seat-blocks"
                        element={(
                            <SeatBlocksList />
                        )}
                    />
                    <Route
                        path="/air-tickets"
                        element={(
                            <AirTickets />
                        )}
                    />
                    <Route
                        path="/escort"
                        element={(
                            <EscortForm />
                        )}
                    />
                    <Route
                        path="/teams"
                        element={(
                            <Teams />
                        )}
                    />
                    <Route
                        path="/reset-password/*"
                        element={(
                            <ConfirmPassword />)}
                    />
                    <Route
                        path="/form"
                        element={(
                            <ApplicationFormNonAuth />
                        )}
                    />
                </Routes>
            </Content>
        </div>
    );
};
