import {httpRequest} from "../../../shared/http/http-request/http-request";
import {IFoodApp} from "../types/food.types";

export type TGetFoodAppResponse = IFoodApp;

export const getFoodAppHttp = async (
    id: number,
): Promise<TGetFoodAppResponse> => {
    const getFoodAppResponse = await httpRequest(`food/${id}`);

    return getFoodAppResponse.json();
};
