import {combine, createEffect, createEvent, createStore, restore, sample} from "effector";
import {registerUserHttp} from "../http/register-user.http";
import {EUserType} from "../types/user.types";
import {
    required,
    validateEmail,
    validatePassword,
    validatePasswordConfirm, validatePhone, validateTelegram
} from "../../../shared/validations/validations";

export type TUserRegistrationCallback = (response: any) => void;

export const setUserRegistrationUsername = createEvent<string>();
export const setUserRegistrationPassword = createEvent<string>();
export const setUserRegistrationPasswordConfirm = createEvent<string>();
export const setUserRegistrationTelegram = createEvent<string>();
export const setUserRegistrationType = createEvent<EUserType>();
export const setUserRegistrationOrganizationName = createEvent<string>();
export const setUserRegistrationContactName = createEvent<string>();
export const setUserRegistrationPhone = createEvent<string>();
export const setUserGroupEmail = createEvent<string>();
export const setUserCharterEmail = createEvent<string>();
export const resetUserRegistrationForm = createEvent<void>();
export const registerUser = createEvent<TUserRegistrationCallback>();

export const registerUserFx = createEffect<
    {
        username: string,
        password: string,
        telegram: string,
        type: EUserType,
        contactName: string,
        organizationName: string,
        phone: string,
        userCharterEmail: string,
        userGroupEmail: string,
        isUsernameValid: boolean,
        isPasswordValid: boolean,
        // isTelegramValid: boolean,
        isPasswordConfirmValid: boolean,
        isOrganizationNameValid: boolean,
        isContactNameValid: boolean,
        isPhoneValid: boolean,
        isUserCharterEmailValid: boolean,
        isUserGroupEmailValid: boolean,
        callback: TUserRegistrationCallback,
    },
    void
>(async ({
    username,
    password,
    telegram,
    type,
    organizationName,
    contactName,
    phone,
    userCharterEmail,
    userGroupEmail,
    isUsernameValid,
    isPasswordValid,
    isPasswordConfirmValid,
    // isTelegramValid,
    isContactNameValid,
    isOrganizationNameValid,
    isPhoneValid,
    isUserCharterEmailValid,
    isUserGroupEmailValid,
    callback,
}) => {
    if (
        isUsernameValid &&
        isPasswordValid &&
        isPasswordConfirmValid &&
        isOrganizationNameValid &&
        isContactNameValid &&
        isPhoneValid &&
        isUserCharterEmailValid &&
        isUserGroupEmailValid
    ) {
        try {
            const response = await registerUserHttp(
                username,
                password,
                telegram,
                type,
                organizationName,
                contactName,
                phone,
                userCharterEmail,
                userGroupEmail,
                );
            const result = await response.text();
            callback(result);
        } catch (e: any) {
            if (e && e.message) {
                callback(e.message);
            }
        }

    }

    throw new Error("validation error");
});

export const $userRegistrationUsername = restore<string>(
    setUserRegistrationUsername,
    "",
)
    .reset(resetUserRegistrationForm);

export const $userRegistrationIsUsernameValid = createStore(false)
    .on($userRegistrationUsername, (_, username) => validateEmail(username))
    .reset(resetUserRegistrationForm);

export const $userRegistrationPassword = restore<string>(
    setUserRegistrationPassword,
    "",
)
    .reset(resetUserRegistrationForm);

export const $userRegistrationIsPasswordValid = createStore(false)
    .on($userRegistrationPassword, (_, password) => validatePassword(password))
    .reset(resetUserRegistrationForm);

export const $userRegistrationPasswordConfirm = restore<string>(
    setUserRegistrationPasswordConfirm,
    "",
)
    .reset(resetUserRegistrationForm);

const $userRegistrationPasswords = combine({
    password: $userRegistrationPassword,
    passwordConfirm: $userRegistrationPasswordConfirm,
});

export const $userRegistrationIsPasswordConfirmValid = createStore(true)
    .on($userRegistrationPasswords, (_, passwords) => {
        return validatePasswordConfirm(passwords.password, passwords.passwordConfirm);
    })
    .reset(resetUserRegistrationForm);

export const $userRegistrationTelegram = restore<string>(
    setUserRegistrationTelegram,
    ""
)
    .reset(resetUserRegistrationForm);

export const $userRegistrationIsTelegramValid = createStore(false)
    .on($userRegistrationTelegram, (_, userTelegram) => validateTelegram(userTelegram))
    .reset(resetUserRegistrationForm);

export const $userRegistrationType = restore<EUserType>(
    setUserRegistrationType,
    EUserType.TEAM,
)
    .reset(resetUserRegistrationForm);

export const $userRegistrationOrganizationName = restore<string>(
    setUserRegistrationOrganizationName,
    "",
)
    .reset(resetUserRegistrationForm);

export const $userRegistrationIsOrganizationNameValid = createStore(false)
    .on($userRegistrationOrganizationName, (_, organizationName) => required(organizationName))
    .reset(resetUserRegistrationForm);

export const $userRegistrationContactName = restore<string>(
    setUserRegistrationContactName,
    "",
)
    .reset(resetUserRegistrationForm);

export const $userRegistrationIsContactNameValid = createStore(false)
    .on($userRegistrationContactName, (_, contactName) => required(contactName))
    .reset(resetUserRegistrationForm);

export const $userRegistrationPhone = createStore("")
    .on(setUserRegistrationPhone, (store, phone) => phone.length > 20 ? store : phone)
    .reset(resetUserRegistrationForm);

export const $userRegistrationIsPhoneValid = createStore(false)
    .on($userRegistrationPhone, (_, phone) => validatePhone(phone))
    .reset(resetUserRegistrationForm);

export const $userCharterEmail = restore<string>(
    setUserCharterEmail,
    ""
)

export const $userCharterEmailValid = createStore(false)
    .on($userCharterEmail, (_, userCharterEmail) => validateEmail(userCharterEmail))
    .reset(resetUserRegistrationForm);

export const $userGroupEmail = restore<string>(
    setUserGroupEmail,
    ""
)

export const $userGroupEmailValid = createStore(false)
    .on($userGroupEmail, (_, userGroupEmail) => validateEmail(userGroupEmail))
    .reset(resetUserRegistrationForm);


    sample({
    clock: registerUser,
    source: combine({
        username: $userRegistrationUsername,
        isUsernameValid: $userRegistrationIsUsernameValid,
        password: $userRegistrationPassword,
        isPasswordValid: $userRegistrationIsPasswordValid,
        isPasswordConfirmValid: $userRegistrationIsPasswordConfirmValid,
        telegram: $userRegistrationTelegram,
        // isTelegramValid: $userRegistrationIsTelegramValid,
        type: $userRegistrationType,
        contactName: $userRegistrationContactName,
        isContactNameValid: $userRegistrationIsContactNameValid,
        organizationName: $userRegistrationOrganizationName,
        isOrganizationNameValid: $userRegistrationIsOrganizationNameValid,
        phone: $userRegistrationPhone,
        isPhoneValid: $userRegistrationIsPhoneValid,
        userCharterEmail: $userCharterEmail,
        userGroupEmail: $userGroupEmail,
        isUserCharterEmailValid: $userCharterEmailValid,
        isUserGroupEmailValid: $userGroupEmailValid,
    }),
    target: registerUserFx,
    fn: (store, callback) => ({
        ...store,
        callback,
    }),
});
